import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  cn,
  Input,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@brisk/ui'
import {
  FC,
  memo,
  useEffect,
  useRef
  //  useState
} from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import wordsTips from '../../../assets/svg/wordsTips.svg'
import { Controller, useForm } from 'react-hook-form'
import { Minus, Plus } from 'lucide-react'
import { filter, map, trim } from 'lodash'
import TextareaWithEmoji, { ITextareaWithEmojiRef } from './TextareaWithEmoji'
import { KeyWordsSaveRequest } from '@brisk/api'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'
import KeyWordsUploadFile, { IFileValue } from './KeyWordsUploadFile'
import { handleFormatFileContents } from '@renderer/utils'
import { getNodeTextContent, MESSAGE_TYPE } from '@brisk/utils'
import { v4 } from 'uuid'

const defaultFormValue = {
  keywordId: null,
  keywordList: [''],
  response: '',
  imagesUrlList: null,
  contents: []
}

interface IAddKeyWords {
  data: KeyWordsSaveRequest | null
  open: boolean
  onClose?: () => void
  onConfirm?: (data: KeyWordsSaveRequest) => void
}
const AddKeyWords: FC<IAddKeyWords> = ({ data, open, onClose, onConfirm }) => {
  const textareaRef = useRef<ITextareaWithEmojiRef>(null)
  const { t } = useTranslation()

  const uploadFileRef = useRef<{ isPending: boolean }>(null)

  const { handleSubmit, control, reset } = useForm<KeyWordsSaveRequest>({
    defaultValues: defaultFormValue
  })
  const onSubmit = (formData: KeyWordsSaveRequest): void => {
    if (uploadFileRef?.current?.isPending) {
      message(t('knowledgeBase.uploaddingFile'), 'info')
      return
    }
    const { keywordList, contents } = formData
    const formatContents = handleFormatFileContents(contents as unknown as IFileValue[])
    const list = filter(keywordList, (item) => item)
    if (!list?.length) {
      message(t('knowledgeBase.Pleaseenterkeyword'), 'error')
      return
    }
    const content = getNodeTextContent(textareaRef?.current?.preRef?.current)
    if (!trim(content)) {
      message(t('knowledgeBase.Pleaseinputreplycontent'), 'error')
      return
    }
    const params = {
      keywordList: list,
      contents: [
        {
          messageType: MESSAGE_TYPE.GENERIC_TEXT,
          content
        },
        ...formatContents
      ]
    } as KeyWordsSaveRequest
    onConfirm && data ? onConfirm({ ...params, keywordId: data?.keywordId }) : onConfirm(params)
    reset(defaultFormValue)
  }

  useEffect(() => {
    if (data) {
      const contents = map(data?.contents, (item) => {
        const id = v4()
        try {
          return { ...JSON.parse(item as string), id }
        } catch (error) {
          return { ...item, id }
        }
      })
      reset({ ...data, contents })
      textareaRef?.current?.setContent(data?.response)
    }
  }, [data])
  useEffect(() => {
    if (!open) {
      reset(defaultFormValue)
    }
  }, [open])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogContent className="p-0 text-sm w-[432px] max-h-[700px] overflow-x-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <ReactSVG
            src={close}
            className="cursor-pointer absolute right-4 top-4 "
            onClick={(e) => {
              e.preventDefault()
              reset(defaultFormValue)
              // setFileName('')
              onClose && onClose()
            }}
          />
          <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
            {data ? t('knowledgeBase.EditKeywords') : t('knowledgeBase.AddKeywords')}
          </AlertDialogTitle>
          <AlertDialogDescription />
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
          <div className=" p-4 flex flex-col gap-3 [&_.itemWrap]:flex [&_.itemWrap]:flex-col [&_.itemWrap]:gap-2 pb-9">
            <Controller
              name="keywordList"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const { value, onChange } = field
                return (
                  <div className="itemWrap">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span>{t('knowledgeBase.Keywords')}</span>
                        <span className="flex items-center gap-1 text-lingheGray">
                          <ReactSVG src={wordsTips} />
                          {t('knowledgeBase.Maximum')}{' '}
                          <span
                            className={cn(
                              value.length === 10
                                ? 'text-lingheError'
                                : value.length < 10 && value.length > 0
                                  ? 'text-black'
                                  : ''
                            )}
                          >
                            {value.length}
                          </span>
                          /10 {t('knowledgeBase.clause')}
                        </span>
                      </div>
                      <Button
                        disabled={value.length >= 10}
                        onClick={(e) => {
                          e.preventDefault()
                          onChange([...value, ''])
                        }}
                        className="flex items-center gap-2 text-primary"
                        variant="ghost"
                      >
                        <Plus size={16} />
                        {t('knowledgeBase.Add')}
                      </Button>
                    </div>
                    {map(value, (_, index) => (
                      <div className="flex items-center gap-2" key={index}>
                        <div className="relative flex-1 shrink-0">
                          <Input
                            className="h-9 pr-14"
                            placeholder={t('common.pleaseEnter')}
                            maxLength={50}
                            value={value[index]}
                            onChange={(e) => {
                              const updatedValue = [...value]
                              updatedValue[index] = e.target.value
                              onChange(updatedValue)
                            }}
                          />
                          <span className="text-xs text-lingheGray absolute top-1/2 right-3 -translate-y-1/2">
                            {value[index]?.length}/50
                          </span>
                        </div>
                        <span
                          onClick={(e) => {
                            e.preventDefault()
                            if (value.length === 1) return
                            onChange(filter(value, (_, i) => i !== index))
                          }}
                          className={cn(
                            'w-6 h-6 rounded text-lingheGray cursor-pointer hover:bg-[#F6F7F9] flex items-center justify-center hover:text-lingheError',
                            value.length === 1 ? 'cursor-not-allowed' : ''
                          )}
                        >
                          <Minus size={14} />
                        </span>
                      </div>
                    ))}
                  </div>
                )
              }}
            />

            <Controller
              name="response"
              control={control}
              render={({ field: { value } }) => (
                <div className="itemWrap">
                  <div className="flex items-center gap-2">
                    <span>{t('knowledgeBase.Replycontent')}</span>
                    <ReactSVG src={wordsTips} />
                  </div>
                  <TextareaWithEmoji ref={textareaRef} contentVal={value || ''} length={1000} />
                </div>
              )}
            />

            <Controller
              // name="imagesUrlList"
              name="contents"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <span>{t('chatFrame.回复文件')}</span>
                      <div className="flex items-center gap-1 text-lingheGray">
                        <ReactSVG src={wordsTips} />
                        <span>{t('knowledgeBase.uploadCount', { count: value?.length })}</span>

                        <TooltipProvider>
                          <Tooltip delayDuration={200}>
                            <TooltipTrigger>
                              <span
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                className="underline hover:text-lingheTextGray"
                              >
                                {t('knowledgeBase.aceptRule')}
                              </span>
                            </TooltipTrigger>
                            <TooltipContent className="max-w-[272px] bg-[rgba(0,0,0,0.6)] text-xs text-white p-0 pt-1 pb-1 pl-[6px] pr-[6px] rounded-md">
                              <div className="flex mb-1">
                                <span className="shrink-0">{t('knowledgeBase.uploadTipsTxt')}</span>
                                <p>
                                  .jpg .png .gif .bmp .webp .doc .docx .xls .xlsx .xlsm .ppt .pptx
                                  .pptm .pdf .txt
                                </p>
                              </div>
                              <div className="flex">
                                <span className="shrink-0">
                                  {t('knowledgeBase.uploadTipsTxt2')}
                                </span>
                                <p>
                                  {t('knowledgeBase.uploadImgLimit')}、
                                  {t('knowledgeBase.uploadFileLimit')}
                                </p>
                              </div>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </div>
                    <KeyWordsUploadFile
                      ref={uploadFileRef}
                      fileValue={value as IFileValue[]}
                      onChange={onChange}
                    />
                  </div>
                )
              }}
            />
          </div>

          <AlertDialogFooter
            style={{ flexDirection: 'row' }}
            className="flex items-center gap-2 border-t h-[54px] pr-3"
          >
            <Button
              variant="outline"
              onClick={(e) => {
                e.preventDefault()
                reset(defaultFormValue)
                // setFileName('')
                onClose && onClose()
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button type="submit" disabled={uploadFileRef?.current?.isPending}>
              {t('common.save')}
            </Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(AddKeyWords)
