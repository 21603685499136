import { create } from 'zustand'
import { IChatList } from '@renderer/api/chatList'
import { ChatListStatus } from '@renderer/api/model'

export type StateMessage = {
  active: number // 会话管理tab
  currentTab: number
  searchResult: { resultList: IChatList[]; value: string }
  chatItemActive: string // 当前会话id

  shoudScrollWhenSearch: boolean // 搜索关键字点击当前激活项 跳转
  itemData: IChatList
}

export type ActionMessage = {
  setActive: (val: number) => void
  setCurrentTab: (val: number) => void
  setSearchResult: (val: StateMessage['searchResult']) => void
  clearCurrent: () => void
  setChatItemActive: (val: string) => void

  setShouldScrollWhenSearch: () => void
  setItemData: (data: IChatList) => void
}

export const useCurrentAndSearch = create<StateMessage & ActionMessage>((set, get) => ({
  active: 0,
  chatItemActive: '',
  currentTab: ChatListStatus.ASSIGNED,
  searchResult: { resultList: [], value: '' },
  setActive: (active: number): void => set(() => ({ active })),
  setCurrentTab: (currentTab: number): void => set(() => ({ currentTab })),
  setSearchResult: (searchResult: StateMessage['searchResult']): void =>
    set(() => ({ searchResult })),
  clearCurrent: () => {
    set(() => ({
      active: 0,
      chatItemActive: '',
      currentTab: ChatListStatus.ASSIGNED,
      searchResult: { resultList: [], value: '' }
    }))
  },
  setChatItemActive: (chatItemActive: string) => set(() => ({ chatItemActive })),

  shoudScrollWhenSearch: false,
  setShouldScrollWhenSearch: () => {
    const { shoudScrollWhenSearch } = get()
    set(() => ({ shoudScrollWhenSearch: !shoudScrollWhenSearch }))
  },

  itemData: null,
  setItemData: (itemData: IChatList) => set(() => ({ itemData }))
}))
