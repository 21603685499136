import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * PresetMessageSaveDTO
 */
export interface SavePersonWordsRequest {
  groupId?: number;
  imageContent?: string[];
  /**
   * 短语编号 新增时不传
   */
  preMessageId?: number;
  shortcutKey?: string;
  textContent?: string;
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 保存快捷短语
 * @returns
 */
export const fetchSavePersonWords = async (
  params?: SavePersonWordsRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(`/preset/message`, params);

  return serialize(data);
};
