import { cn, Popover, PopoverContent, PopoverTrigger } from '@brisk/ui'
import { map } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { ReactSVG } from 'react-svg'
// import computer from '@renderer/assets/svg/computer.svg'
// import computerActive from '@renderer/assets/svg/computerActive.svg'
import turnManage from '@renderer/assets/svg/turnManage.svg'
import turnManage02 from '@renderer/assets/svg/turnManage02.svg'
import msg from '@renderer/assets/svg/msg.svg'
import msgActive from '@renderer/assets/svg/msgActive.svg'
// import accout from '@renderer/assets/svg/accout.svg'
// import accoutActive from '@renderer/assets/svg/accoutActive.svg'
// import lib from '@renderer/assets/svg/lib.svg'
// import libActive from '@renderer/assets/svg/libActive.svg'
import setting from '@renderer/assets/svg/setting.svg'
import settingActive from '@renderer/assets/svg/settingActive.svg'
import helpCenterIcon from '@renderer/assets/svg/helpCenterIcon.svg'
import helpCenterIcon02 from '@renderer/assets/svg/helpCenterIcon02.svg'
import serviceOnline from '@renderer/assets/svg/serviceOnline.svg'
import serviceOnline02 from '@renderer/assets/svg/serviceOnline02.svg'
import help from '@renderer/assets/svg/help.svg'
import help02 from '@renderer/assets/svg/help02.svg'
// import themeLight from '@renderer/assets/svg/themeLight.svg'
import { AvatarPopover } from './AvatarPopover'
import { AccountMenuInterface } from '../MessageMenu/type'
import { APP_ROUTER_PATH } from '@renderer/router'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '@renderer/store/useUserStore'
import { showServiceChat } from '@renderer/utils'
import { useNewBieGuide } from '../../../store/manageStore/useNewBieGuide'
import { useTranslation } from 'react-i18next'
import { useUserRoleStore } from '@renderer/store/useUserRoleStore'

export const testSrc = 'https://i.pravatar.cc/150?img=2'

interface IIconList {
  icon: (code: number) => JSX.Element
  id: string
  name: string
  code: number
  link: string
}

const AccountMenu: FC<AccountMenuInterface> = ({ onMenuActive }) => {
  const [active, setActive] = useState(3) // 先默认3
  const { user } = useUserStore()
  const navigate = useNavigate()
  const { client } = useNewBieGuide()
  const [opens, setOpens] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    if (client.contactService === 'open') {
      setOpens(true)
    } else {
      setOpens(false)
    }
  }, [client.contactService])
  const iconList: IIconList[] = useMemo(
    () => [
      // {
      //   icon: (code) => (
      //     <>
      //       <div className={cn('group-hover:hidden', code === 0 && 'hidden')}>
      //         <ReactSVG src={msg} />
      //       </div>
      //       <div className={cn('hidden group-hover:block', code === 0 && 'text-primary block')}>
      //         <ReactSVG src={msgActive} />
      //       </div>
      //     </>
      //   ),
      //   id: 'MessageSquareText',
      //   name: 'MessageSquareText',
      //   code: 0
      // },
      // {
      //   icon: (code) => (
      //     <>
      //       <div className={cn('group-hover:hidden', code === 1 && 'hidden')}>
      //         <ReactSVG src={accout} />
      //       </div>
      //       <div className={cn('hidden group-hover:block', code === 1 && 'text-primary block')}>
      //         <ReactSVG src={accoutActive} />
      //       </div>
      //     </>
      //   ),
      //   id: 'SquareUserRound',
      //   name: 'SquareUserRound',
      //   code: 1
      // },
      // {
      //   icon: (code) => (
      //     <>
      //       <div className={cn('group-hover:hidden', code === 2 && 'hidden')}>
      //         <ReactSVG src={lib} />
      //       </div>
      //       <div className={cn('hidden group-hover:block', code === 2 && 'text-primary block')}>
      //         <ReactSVG src={libActive} />
      //       </div>
      //     </>
      //   ),
      //   id: 'LibraryBig',
      //   name: 'LibraryBig',
      //   code: 2
      // },
      {
        icon: (code) => (
          <>
            <div className={cn('group-hover:hidden', code === 3 && 'hidden')}>
              <ReactSVG src={msg} />
            </div>
            <div className={cn('hidden group-hover:block', code === 3 && 'text-primary block')}>
              <ReactSVG src={msgActive} />
            </div>
          </>
        ),
        id: 'LaptopMinimal',
        name: 'LaptopMinimal',
        code: 3,
        link: APP_ROUTER_PATH.CHATTING_WINDOW
      },
      {
        icon: (code) => (
          <>
            <div className={cn('group-hover:hidden', code === 4 && 'hidden')}>
              <ReactSVG src={setting} />
            </div>
            <div className={cn('hidden group-hover:block', code === 4 && 'text-primary block')}>
              <ReactSVG src={settingActive} />
            </div>
          </>
        ),
        id: 'Bolt',
        name: 'Bolt',
        code: 4,
        link: APP_ROUTER_PATH.HOME_SETTING
      }
    ],
    [APP_ROUTER_PATH]
  )
  function handleMenuActive(code: number) {
    setActive(code)
    onMenuActive(code)
  }

  /**
   * Popover 监听开启/关闭状态
   * @param open
   */
  function onOpenChange(open: boolean) {
    if (client.contactService === 'open') {
      return
    } else {
      setOpens(open)
    }
  }

  useEffect(() => {
    const code = localStorage.getItem('activeMenuIconCode')
    if (code) {
      setActive(+code)
      localStorage.removeItem('activeMenuIconCode')
    }
  }, [])

  return (
    <div className="h-full flex flex-col items-center justify-between pb-10">
      <div className="">
        <div className="flex items-center justify-center mb-8">
          <AvatarPopover />
        </div>
        <div className="flex flex-col items-center gap-5 pl-3 pr-3 mb-5">
          {map(iconList, ({ icon, id, code, link }) => (
            <div
              key={id}
              className={cn(
                'w-10 h-10 flex items-center justify-center cursor-pointer hover:bg-lingheBtnHover rounded-lg group',
                code === active && 'bg-lingheBtnHover'
              )}
              onClick={() => {
                handleMenuActive(code)
                link && navigate(link)
              }}
            >
              {icon && icon(active)}
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center w-full gap-5">
          <Popover open={opens} onOpenChange={onOpenChange}>
            <PopoverTrigger>
              <div className="group w-10 h-10 flex items-center justify-center cursor-pointer group hover:bg-lingheBtnHover rounded-lg">
                <ReactSVG src={helpCenterIcon} className="group-hover:hidden" />
                <ReactSVG src={helpCenterIcon02} className="hidden group-hover:block" />
              </div>
            </PopoverTrigger>
            <PopoverContent
              className="p-2 w-[136px] h-[84px] translate-y-[30%]"
              side={'right'}
              sideOffset={3}
            >
              <div
                className="flex flex-col gap-1 w-full h-full [&_.item]:flex [&_.item]:items-center [&_.item]:justify-center [&_.item]:gap-[6px] [&_.item]:h-8 [&_.item]:rounded-md
              [&_.item:hover]:bg-lingheBtnHover [&_.item:hover]:text-primary [&_.item]:cursor-pointer onlineCustomer-service"
              >
                <div className="item group" onClick={showServiceChat}>
                  <ReactSVG src={serviceOnline} className="group-hover:hidden" />
                  <ReactSVG src={serviceOnline02} className="hidden group-hover:block" />
                  <span>{t('common.LiveChat')}</span>
                </div>
                <div
                  className="item group"
                  onClick={() => {
                    window.open(import.meta.env.VITE_HELP_URL, '_blank')
                  }}
                >
                  <ReactSVG src={help} className="group-hover:hidden" />
                  <ReactSVG src={help02} className="hidden group-hover:block" />
                  <span>{t('common.HelpCenter')}</span>
                </div>
              </div>
            </PopoverContent>
          </Popover>
          <div className="group w-10 h-10 flex items-center justify-center cursor-pointer group hover:bg-lingheBtnHover rounded-lg">
            <ReactSVG src={turnManage} className="group-hover:hidden computer" />
            <ReactSVG
              src={turnManage02}
              className="hidden group-hover:block"
              onClick={() => {
                const roleList = useUserRoleStore.getState()?.role?.roleIdList
                const isManager = roleList.includes('1') || roleList.includes('3')
                const userInfo = encodeURIComponent(JSON.stringify(user))
                window.open(
                  `${import.meta.env.VITE_ADMIN_URL}${isManager ? APP_ROUTER_PATH.MANAGER_HOME : APP_ROUTER_PATH.MANAGER_CUSTOMERMANAGE}?from=desktop&userInfo=${userInfo}`,
                  '_blank'
                )
              }}
            />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default AccountMenu
