import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import search from '@renderer/assets/svg/search.svg'
import { ReactSVG } from 'react-svg'
import { cn } from '@brisk/ui'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'

interface Props {
  handleInput?: (value: string) => void
}
const inputIconClassName = 'absolute left-3 top-1/2 -translate-y-1/2'

const SearchComp = forwardRef(({ handleInput }: Props, ref) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    onReset() {
      inputRef.current.value = ''
    }
  }))
  /**
   * 监听输入框
   * @param event
   */
  const onInput = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    handleInput(value)
  }, 500)
  return (
    <section className="relative pr-3">
      <ReactSVG src={search} className={cn(inputIconClassName)} />
      <input
        ref={inputRef}
        onInput={onInput}
        placeholder={t('chatFrame.请输入姓名、账号搜索')}
        className="placeholder:text-[#A5A7B1] placeholder:text-sm border border-[#ECECEC] focus:outline-none pr-3 pl-[1.9rem] py-[0.3125rem] rounded-md"
      />
    </section>
  )
})
SearchComp.displayName = 'SearchComp'
export default SearchComp
