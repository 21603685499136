import { FC, memo } from 'react'
import createStep01 from '@renderer/assets/svg/createStep01.svg'
import createStep02 from '@renderer/assets/svg/createStep02.svg'
import createStep02Active from '@renderer/assets/svg/createStep02Active.svg'
import { map } from 'lodash'
import { ReactSVG } from 'react-svg'
import { cn } from '@brisk/ui'
import { useTranslation } from 'react-i18next'

const CreateStepCom: FC<{
  step: number
}> = ({ step }) => {
  const { t } = useTranslation()

  const stepData = [
    {
      icon: createStep01,
      iconActive: createStep01,
      title: `1. ${t('chatFrame.配置基础信息')}`,
      content: `${t('chatFrame.为您的聊天插件取个名字')}`
    },
    {
      icon: createStep02,
      iconActive: createStep02Active,
      title: `2. ${t('chatFrame.选择接待客服')}`,
      content: `${t('chatFrame.选择对应的坐席处理消息')}`
    }
  ]

  return (
    <div className="w-full h-full flex flex-col gap-[72px]">
      {map(stepData, (item, i) => {
        const hasStep = step >= i + 1
        return (
          <div
            key={item.title + i}
            className={cn(
              'flex gap-2 relative',
              i < stepData.length - 1 &&
                `after:absolute after:content-[''] after:w-[2px] after:h-[88px] after:bg-primary after:left-4 after:top-[80%]`
            )}
          >
            <ReactSVG src={hasStep ? item.iconActive : item.icon} />

            <div className="flex flex-col gap-1">
              <div className={cn('text-sm font-semibold', hasStep && 'text-primary')}>
                {item.title}
              </div>
              <div className="text-xs text-lingheGray">{item.content}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default memo(CreateStepCom)
