import { ChatListStatus } from '@renderer/api/model'
import { handleConvStatusChange } from '../juggleHandles'
import { timGetJoinedGroupList } from './ipcTimSdk'

export const handleConversaionListener = (conv) => {
  const { type, data } = conv
  switch (type) {
    /**
     * 新增会话
     */
    case 0:
      console.log('新增会话')
      try {
        timGetJoinedGroupList()
        handleConvStatusChange(data[0]?.conv_id, ChatListStatus.ASSIGNED)
      } catch (error) {
        console.log('error', error)
      }
      break
    /**
     * 删除会话
     */
    case 1:
      console.log('删除会话')
      try {
        timGetJoinedGroupList()
        handleConvStatusChange(data[0]?.split('_')[1], ChatListStatus.FINISHED)
      } catch (error) {
        console.log('error', error)
      }
      break
    /**
     * 会话同步完成
     */
    case 2:
      // console.log('同步会话完成')
      // _updateConversation(data)
      break
    /**
     * 会话开始同步
     */
    case 3:
      // console.log('开始同步会话')
      break
    /**
     * 会话更新
     */
    case 4:
      // console.log('会话更新')
      break
  }
}
