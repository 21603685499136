import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * ChannelTelegramSaveDTO
 */
export interface IAddTelegramConfigRequest {
  /**
   * 机器人令牌
   */
  token: string
  remark?: string
  /**
   * 选中坐席ids
   */
  userIds?: string[]
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * telegram渠道 保存配置
 * @param token
 * @returns
 */
export const fetchAddTelegramConfig = async (
  param: IAddTelegramConfigRequest
): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/channel/telegram/save`, param)

  return serialize(data)
}
