import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import {
  TeamWordsFromPcRequest,
  TeamWordsFromPcResponse,
} from "./fetchTeamWordsFromPc";

interface ApiResp extends ClientResponse {
  data: TeamWordsFromPcResponse[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 个人话术
 * @returns
 */
export const fetchPersonWordsFromPc = async (
  params?: TeamWordsFromPcRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/preset/message/list`,
    params,
  );

  return serialize(data);
};
