import { cn } from '@brisk/ui'
import { FC, memo, useEffect, useState } from 'react'
import './preview.css'

const MIN_WIDTH = 360 // 最小宽度
const MIN_HEIGHT = 572 // 最小高度

const phoneScreenWidth = 820
const PreviewChat: FC = () => {
  const [token, setToken] = useState('')
  const [isPhone, setIsPhone] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight) // 监听窗口尺寸变化

  // 判断 iframe 是否需要使用最小高度
  const iframeWidth = isPhone ? windowWidth : Math.min(windowWidth * 0.4, 700) // 根据设备选择宽度
  const iframeHeight = windowWidth <= MIN_WIDTH ? '100%' : isPhone ? windowHeight : '100%'

  const shouldAutoHeight = !isPhone && windowWidth <= 1780
  const handleResize = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight) // 当屏幕拉伸变化时重新判断是否为移动设备

    if (window.innerWidth <= phoneScreenWidth || isMobile()) {
      setIsPhone(true)
    } else {
      setIsPhone(false)
    }
  }

  const isMobile = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0
    const isMobileUserAgent = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    )
    const isSmallScreen = window.innerWidth <= phoneScreenWidth
    return isMobileUserAgent || (isTouchDevice && isSmallScreen)
    // return isSmallScreen
  }

  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search.split('?')[1])
    const token = params.get('token')
    setToken(token) // 初始化判断是否为移动设备

    setIsPhone(isMobile()) // 添加窗口 resize 监听

    window.addEventListener('resize', handleResize)

    return () => {
      // 清理窗口 resize 监听
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      className={cn('bg-[#F3F8FE]')}
      style={{
        width: windowWidth,
        height: windowHeight,
        overflow: 'auto'
      }}
    >
      <div
        className="w-full h-full flex items-center justify-center"
        style={{
          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          overflow: 'hidden'
        }}
      >
        <iframe
          src={`${import.meta.env.VITE_PREVIEW_URL}?token=${token}&hide=1`}
          style={{
            border: 'none',
            // width: iframeWidth,
            width: shouldAutoHeight ? windowWidth : iframeWidth,
            // height: iframeHeight,
            height: shouldAutoHeight ? windowHeight : iframeHeight,
            maxWidth: '700px',
            maxHeight: '1016px',
            minWidth: MIN_WIDTH,
            minHeight: MIN_HEIGHT
          }}
        ></iframe>
      </div>
    </div>
  )
}

export default memo(PreviewChat)
