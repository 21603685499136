import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { ITagList } from "../manage/fetchTagList";

interface RequestBody {
  id: string;
}

/**
 * SessionDetailVO
 */
export interface UserDetailResponse {
  /**
   * 渠道的icon图片地址
   */
  channelIconUrl?: string;
  /**
   * 渠道编号
   */
  channelId?: string;
  /**
   * 渠道名称
   */
  channelName?: string;
  /**
   * 渠道类型 独立站：WEBSITE
   */
  channelType?: string;
  customerInfo?: CustomerInfoVO;
  /**
   * 群编号
   */
  imGroupId?: number;
  /**
   * 转出记录
   */
  referralInfoList?: ReferralInfoVO[];
  /**
   * 会话编号
   */
  sessionId?: string;
  channelEnableType?: number; // 0-未启用 1-启用
  /**
   * 会话发起时间
   */
  startTime?: number;
  [property: string]: any;
}

/**
 * CustomerInfoVO，客户信息
 */
export interface CustomerInfoVO {
  /**
   * 地址
   */
  address?: string;
  /**
   * 头像地址
   */
  avatar?: string;
  /**
   * 公司名称
   */
  companyName?: string;
  /**
   * 邮箱
   */
  email?: string;
  /**
   * 访客名称
   */
  name?: string;
  /**
   * 手机号
   */
  phone?: string;
  tag: ITagList[];
  [property: string]: any;
}

/**
 * ReferralInfoVO，转出记录
 */
export interface ReferralInfoVO {
  /**
   * 转出时间
   */
  createTime?: Date;
  /**
   * 转出客服编号
   */
  outUserId?: number;
  /**
   * 转出客服名称
   */
  outUserName?: string;
  /**
   * 备注
   */
  remark?: string;
  [property: string]: any;
}

interface ApiResp extends ClientResponse {
  data: UserDetailResponse;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

export const fetchUserDetail = async (id: RequestBody): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/session/detail/${id}`);

  return serialize(data);
};
