import {
  ApiReqFn,
  ApiRespSerializeFn,
  ClientResponse,
  httpClient
} from '../../http-client/httpClient'

interface RequestBody {
  /**
   * 用户昵称
   */
  nickName: string
  /**
   * 密码
   */
  password: string
  /**
   * 临时token 从未选择租户登录接口获取
   */
  tempToken: string
  /**
   * 租户名称
   */
  tenantName: string
}

interface ApiResp extends ClientResponse {
  data: string
}

interface Data extends ClientResponse {
  data: {
    tenantId: string
  }
}
const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
  return { ...resp, data: { tenantId: resp.data } }
}
// 创建企业
export const mutationCreateTenant: ApiReqFn<RequestBody, Data> = async (payload) => {
  const { data } = await httpClient.post<ApiResp>(`/tenant`, {
    ...payload
  })

  return serialize(data)
}
