import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 删除关键词

 */
export const fetchDeleteKeyWords = async (keywordId): Promise<ApiResp> => {
  const { data } = await apiClient.delete<ApiResp>(
    `/bg/kb/keyword/${keywordId}`,
  );

  return serialize(data);
};
