import { cn } from '@brisk/ui'
import { useNewBieGuide } from '@renderer/store/manageStore/useNewBieGuide'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Joyride, { CallBackProps, Step } from 'react-joyride'
import { ReactSVG } from 'react-svg'
import joyridearrow from '@renderer/assets/svg/joyridearrow.svg'

let step_index = 0
const HomePageJoyride: FC<{
  open: boolean
  setOpen: (open: boolean) => void
}> = ({ open, setOpen }) => {
  const [stepIndex, setStepIndex] = useState(0)
  const { client, setClientStatus } = useNewBieGuide()

  const { t } = useTranslation()

  const steps = useMemo(
    () => [
      {
        title: t('managerBeginnerGuide.Communicatewithyourcustomers'),
        target: '.bar-header',
        placement: 'right-end',
        disableBeacon: true,
        content: t('managerBeginnerGuide.CommunicatewithyourcustomersMessage'),
        zIndex: 9999
      },
      {
        title: t('managerBeginnerGuide.Customerserviceworkstatus'),
        target: '.popoverContent',
        content: t('managerBeginnerGuide.CustomerserviceworkstatusMessage'),
        placement: 'right-start'
      },
      {
        title: t('managerBeginnerGuide.ContactAIOChatCustomerServiceto'),
        target: '.onlineCustomer-service',
        content: t('managerBeginnerGuide.ContactAIOChatCustomerServicetoMessage'),
        placement: 'right-start'
      },
      {
        title: t('managerBeginnerGuide.ManagementBackground'),
        target: '.computer',
        content: t('managerBeginnerGuide.ManagementBackgroundMessage'),
        placement: 'right-start'
      }
    ],
    []
  )
  const placement = useMemo(
    () => ({
      '1': {
        tooltipTop: 'top-[-22px] left-[45px] z-[9999]',
        reactSVG: 'left-[-67px] top-[31px]'
      },
      '2': {
        tooltipTop: 'top-[-50px] left-[50px]',
        reactSVG: 'left-[-67px] top-[31px]'
      },
      '3': {
        tooltipTop: 'top-[-142px] left-[40px]',
        reactSVG: 'bottom-[80px] left-[-67px]'
      },
      '4': {
        tooltipTop: 'top-[-156px] left-[48px]',
        reactSVG: 'bottom-[46px] left-[-67px]'
      }
    }),
    []
  )
  const Tooltip = useCallback(
    ({ index, step, backProps, closeProps, primaryProps, skipProps, tooltipProps }) => {
      return (
        <div
          className={cn(
            'tooltip-body min-w-[414px] p-6 min-h-[192px] absolute rounded-lg bg-[#247CFF] top-[-62px] left-[30px] text-sm',
            placement[index + 1].tooltipTop
          )}
          {...tooltipProps}
        >
          <ReactSVG src={joyridearrow} className={cn('absolute', placement[index + 1].reactSVG)} />
          <p className="text-base text-[#FFFFFF] pb-3">
            {index + 1}
            <span className="text-[rgba(255,255,255,0.50)]">/{steps.length}</span>
          </p>
          <h1 className="text-xl text-[#FFFFFF] pb-2">{step.title}</h1>
          <p className="text-[#FFFFFF] font-normal text-sm pb-6 whitespace-pre">{step.content}</p>
          <div className="tooltip-footer">
            {index + 1 === steps.length ? (
              <div
                className="guide-next float-right w-[88px] text-base text-[#247CFF] leading-8 h-8 bg-[#FFFFFF] rounded text-center"
                {...closeProps}
              >
                {t('managerBeginnerGuide.IgotIt')}
              </div>
            ) : (
              <div className="flex items-center justify-between">
                <span className="text-base text-[#FFFFFF]" {...skipProps}>
                  {t('managerBeginnerGuide.Skip')}
                </span>
                <div className="flex items-center gap-[12px]">
                  {index > 0 ? (
                    <div
                      className="guide-prev w-[74px] text-base text-[#FFFFFF] leading-8 h-8 li rounded bg-[rgba(255,255,255,0.20)] text-center"
                      {...backProps}
                    >
                      {t('managerBeginnerGuide.Previous')}
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    className="guide-next w-[74px] text-base text-[#247CFF] leading-8 h-8 bg-[#FFFFFF] rounded text-center"
                    {...primaryProps}
                  >
                    {t('managerBeginnerGuide.Next')}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )
    },
    []
  )

  const callback = useCallback((data: CallBackProps) => {
    const { action, index, type } = data
    const next_router = {
      '0': 'customerStatus',
      '1': 'contactService'
    }
    const prev_router = {
      '1': 'customerStatus',
      '2': 'contactService'
    }
    if (type === 'tour:end') {
      tourEnd()
    }
    switch (action) {
      case 'prev':
        if (type === 'step:after') {
          if (prev_router[index - 1]) {
            client[prev_router[index - 1]] = 'open'
          }
          client[prev_router[index]] = 'close'
          setClientStatus(client)
          step_index = index - 1
          setTimeout(() => {
            setStepIndex(index - 1)
            step_index = index - 1
          }, 100)
        }
        break
      case 'next':
        if (type === 'step:after') {
          if (next_router[index]) {
            client[next_router[index]] = 'open'
          }
          client[next_router[index - 1]] = 'close'
          setClientStatus(client)
          step_index = index + 1
          setTimeout(() => {
            setStepIndex(index + 1)
            step_index = index + 1
          }, 100)
        }
        break
      case 'close':
        tourEnd()
        break
    }
  }, [])

  // 跳过 结束
  function tourEnd() {
    client.customerStatus = 'close'
    client.contactService = 'close'
    setClientStatus(client)
    setOpen?.(false)
    localStorage.setItem('newbieGuide', 'fulfil')
  }
  return (
    <div className="relative">
      <Joyride
        callback={callback}
        run={open}
        stepIndex={stepIndex}
        tooltipComponent={Tooltip}
        steps={steps as Array<Step>}
        disableCloseOnEsc
        disableOverlayClose
        continuous={true}
        showSkipButton
        styles={{
          options: {
            arrowColor: 'transparent',
            // overlayColor: step_index === 1 || step_index === 2 ? 'transparent' : '#00000080',
            // overlayColor: 'transparent',
            zIndex: 10000
          }
        }}
      />
      {/* {(step_index === 1 || step_index === 2) && (
        <div className="fixed w-full h-full bg-[#00000080] left-0 top-0 z-[1] pointer-events-none" />
      )} */}
      {/* <div className="fixed w-full h-full bg-[#00000080] left-0 top-0 pointer-events-none" /> */}
    </div>
  )
}

export default memo(HomePageJoyride)
