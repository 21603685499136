import { IParamForPage } from '@renderer/utils/consts'
import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * PageChannelTelegramVO
 */
export interface ITelegramResp {
  countId?: string
  current?: number
  pages?: number
  records?: ChannelTelegramVO[]
  searchCount?: Response
  size?: number
  total?: number
}

/**
 * ChannelTelegramVO
 */
export interface ChannelTelegramVO {
  id: string
  /**
   * 渠道编号
   */
  channelId?: string
  /**
   * 渠道类型 独立站：WEBSITE
   */
  channelType?: string
  /**
   * 创建时间
   */
  createTime?: Date
  /**
   * 是否有效 0:失效 1:有效
   */
  effective: number
  /**
   * 是否启用 0-否 1-是
   */
  enable?: number
  /**
   * 渠道的icon图片地址
   */
  iconUrl?: string
  /**
   * 渠道名称
   */
  name?: string
  userName?: string // 机器人用户名
  tokenEffective?: number // 机器人token是否有效
  tokenCheckDate?: string // token检查时间
  /**
   * 备注
   */
  remark: string
  /**
   * 租户编号
   */
  tenantId?: string
  token?: string
}

interface ApiResp extends ClientResponse {
  data: ITelegramResp
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 分页搜索telegram配置
 * @param param
 * @returns
 */
export const fetchTelegramConfig = async (param: IParamForPage): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/channel/telegram/page`, param)

  return serialize(data)
}
