import { cn } from '@brisk/ui'
import { message } from '@renderer/components/message'
import { whiteSpaceStyle } from '@renderer/pages/home/Chatting/ChattingWindow'
import EmojiPopover from '@renderer/pages/home/Chatting/EmojiPopover'
import { getNodeTextContent, onJudgeImageIsHeic, replaceImgWithAlt } from '@renderer/utils'
import { MAX_INP_LENGTH } from '@renderer/utils/consts'
import { replaceEmojiMark } from '@renderer/utils/emojiData'
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  ClipboardEvent,
  Ref
} from 'react'
import { useTranslation } from 'react-i18next'

interface ITextareaWithEmojiProps {
  contentVal?: string
  onChange?: (val: string) => void
  length?: number
}

export interface ITextareaWithEmojiRef {
  preCurrent: HTMLDivElement
  content: string
  setContent: (val: string) => void
  preRef: Ref<HTMLDivElement>
}

const TextareaWithEmoji = forwardRef(
  ({ contentVal, onChange, length }: ITextareaWithEmojiProps, ref) => {
    const [content, setContent] = useState<string>('')
    const [inpLen, setInpLen] = useState(0)
    const [selection, setSelection] = useState<Selection | null>(null)
    const [lastRange, setLastRange] = useState<Range | null | undefined>(null)

    const preRef = useRef<HTMLPreElement>(null)
    const { t } = useTranslation()
    const maxLength = length || MAX_INP_LENGTH

    useImperativeHandle(ref, () => ({
      preCurrent: preRef?.current,
      content: content?.slice(0, maxLength),
      setContent,
      preRef
    }))

    const getLastRange = () => {
      const selection = window.getSelection()
      setSelection(selection)
      const range = selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null
      if (range) setLastRange(selection?.getRangeAt(0))
      else setLastRange(null)
    }

    const handlePaste = async (event: ClipboardEvent<HTMLPreElement>): Promise<void> => {
      event.preventDefault()
      event.stopPropagation()

      const clipboard = event.clipboardData
      if (clipboard.files.length > 0 && onJudgeImageIsHeic(clipboard.files[0])) {
        return
      }

      const text = await navigator.clipboard.readText() // 只调用一次
      if (typeof text !== 'string') return // 直接检查文本类型

      if (!preRef.current) return
      const selection = window.getSelection()
      if (!selection || !selection.getRangeAt || selection.rangeCount <= 0) return

      const range = selection.getRangeAt(0)
      range.deleteContents() // 删除选区内容

      let txt = replaceImgWithAlt(text)
      // 如果新内容超过最大输入长度 500，则截取
      if (txt.length > maxLength) {
        txt = txt.slice(0, maxLength)
      }
      const len = (content + txt).trim().length
      setInpLen(len > maxLength ? maxLength : len)
      // 确保内容不会超过最大长度
      setContent((prevContent) => {
        const t = prevContent + txt
        const content = t.length > maxLength ? prevContent.slice(0, maxLength) : t

        return content
      })

      const fragment = range.createContextualFragment(replaceEmojiMark(txt))
      range.insertNode(fragment)
      range.collapse(false)

      // 确保重新选中粘贴后的内容
      selection.removeAllRanges()
      selection.addRange(range)
    }

    useEffect(() => {
      let timer
      if (contentVal) {
        setContent(contentVal)
        setInpLen(contentVal.trim().length)

        timer = setTimeout(() => {
          if (preRef?.current) preRef.current.innerHTML = replaceEmojiMark(contentVal)
        }, 0)
      }
      return (): void => {
        clearTimeout(timer)
      }
    }, [contentVal])

    return (
      <div className="w-full h-[164px] border rounded-md flex flex-col">
        <div className="h-[36px] flex items-center pl-3 bg-[#ECECEC]">
          <EmojiPopover
            onClick={(emoji) => {
              const val = content + emoji
              if ((content + emoji).length > maxLength) return
              preRef?.current?.focus()
              const selection = window.getSelection()
              setSelection(selection)
              if (!selection || !selection.getRangeAt || selection.rangeCount <= 0) return
              if (selection && selection.rangeCount > 0) {
                setLastRange(selection?.getRangeAt(0))
              } else {
                setLastRange(null)
              }
              if (lastRange) {
                const selection = window.getSelection()
                selection.removeAllRanges()
                selection.addRange(lastRange)
              }
              const range = selection.getRangeAt(0)
              if (range) setLastRange(selection?.getRangeAt(0))
              else setLastRange(null)
              range.deleteContents()
              const fragment = range.createContextualFragment(emoji)
              range.insertNode(fragment)
              range.collapse(false)
              selection.removeAllRanges()
              selection.addRange(range)
              setInpLen(val.trim().length)
              setContent(val)
              onChange && onChange(val)
            }}
          />
        </div>

        <div className="flex-1 w-full bg-white shrink-0 relative overflow-y-auto text-base">
          <pre
            ref={preRef}
            className={cn(
              'rounded-md p-3 w-full h-full outline-none bg-white focus:after:hidden after:absolute after:top-3 after:left-3 after:text-lingheGray'
            )}
            contentEditable
            suppressContentEditableWarning
            onPaste={handlePaste}
            onClick={getLastRange}
            onInput={(e) => {
              // 获取文本内容并处理图片 alt 替换
              const result = replaceImgWithAlt(getNodeTextContent(e.target as HTMLElement)?.content)
              const newCount = result.length || 0

              // 获取当前光标位置
              const selection = window.getSelection()
              setSelection(selection)
              const range = selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null
              if (range) setLastRange(selection?.getRangeAt(0))
              else setLastRange(null)
              if (newCount > maxLength) {
                // 显示错误信息
                message(t('clientManagement.Maximumwordlimitexceeded'), 'error')

                // 截断文本至500字符
                const truncatedResult = result.slice(0, maxLength)

                // 设置截断的内容到 `pre` 标签中
                e.currentTarget.textContent = truncatedResult
                setInpLen(truncatedResult.trim().length)
                setContent(truncatedResult)
                onChange && onChange(truncatedResult)

                // 恢复光标位置
                if (range) {
                  selection?.removeAllRanges()
                  selection?.addRange(range)
                }
              } else {
                setInpLen(result.trim().length)
                setContent(result)
                onChange && onChange(result)
              }
            }}
            style={whiteSpaceStyle}
          />
        </div>
        <div className="relative h-0">
          <span className="text-lingheTextGray absolute right-2 bottom-1">
            {inpLen}/{maxLength}
          </span>
        </div>
      </div>
    )
  }
)

TextareaWithEmoji.displayName = 'TextareaWithEmoji'

export default memo(TextareaWithEmoji)
