import queryString from 'query-string'
import heic2any from 'heic2any'
import { message } from '@renderer/components/message'
import {
  fetchGetUserInfo,
  IChatList,
  IKeywordContent,
  RESPONSE_CODE,
  UserDetailResponse
} from '@brisk/api'
import { t } from 'i18next'
import { IFileValue } from '@renderer/pages/manager/Components/KeyWordsUploadFile'
import { debounce, map } from 'lodash'
import { MESSAGE_TYPE } from '@brisk/utils'
import { Buffer } from 'buffer'
import { useUserStore } from '@renderer/store/useUserStore'
import { User } from '@renderer/model/data/user'

export const trimAll = (str: string): string => {
  return str.replace(/\s+/g, '')
}

export const generateUrlWithQueryString = (
  path: string,
  queryParams: Record<string, unknown>
): string => {
  const query = queryString.stringify(queryParams)

  return `${path}?${query}`
}
export const isElectron = !!window.electron

export const isDevLocal = import.meta.env.DEV // 本地开发

export const isDev = import.meta.env.VITE_MODE === 'development'
export const isTest = import.meta.env.VITE_MODE === 'test'
export const isProd = import.meta.env.VITE_MODE === 'production'
export const isDevOrTest =
  import.meta.env.VITE_MODE === 'development' || import.meta.env.VITE_MODE === 'test'

export const getRandomInt = (min, max): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

// 判断图片是否为heic类型
export const onJudgeImageIsHeic = (heicFile: File): boolean => {
  return (
    heicFile.type === 'image/heic' || heicFile.name.split('.').pop()?.toLocaleLowerCase() === 'heic'
  )
}

// 将HEIC文件转换为PNG文件
export async function convertHEICtoPNG(heicFile: File) {
  // 判断文件是否为heic文件，否则直接返回
  if (!onJudgeImageIsHeic(heicFile)) return heicFile

  try {
    const pngData = await heic2any({
      blob: new Blob([heicFile], { type: 'image/heic' }),
      toType: 'image/png'
    })
    const name = heicFile.name.split('.').shift() || new Date().getTime().toString()

    // 返回转换后的PNG文件
    return new File([pngData as BlobPart], name + '.png', { type: 'image/png' })
  } catch (error) {
    console.error('转换失败:', error)

    return Promise.reject(error)
  }
}

/**
 * 复制文本
 * @param text
 */
export const copyTextToClipboard = async (text): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text)
    message(t('editInformation.contentcopiedclipboard'), 'success')
  } catch (err) {
    console.error('复制到剪贴板失败', err)
    return Promise.reject(err)
  }
}

// 过滤字符串中特殊符号，防止渲染为 html 节点
export const filterHtmlEntities = (str: string): string => {
  return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
}

// 获取目标节点纯文本内容
export const getNodeTextContent = (ele: HTMLElement): { content: string; imgSrc: string } => {
  let res = ''
  let imgSrc = ''
  let nodeArr = Array.from(ele.childNodes) as Node[]

  if (nodeArr.length > 1 && nodeArr[nodeArr.length - 1]?.nodeName === 'BR') {
    nodeArr = nodeArr.slice(0, -1)
  }

  nodeArr.forEach((child) => {
    if (child.nodeName === '#text') {
      res += child.nodeValue || ''
    } else if (child.nodeName === 'BR') {
      res += '\n'
    } else if (['P', 'SPAN', 'BUTTON'].includes(child.nodeName)) {
      res += getNodeTextContent(child as HTMLElement).content
    } else if (child.nodeName === 'IMG') {
      const img = child as HTMLImageElement
      const src = img.src
      if (/^https?:\/\//.test(src) && img.getAttribute('data-type') !== 'emoji') {
        imgSrc = src
      }
      res += img.alt || ''
    } else if (child.nodeName === 'DIV') {
      const childNodes = Array.from(child.childNodes)
      if (
        (childNodes.length === 1 && childNodes[0].nodeName === 'BR') ||
        (child.previousSibling && child.previousSibling.nodeName === 'BR')
      ) {
        res += getNodeTextContent(child as HTMLElement).content
      } else {
        res += '\n' + getNodeTextContent(child as HTMLElement).content
      }
    }
  })

  return {
    content: filterHtmlEntities(res),
    imgSrc
  }
}

/**
 * 过滤文本中的 html 标签
 * @param str
 * @returns
 */
export const replaceHtmlElement = (str: string): string => {
  if (!str) return ''
  const regex = /<[^>]+>/g
  return str.replace(regex, '')
}

/**
 * 定义读取文件为Base64的箭头函数
 * @param file
 * @returns
 */
export const readFileAsBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const getFileExtension = (fileName: string): string => {
  const parts = fileName.split('.')
  const fileEext = parts.pop()
  if (!fileEext) {
    return ''
  }
  return '.' + fileEext
}
export const formatDurationHDMin = (seconds: number): string => {
  if (seconds <= 0) return '0min'

  const days = Math.floor(seconds / (24 * 3600))
  seconds %= 24 * 3600
  const hours = Math.floor(seconds / 3600)
  seconds %= 3600
  const minutes = Math.floor(seconds / 60)

  const result: string[] = []
  if (days > 0) result.push(`${days}d`)
  if (hours > 0 || result.length > 0) result.push(`${hours}h`)
  if (minutes > 0 || result.length === 0) result.push(`${minutes}min`)

  return result.join('')
}

export const replaceImgWithAlt = (text): string => {
  // 正则表达式匹配<img>标签
  const imgTagPattern = /<img[^>]*alt\s*=\s*["']([^"']*)["'][^>]*>/g
  // 使用正则表达式替换<img>标签为alt属性的值
  const result = text?.replace(imgTagPattern, '$1')

  return result
}

/**
 * 数字格式转换
 * @param num
 * @returns
 */
export const formatNumberWithK = (num: number): string => {
  if (num >= 10000) {
    const quotient = num / 1000
    // Check if the result is an integer
    if (Math.floor(quotient) === quotient) {
      return Math.floor(quotient) + 'K'
    } else {
      return quotient.toFixed(1) + 'K'
    }
  } else {
    return num.toString()
  }
}

/**
 * 类型：1：进行中，2：排队中，3：已结束，4：在线坐席数，5：忙碌坐席数，6：离线坐席数，
 */
export enum CustomerStatus {
  '进行中' = 1,
  '排队中' = 2,
  '已结束' = 3,
  '在线坐席数' = 4,
  '忙碌坐席数' = 5,
  '离线坐席数' = 6
}

/**
 * 判断终端来源
 * @returns
 */
export const detectDeviceType = (): 'pc' | 'mobile' => {
  const userAgent = navigator.userAgent || navigator.vendor || window?.opera

  // 判断移动设备
  const mobileKeywords = ['Mobile', 'Android', 'iPhone', 'iPod', 'iPad']
  if (mobileKeywords.some((keyword) => userAgent.indexOf(keyword) > 0)) {
    return 'mobile'
  }

  // 如果没有匹配到移动设备关键词，则认为是PC端
  return 'pc'
}

/**
 * 遮罩手机号码中间四位
 * @param phoneNumber
 * @returns
 */
export const maskShortPhone = (phoneNumber = ''): string => {
  // 检查电话号码的长度
  if (phoneNumber?.length < 2) {
    throw new Error('Phone number must be at least 2 digits long.')
  }

  let maskedPhone

  // 根据电话号码的长度决定如何进行遮罩
  if (phoneNumber?.length <= 6) {
    // 如果电话号码长度小于等于6位，只替换中间的两位
    const midIndex = Math.floor(phoneNumber?.length / 2)
    maskedPhone = phoneNumber?.slice(0, midIndex - 1) + '**' + phoneNumber?.slice(midIndex + 1)
  } else {
    // 如果电话号码长度大于6位，按照之前的方法替换倒数第三位到第六位
    maskedPhone = phoneNumber?.slice(0, -6) + '****' + phoneNumber?.slice(-3)
  }

  return maskedPhone
}

/**
 * 未分配列表 用户会话详情
 * @param data
 * @returns
 */
export const handleFormatUserDetailData = (data: IChatList): UserDetailResponse => {
  return {
    sessionId: data?.sessionId,
    channelId: data?.channelId,
    channelName: data?.channelName,
    channelIconUrl: data?.channelIconUrl,
    imGroupId: data?.imGroupId,
    startTime: data?.startTime,
    customerInfo: {
      tag: data?.customerInfo?.tag || [],
      name: data?.conversationTitle,
      avatar: data?.customerAvatar
    }
  }
}

export const getDeviceType = () => {
  const userAgent = navigator.userAgent

  if (/android/i.test(userAgent)) {
    return 'Android'
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  } else {
    return '其他设备'
  }
}

export const language_state = {
  zh: '',
  en: 'w-[7.8rem]',
  tc: '',
  ar: 'w-[6.8rem]'
}

export const hiddenServiceChat = () => {
  window?.AIOChatSDK?.hide()
  // const scriptDom = document.querySelector('#AIOChat-trigger') as HTMLElement
  // if (scriptDom) {
  //   scriptDom.style.opacity = '0'
  //   scriptDom.style.zIndex = '-99'
  // }
}

export const showServiceChat = () => {
  window?.AIOChatSDK?.show()
  // const scriptDom = document.querySelector('#AIOChat-trigger') as HTMLElement
  // console.log('scriptDom', scriptDom)
  // if (scriptDom) {
  //   scriptDom?.click()
  // }
}

//  验证邮箱的正则表达式
export const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/

/**
 * 格式化关键词回复文件类
 */
export const handleFormatFileContents = (data: IFileValue[]): IKeywordContent[] => {
  if (!data.length) return []

  return map(data, ({ type, ...item }) => {
    let messageType: string

    switch (true) {
      case type.startsWith('image/'):
        messageType = MESSAGE_TYPE.GENERIC_IMAGE
        break
      case type.startsWith('audio/'):
        messageType = MESSAGE_TYPE.GENERIC_VOICE
        break
      case type.startsWith('video/'):
        messageType = MESSAGE_TYPE.GENERIC_VIDEO
        break
      // case type === 'text/plain':
      //   messageType = MESSAGE_TYPE.GENERIC_TEXT
      //   break
      default:
        messageType = MESSAGE_TYPE.GENERIC_FILE // 其他类型归为文件
    }

    return { ...item, type, messageType }
  })
}

/**
 * 文件大小
 * @param bytes
 * @returns
 */
export const formatFileSize = (bytes = 0) => {
  const units = ['B', 'K', 'M', 'G', 'T']
  let len = bytes
  let factor = 0

  while (len >= 1024 && factor < units.length - 1) {
    len /= 1024
    factor++
  }

  return `${len.toFixed(2)}${units[factor]}`
}

/**
 * 将 File 对象转换为 Buffer
 * @param file File 对象
 * @returns {Promise<Buffer>} 转换后的 Buffer
 */
export const fileToBuffer = (file: File): Promise<Buffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    // 读取完成时，将 ArrayBuffer 转换为 Buffer
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer
      const buffer = Buffer.from(arrayBuffer)
      resolve(buffer)
    }

    // 出现错误时拒绝 Promise
    reader.onerror = (error) => {
      reject(error)
    }

    // 读取文件为 ArrayBuffer
    reader.readAsArrayBuffer(file)
  })
}

/**
 * 设置用户信息-同步头像
 */
export const handleSetUserInfo = debounce(async () => {
  try {
    const { user, setUser } = useUserStore.getState()
    const res = await fetchGetUserInfo()
    if (res.code === RESPONSE_CODE.success) {
      setUser({ ...user, ...res.data } as User)
    }
  } catch (error) {
    console.log('error>---->>', error)
  }
}, 500)
