import { IContent } from '@brisk/api'
import { concat, findIndex, forEach, set as lodashSet } from 'lodash'
import { create } from 'zustand'

export type MessageData = {
  conversationId?: string
  index?: number
  isFinished?: boolean
  messages?: IContent[]
}

type State = {
  secretKey: string // 当前会话加解密的key
  isUpdate: boolean // 是否正在更新数据
  messageData: MessageData[]
}

type Action = {
  setMessageData: (messageData: MessageData) => void
  setMessageDataList: (
    conversationId: string,
    messageData: MessageData[],
    callback?: () => void
  ) => void // 设置指定列表（加载更多...）
  setMessageDataListInfo: (msg: IContent) => void // 设置指定列表信息

  setTransLateResMsgData: (msg: IContent[], conv: string) => void // 设置翻译结果

  setIsUpdate: (isUpdate: boolean) => void
  setSecretKey: (key: string) => void
}

export const useSingleChatStore = create<State & Action>((set, get) => ({
  messageData: [{ conversationId: '', index: 0, isFinished: false, messages: [] }],
  isUpdate: false,
  secretKey: '',
  setMessageData: (data: MessageData): void => {
    const { messageData } = get()
    const i = findIndex(messageData, (it) => it.conversationId === data.conversationId)
    if (i >= 0) {
      set({ messageData: lodashSet(messageData, i, data) })
    } else {
      set({ messageData: concat(messageData, [data]) })
    }
  },
  setMessageDataList: (
    conversationId: string,
    data: MessageData[],
    callback?: () => void
  ): void => {
    const { messageData } = get()
    const i = findIndex(messageData, (it) => it.conversationId === conversationId)
    if (i >= 0) {
      const { messages, ...rest } = messageData[i]
      set({ messageData: lodashSet(messageData, i, { ...rest, messages: concat(data, messages) }) })
    }
    callback && callback()
  },
  setMessageDataListInfo: (msg: IContent) => {
    const { messageData } = get()
    const i = findIndex(messageData, (it) => it.conversationId === msg.conversationId)
    if (i >= 0) {
      const { messages, ...rest } = messageData[i]
      const j = findIndex(messages, (it) => {
        const id = it.timMsgId ? it.timMsgId : it.tid
        return id === msg.tid
      })
      const newLsit = lodashSet(messages, j, msg)
      set({ messageData: lodashSet(messageData, i, { ...rest, messages: newLsit }) })
    }
  },

  setTransLateResMsgData: (msg: IContent[], conv: string) => {
    const { messageData } = get()
    const i = findIndex(messageData, (it) => it.conversationId === conv)
    if (i < 0) return
    const conversation = messageData[i]
    if (conversation) {
      // 遍历 msg，找到需要替换的消息并直接替换
      forEach(msg, (newMessage) => {
        const index = findIndex(conversation.messages, { tid: newMessage.tid })
        if (index !== -1) {
          conversation.messages[index] = newMessage
        }
      })

      // 更新状态
      set({ messageData: lodashSet(messageData, i, conversation) })
    }
  },

  setIsUpdate: (isUpdate: boolean) => {
    set({ isUpdate })
  },
  setSecretKey: (key: string) => {
    set({ secretKey: key })
  }
}))
