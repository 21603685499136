import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import { ReactSVG } from 'react-svg'
import wordsTips from '@renderer/assets/svg/wordsTips.svg'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CustomerPageResultVO, ICustomerReq, ICustomerResp, fetchCustomerList } from '@renderer/api'
import { fetchTagList } from '@renderer/api/manage/fetchTagList'
import { Controller, useForm } from 'react-hook-form'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  cn
} from '@brisk/ui'
import { map } from 'lodash'
import search from '@renderer/assets/svg/search.svg'
import searchLight from '@renderer/assets/svg/searchLight.svg'
import resetSvg from '@renderer/assets/svg/reset.svg'
import noCustomer from '@renderer/assets/svg/noCustomer.svg'
import { CHANNAL_SOURCE, inputIconClassName } from '@renderer/utils/consts'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import UserDetailSheet from '../Components/UserDetailSheet'
import { Plus, User } from 'lucide-react'
import { ColumnDef } from '@tanstack/react-table'
import { IPageationRef } from '../AddCustomer'
import { ManagerTable } from '@renderer/components/ManagerTable'
import {
  CustomerDetailResponse,
  fetchAddTag,
  fetchCustomerDetail,
  fetchDelTag,
  fetchEditTag,
  fetchTagInfo,
  ITagPageVO
} from '@brisk/api'
import group01 from '@renderer/assets/svg/group01.svg'
import groupEdit02 from '@renderer/assets/svg/groupEdit02.svg'
import allTagIcon from '@renderer/assets/svg/allTagIcon.svg'
import manageChannelIcon from '@renderer/assets/svg/manageChannelIcon.svg'
import manageGrouoIcon from '@renderer/assets/svg/manageGrouoIcon.svg'
import AddTag from '../Components/AddTag'
import DelCustolerModal from '../Components/DelCustolerModal'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'
import TooltipComp from '@renderer/pages/home/Chatting/components/TooltipComp'
import { SESSION_TYPE } from '@brisk/utils'

export const SourceType = {
  0: 'webSite',
  1: 'Telegram',
  2: 'messenger'
}

export type ChannelData = {
  [key: string]: {
    name: string
    src: string
  }
}

type ModalData = {
  open: boolean
  data: ITagPageVO | null
}

const channelData: ChannelData = {}

const index: FC = () => {
  const [open, setOpen] = useState(false)
  const [listData, setListData] = useState<ICustomerResp>({})
  const [detailData, setDetailData] = useState<CustomerDetailResponse>({})
  const [edit, setEdit] = useState<string | number>('')
  const subBtnRef = useRef<HTMLButtonElement>(null)
  const editRef = useRef<HTMLDivElement>(null)

  const [modalData, setModalData] = useState<ModalData>({
    open: false,
    data: null
  })
  const [delData, setDelData] = useState<ModalData>({
    open: false,
    data: null
  })

  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })
  const { handleSubmit, control, reset, getValues, watch, setValue } = useForm<ICustomerReq>({
    defaultValues: {
      keyWord: '',
      source: -1,
      tagId: -1
    }
  })

  const activeTagId = watch('tagId')
  const { t } = useTranslation()

  const { mutateAsync: getCustomerList, isPending: pageLoading } = useMutation({
    mutationFn: fetchCustomerList
  })
  const { mutateAsync: getCustomerDetail, isPending } = useMutation({
    mutationFn: fetchCustomerDetail
  })

  const {
    data: tagList,
    refetch,
    isPending: tagLoading
  } = useQuery({
    queryKey: [fetchTagList.name],
    queryFn: async () => {
      const res = await fetchTagList()
      return res?.data
    }
  })

  const { mutateAsync: addTag, isPending: addTagLoading } = useMutation({
    mutationFn: fetchAddTag
  })

  const { mutateAsync: editTag, isPending: editTagLoading } = useMutation({
    mutationFn: fetchEditTag
  })

  const { mutateAsync: delTag, isPending: delTagLoading } = useMutation({
    mutationFn: fetchDelTag
  })

  const { mutateAsync: getTagInfo, isPending: tagInfoLoading } = useMutation({
    mutationFn: fetchTagInfo
  })

  const handleGetCustomerList = async (params?: ICustomerReq): Promise<void> => {
    try {
      const res = await getCustomerList(params)
      if (res?.code === RESPONSE_CODE.success) setListData(res?.data)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleFormatParams = (data: ICustomerReq): ICustomerReq => {
    const { keyWord, source, tagId } = data
    return {
      keyWord,
      source: +source === -1 ? null : source,
      tagId: +tagId === -1 ? null : tagId
    }
  }
  const onSubmit = async (formData): Promise<void> => {
    console.log('formData', formData)
    const param = {
      pageNum: 1,
      pageSize: pageationRef?.current?.itemsPerPage || 10,
      ...handleFormatParams(formData)
    }
    handleGetCustomerList(param)
  }

  const columns: ColumnDef<CustomerPageResultVO>[] = useMemo(
    () => [
      {
        id: 'name',
        header: (): JSX.Element => <div>{t('clientManagement.CustomerNickname')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div key={original?.avatar + original?.name} className="flex items-center gap-1 h-full">
              <Avatar className="hidden h-8 w-8 sm:flex">
                <AvatarImage
                  alt="Avatar"
                  src={import.meta.env.VITE_IMAGE_URL + original?.avatar}
                  className="w-full h-full rounded-full"
                />
                <AvatarFallback>
                  <User />
                </AvatarFallback>
              </Avatar>
              <span>{original?.name}</span>
              {original?.sessionType === SESSION_TYPE.TG_GROUP && (
                <TooltipComp
                  content={t('chatFrame.Telegram 群组')}
                  style={{
                    zIndex: 10
                  }}
                >
                  <div>
                    <ReactSVG src={manageGrouoIcon} className="ml-1" />
                  </div>
                </TooltipComp>
              )}
              {original?.sessionType === SESSION_TYPE.TG_CHANNEL && (
                <TooltipComp content={t('chatFrame.Telegram 频道')} className="z-2">
                  <div>
                    <ReactSVG src={manageChannelIcon} className="ml-1" />
                  </div>
                </TooltipComp>
              )}
            </div>
          )
        }
      },
      {
        id: 'source',
        header: (): JSX.Element => <div>{t('clientManagement.Channel')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div className="flex items-center gap-1 h-full">
              <span>{SourceType[original?.source]}</span>
            </div>
          )
        }
      },
      {
        id: 'email',
        header: (): JSX.Element => <div>{t('common.mailbox')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div className="flex items-center gap-1 h-full">{original?.email}</div>
        }
      },
      {
        id: 'phone',
        header: (): JSX.Element => <div>{t('common.telephone')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div className="flex items-center gap-1 h-full">{original?.phone}</div>
        }
      },
      {
        id: 'address',
        header: (): JSX.Element => <div>{t('common.address')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div className="flex items-center gap-1 h-full">{original?.address}</div>
        }
      },
      {
        id: 'company',
        header: (): JSX.Element => <div>{t('common.corporation')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div className="flex items-center gap-1 h-full">{original?.company}</div>
        }
      },
      {
        id: 'customerTag',
        header: (): JSX.Element => <div>{t('clientManagement.Tags')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div className="flex items-center flex-wrap gap-1 h-full max-h-[200px] overflow-y-auto">
              {map(original?.customerTag, (it, i) => (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <div
                        key={i + it?.tagId + it?.colorCode}
                        className={cn(
                          'flex items-center gap-1 pt-0.5 pb-0.5 pl-1 pr-2 rounded text-xs bg-lingheInpBg text-lingheTextGray'
                        )}
                        style={{
                          borderRadius: 3,
                          overflowWrap: 'anywhere'
                        }}
                      >
                        <span
                          className="w-2.5 h-2.5 border"
                          style={{
                            borderRadius: 2,
                            backgroundColor: `${it?.colorCode}`,
                            borderColor: 'rgba(0,0,0,0.06)'
                          }}
                        />
                        <span
                          className={cn(
                            'flex-1 shrink-0 max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap '
                          )}
                        >
                          {it?.tagName}
                        </span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent className="max-w-[300px]">{it?.tagName}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ))}
            </div>
          )
        }
      },
      {
        id: 'remark',
        header: (): JSX.Element => <div>{t('clientManagement.Remarks')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div
              className="flex items-center gap-1 h-full"
              style={{
                maxWidth: 700,
                whiteSpace: 'pre-wrap',
                overflowWrap: 'anywhere'
              }}
            >
              {original?.remark}
            </div>
          )
        }
      },
      {
        id: 'edit',
        header: (): JSX.Element => <div>{t('common.Operation')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <Button
              variant="link"
              className="hover:no-underline p-0"
              onClick={async () => {
                try {
                  const res = await getCustomerDetail(original?.customerId)
                  if (res?.code === RESPONSE_CODE.success) {
                    setDetailData(res?.data)
                    setOpen(true)
                  }
                } catch (error) {
                  console.log('error', error)
                }
              }}
            >
              {t('clientManagement.Details')}
            </Button>
          )
        },
        fixed: 'right'
      }
    ],
    []
  )

  useEffect(() => {
    handleGetCustomerList({
      pageSize: pageationRef?.current?.itemsPerPage || 10,
      pageNum: 1
    })
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editRef.current && !editRef.current.contains(event.target as Node)) {
        setEdit(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [edit])

  return (
    <NavBodyLayout
      title={[t('clientManagement.clientManagementTitle')]}
      loading={[
        isPending,
        pageLoading,
        tagLoading,
        addTagLoading,
        editTagLoading,
        delTagLoading,
        tagInfoLoading
      ]}
    >
      <div className="flex flex-col w-full h-full overflow-hidden">
        <div className="h-[54px] shrink-0 border-b">
          <div className="flex items-center gap-3 pl-3 h-full font-medium text-base">
            {t('clientManagement.ClientList')}
            <div className="flex items-center gap-2">
              <ReactSVG src={wordsTips} />
              <span className="text-sm text-lingheTextTips font-normal">
                {t('clientManagement.ClientListips')}
              </span>
            </div>
          </div>
        </div>

        <div className="flex-1 shrink-0 flex w-full overflow-hidden">
          <div className="flex flex-col w-[252px] border-r shrink-0">
            <div className="w-full flex items-center px-8 shrink-0 rounded-md h-8 text-sm flex items-center justify-between mt-4 mb-2">
              <span className="font-medium">{t('editInformation.tag')}</span>
              <span
                className="flex items-center gap-1 text-lingheTextGray hover:text-primary cursor-pointer"
                onClick={() => setModalData({ data: null, open: true })}
              >
                <Plus size={14} />
                {t('knowledgeBase.Add')}
              </span>
            </div>

            <div className="flex-1 overflow-hidden shrink-0">
              <div className="w-full h-full overflow-y-auto px-4">
                <div
                  onClick={() => {
                    if (activeTagId === -1) return
                    setValue('tagId', -1)
                    subBtnRef?.current?.click()
                  }}
                  className={cn(
                    'w-full relative min-h-12 rounded-md mb-[1px] flex items-center justify-between px-4 cursor-pointer hover:bg-lingheBtnHover',
                    activeTagId === -1 && 'bg-lingheBtnHover'
                  )}
                >
                  <div className="flex flex-1 shrink-0 items-center gap-2">
                    <ReactSVG src={allTagIcon} />
                    <span className="flex-1 shrink-0 max-w-[132px] truncate">
                      {t('clientManagement.AllTags')}
                    </span>
                  </div>
                </div>
                {map(tagList, (item) => (
                  <div
                    className={cn(
                      'w-full relative min-h-12 mb-[1px] cursor-pointer rounded-md',
                      activeTagId === item?.tagId && 'bg-lingheBtnHover'
                    )}
                    key={item?.tagId}
                    onClick={() => {
                      if (activeTagId === item?.tagId) return
                      setValue('tagId', item?.tagId)
                      subBtnRef?.current?.click()
                    }}
                  >
                    <div className="hover:bg-lingheBtnHover w-full flex rounded-md min-h-12 items-center justify-between px-4">
                      <div className="flex flex-1 shrink-0 items-center gap-2">
                        <span
                          className="w-3 h-3 rounded-sm shrink-0 border border-[rgba(0,0,0,0.06)] box-border"
                          style={{
                            backgroundColor: item?.colorCode
                          }}
                        />
                        <span className="flex-1 shrink-0 max-w-[132px] truncate">
                          {item?.tagName}
                        </span>
                      </div>
                      <div className="group shrink-0">
                        <ReactSVG
                          src={group01}
                          className="shrink-0 cursor-pointer group-hover:hidden"
                        />

                        <ReactSVG
                          src={groupEdit02}
                          className="shrink-0 cursor-pointer hidden group-hover:block"
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setEdit((pre) => {
                              if (pre === item?.tagId) return ''
                              return item?.tagId
                            })
                          }}
                        />
                      </div>
                    </div>
                    {edit === item?.tagId && (
                      <div
                        ref={editRef}
                        className="z-10 absolute min-w-[68px] p-2 rounded-md bg-white shadow-md text-sm right-1 top-9 [&_span]:flex [&_span]:items-center [&_span]:cursor-pointer
              [&_span]:justify-center [&_span]:block [&_span]:px-1 [&_span]:w-full [&_span]:h-8"
                      >
                        <span
                          className="hover:bg-lingheBtnHover"
                          onClick={async (e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            try {
                              setEdit('')
                              const res = await getTagInfo(item?.tagId)
                              if (res?.code === RESPONSE_CODE.success) {
                                setModalData({ data: res?.data as ITagPageVO, open: true })
                              }
                            } catch (error) {
                              console.log('error', error)
                            }
                          }}
                        >
                          {t('login.Edit')}
                        </span>
                        <span
                          className="hover:bg-lingheBtnHover text-lingheError"
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setEdit('')
                            setDelData({ data: item as ITagPageVO, open: true })
                          }}
                        >
                          {t('chatFrame.removing')}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex-1 shrink-0 h-full flex flex-col overflow-hidden">
            <div className="h-16 shrink-0 w-full">
              <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
                <div className="flex items-center h-full justify-between pl-3 pr-3">
                  <div className="flex items-center gap-3">
                    <Controller
                      name="keyWord"
                      control={control}
                      render={({ field }) => (
                        <>
                          <div className="relative">
                            <Input
                              placeholder={t('clientManagement.Searchforcustomername')}
                              {...field}
                              className="w-[200px] h-8 pl-6"
                              type="text"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault() // 阻止 Enter 键默认行为（防止表单刷新）
                                  handleSubmit(onSubmit)() // 触发表单提交
                                }
                              }}
                            />
                            <ReactSVG src={search} className={cn(inputIconClassName)} />
                          </div>
                        </>
                      )}
                    />
                    <Controller
                      name="source"
                      control={control}
                      render={({ field }) => (
                        <Select
                          value={String(field.value)}
                          onValueChange={(e) => field.onChange(Number(e))}
                        >
                          <SelectTrigger className="w-[200px] h-8">
                            <SelectValue
                              defaultValue={field.value + ''}
                              placeholder={`${Number(field.value) === -1 ? t('clientManagement.Allchannels') : field.value}`}
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem key="allChannelType" value={'-1'}>
                                {t('clientManagement.Allchannels')}
                              </SelectItem>
                              {map(CHANNAL_SOURCE, (item) => (
                                <SelectItem
                                  key={item.label + item?.value}
                                  value={String(item.value)}
                                >
                                  {item.label}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                  <div className="flex items-center gap-3 [&_button]:flex [&_button]:items-center [&_button]:h-8 [&_button]:gap-1">
                    <Button variant="outline" onClick={() => reset()}>
                      <ReactSVG src={resetSvg} />
                      {t('clientManagement.Reset')}
                    </Button>
                    <Button ref={subBtnRef} type="submit">
                      <ReactSVG src={searchLight} />
                      {t('clientManagement.Search')}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <div className="flex-1 h-full shrink-0 overflow-hidden">
              <ManagerTable<CustomerPageResultVO>
                pageationRef={pageationRef}
                data={listData?.records || []}
                columns={columns}
                rowCount={listData?.total || 0}
                onPageChange={(pageNum, pageSize) => {
                  handleGetCustomerList({
                    ...handleFormatParams(getValues()),
                    pageNum,
                    pageSize
                  })
                }}
                emptyElement={() => (
                  <div className="flex items-center justify-center pt-20">
                    <div className="flex flex-col items-center justify-center gap-2">
                      <ReactSVG src={noCustomer} />
                      <span className="mb-2 text-sm text-lingheTextGray">
                        {t('businessManagement.Therearecurrentlycustomers')}
                      </span>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <UserDetailSheet
          tagList={tagList}
          channelData={channelData}
          data={detailData}
          open={open}
          onClose={() => {
            setDetailData({})
            setOpen(false)
          }}
          updateList={() => {
            handleGetCustomerList({
              ...handleFormatParams(getValues()),
              pageNum: pageationRef?.current?.currentPage || 1,
              pageSize: pageationRef?.current?.itemsPerPage || 10
            })
          }}
        />

        <AddTag
          data={modalData.data}
          open={modalData.open}
          onClose={() => setModalData({ data: null, open: false })}
          onConfirm={async (param, callBack): Promise<void> => {
            try {
              const res = await addTag(param)
              if (res?.code === RESPONSE_CODE.success) {
                message(t('clientManagement.AddTagSuccess'), 'success')
                setModalData({ data: null, open: false })
                refetch()
                callBack && callBack()
              } else {
                message(res?.msg)
              }
            } catch (error) {
              console.log('error', error)
            }
          }}
          onEditConfirm={async (param): Promise<void> => {
            try {
              const res = await editTag(param)
              if (res?.code === RESPONSE_CODE.success) {
                message(t('clientManagement.EditTagsSucceed'), 'success')
                setModalData({ data: null, open: false })
                refetch()
              }
            } catch (error) {
              console.log('error', error)
            }
          }}
        />

        <DelCustolerModal
          title={t('clientManagement.DeleteTags')}
          open={delData.open}
          onClose={() => {
            setDelData({
              open: false,
              data: null
            })
          }}
          onConfirm={async () => {
            const res = await delTag(delData?.data?.tagId || '')
            if (res?.code === RESPONSE_CODE.success)
              message(t('businessManagement.Deletedsuccessfully'), 'success')
            refetch()
            setDelData({
              open: false,
              data: null
            })
          }}
          content={
            <div className="text-lingheTextGray text-sm text-center whitespace-pre-line">
              {t('clientManagement.Deletingdisappearcustomerinformation')}
            </div>
          }
        />
      </div>
    </NavBodyLayout>
  )
}

export default memo(index)
