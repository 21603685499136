import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import { ReactSVG } from 'react-svg'
import wordsTips from '../../../assets/svg/wordsTips.svg'
import reset from '../../../assets/svg/reset.svg'
import { Button, cn } from '@brisk/ui'
import { TipsIcon } from '../ReceptionWelcomeWords'
import ReactECharts from 'echarts-for-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { find, map, orderBy } from 'lodash'
import { ManagerTable } from '@renderer/components/ManagerTable'
import { ColumnDef } from '@tanstack/react-table'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import manage_data01 from '@renderer/assets/imgs/manage_data01.png'
import manage_data02 from '@renderer/assets/imgs/manage_data02.png'
import manage_data03 from '@renderer/assets/imgs/manage_data03.png'
import { IPageationRef } from '../AddCustomer'
import {
  fetchChatData,
  fetchCustomerStatus,
  fetchDataSyncPage,
  fetchVisitedes,
  fetchVisiteLinesData,
  IDataSyncPageRecords,
  IDataSyncPageRequest,
  IDataSyncPageResponse,
  IVisitLineItem,
  RESPONSE_CODE
} from '@brisk/api'
import { statusColor } from '@renderer/pages/home/AccountMenu/AvatarPopover'
import { EmployeeStatus } from '@renderer/api/model'
import { CustomerStatus, formatNumberWithK } from '@renderer/utils'
import CustomerSelectComp from '../Components/CustomerSelectComp'
import { useTranslation } from 'react-i18next'

const formateLineData = (data: IVisitLineItem[]): IVisitLineItem[] =>
  orderBy(data || [], (item) => parseInt(item.keyStr.split(':')[0], 10), ['asc'])

/** echats option 通用部分*/
export const pubblicOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#247CFF'
      }
    },
    backgroundColor: 'rgba(255,255,255,.8)',
    position: (pt, params, dom, rect, size): [number, number] => {
      const x = pt[0]
      const width = size.viewSize[0]
      const tooltipWidth = 200
      if (x + tooltipWidth > width * 0.8) {
        return [x - tooltipWidth, pt[1]]
      } else {
        return [x, pt[1]]
      }
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value}'
    }
  }
}

interface IOptionParam {
  receptionHourNum?: IVisitLineItem[] // 接待访客数
  newCustomerHourNum?: IVisitLineItem[] // 新访客数
  consultingHourNum?: IVisitLineItem[] // 咨询数
  waitConsultingHourNum?: IVisitLineItem[] // 排队数
}

const index: FC = () => {
  const { t } = useTranslation()
  const echartsOption = (param: IOptionParam): { [key: string]: unknown } => {
    const receptionHour = formateLineData(param?.receptionHourNum || [])
    const newCustomerHour = formateLineData(param?.newCustomerHourNum || [])
    const consultingHour = formateLineData(param?.consultingHourNum || [])
    const waitConsultingHour = formateLineData(param?.waitConsultingHourNum || [])
    return {
      ...pubblicOption,
      legend: {
        data: [
          {
            icon: `path://M1 4C1 4 2.5 6.5 4.5 7C7 7 8 4 8 4C8 4 9 1 11.5 1C14 1 15 4 15 4`,
            name: t('statistics.Newvisitors')
          },
          {
            icon: 'path://M1 4C1 4 2 7 4.5 7C7 7 8 4 8 4C8 4 9 1 11.5 1C14 1 15 4 15 4',
            name: t('statistics.Numberofinquiries')
          },
          {
            icon: 'path://M1 4C1 4 2 7 4.5 7C7 7 8 4 8 4C8 4 9 1 11.5 1C14 1 15 4 15 4',
            name: t('statistics.QueuedSessions')
          },
          {
            icon: 'path://M1 4C1 4 2 7 4.5 7C7 7 8 4 8 4C8 4 9 1 11.5 1C14 1 15 4 15 4',
            name: t('statistics.SeatDetails')
          }
        ],
        bottom: '0px'
      },
      xAxis: {
        type: 'category',
        data: map(receptionHour, (item) => item?.keyStr)
      },
      series: [
        {
          name: t('statistics.Newvisitors'),
          type: 'line',
          smooth: true,
          data: map(receptionHour, (item) => item?.num),
          areaStyle: {
            opacity: 0.2
          },
          lineStyle: {
            width: 3
          }
        },
        {
          name: t('statistics.Numberofinquiries'),
          type: 'line',
          smooth: true,
          data: map(newCustomerHour, (item) => item?.num),
          areaStyle: { opacity: 0.2 },
          lineStyle: {
            width: 3
          }
        },
        {
          name: t('statistics.QueuedSessions'),
          type: 'line',
          smooth: true,
          data: map(consultingHour, (item) => item?.num),
          areaStyle: { opacity: 0.2 },
          lineStyle: {
            width: 3
          }
        },
        {
          name: t('statistics.SeatDetails'),
          type: 'line',
          smooth: true,
          data: map(waitConsultingHour, (item) => item?.num),
          areaStyle: { opacity: 0.2 },
          lineStyle: {
            width: 3
          }
        }
      ]
    }
  }
  const [listData, setListData] = useState<IDataSyncPageResponse>()
  const [option, setOption] = useState(echartsOption({}))

  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  const { mutateAsync: getList, isPending } = useMutation({
    mutationFn: fetchDataSyncPage
  })

  const {
    data: customerStatus,
    refetch: customerStatusRefetch,
    isPending: customerStatusIsPending
  } = useQuery({
    queryKey: [fetchCustomerStatus.name],
    queryFn: async () => {
      const res = await fetchCustomerStatus()
      return res?.data
    }
  })

  const {
    data: visitedes,
    refetch: visitedesRefetch,
    isPending: visitedesIsPending
  } = useQuery({
    queryKey: [fetchVisitedes.name],
    queryFn: async () => {
      const res = await fetchVisitedes()
      return res?.data
    }
  })

  const {
    data: chatData,
    refetch: chatDataRefetch,
    isPending: chatDataIsPending
  } = useQuery({
    queryKey: [fetchChatData.name],
    queryFn: async () => {
      const res = await fetchChatData()
      return res?.data
    }
  })

  const {
    data: visiteLinesData,
    refetch: visiteLinesDataRefetch,
    isPending: visiteLinesDataIsPending
  } = useQuery({
    queryKey: [fetchVisiteLinesData.name],
    queryFn: async () => {
      const res = await fetchVisiteLinesData()
      return res?.data
    }
  })

  const columns: ColumnDef<IDataSyncPageRecords>[] = useMemo(
    () => [
      {
        id: 'nickname',
        header: () => <div>{t('statistics.ReceptionSessions')}</div>,
        cell: ({ row: { original } }) => (
          <div className="flex items-center gap-1">
            <span
              className={cn(
                'w-2 h-2 border-white border rounded-full',
                statusColor[EmployeeStatus[original?.status || '']] || ''
              )}
            />
            <img className="h-6 w-6 rounded-full" src={original?.avatarUrl} alt="" />
            <span>{original?.nickname}</span>
          </div>
        )
      },
      {
        id: 'receptionNum',
        header: () => <div>{t('statistics.Newvisitors')}</div>,
        cell: ({ row: { original } }) => <div>{original?.receptionNum || 0}</div>
      },
      {
        id: 'sessionNum',
        header: () => <div>{t('statistics.Receptionsession')}</div>,
        cell: ({ row: { original } }) => <div>{original?.sessionNum}</div>
      }
    ],
    []
  )

  const handleList = async (param: IDataSyncPageRequest): Promise<void> => {
    try {
      const res = await getList(param)
      if (res?.code === RESPONSE_CODE.success) {
        setListData(res?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const refetchAll = useCallback(() => {
    customerStatusRefetch()
    visitedesRefetch()
    chatDataRefetch()
    visiteLinesDataRefetch()
    handleList({
      pageNum: pageationRef?.current?.currentPage || 1,
      pageSize: pageationRef?.current?.itemsPerPage || 10
    })
  }, [pageationRef?.current])

  useEffect(() => {
    handleList({
      pageNum: pageationRef?.current?.currentPage || 1,
      pageSize: pageationRef?.current?.itemsPerPage || 10
    })
  }, [])

  useEffect(() => {
    if (visiteLinesData) setOption(echartsOption(visiteLinesData))
  }, [visiteLinesData])

  return (
    <NavBodyLayout
      title={[t('statistics.statisticsTitle'), t('statistics.Realtimedata')]}
      loading={[
        isPending,
        customerStatusIsPending,
        visitedesIsPending,
        chatDataIsPending,
        visiteLinesDataIsPending
      ]}
    >
      <div className="w-full h-full min-w-[896px] flex flex-col relative overflow-x-hidden overflow-y-auto">
        <div className="flex w-full shrink-0 items-center justify-between p-3 h-[54px] border-b">
          <div className="h-full flex items-center gap-3">
            <span className="text-base font-medium">{t('statistics.Realtimedata')}</span>
            <div className="flex h-full items-center gap-1.5">
              <ReactSVG src={wordsTips} />
              <span className="text-sm text-lingheTextTips">
                {t('statistics.Realtimedatabeenreceivedtoday')}
              </span>
            </div>
          </div>
          <Button variant="outline" onClick={refetchAll} className="w-[90px] h-8">
            <ReactSVG src={reset} className="mr-1" />
            {t('common.refresh')}
          </Button>
        </div>

        <div
          className="flex shrink-0 items-center gap-2 p-3 [&_svg]:z-0 [&_.contentWrap]:z-10 [&_.contentWrap]:flex [&_.contentWrap]:flex-col [&_.contentWrap]:gap-3
          [&_.contentWrap]:pl-4 [&_.contentWrap]:pr-4 [&_.contentWrap]:justify-center [&_.contentWrap]:w-full [&_.contentWrap]:h-full
        [&_.itemWrap]:flex-1 [&_.itemWrap]:min-w-[235px] [&_.itemWrap]:h-[106px] [&_.itemWrap]:relative [&_.itemWrap]:overflow-hidden [&_.itemWrap]:rounded-md shrink-0"
        >
          <div className="itemWrap bg-gradient-to-r from-[#F0F9FF] via-[#D1EDF9] to-[#F0F9FF] rounded-tr-lg rounded-br-lg">
            <div className="contentWrap">
              <div className="flex items-center gap-1">
                <span>{t('statistics.Seats')}</span>
                {/* <TipsIcon content="xxxxxx" side="top" /> */}
              </div>
              <div className="flex items-center justify-between pr-20 [&_.item>span]:z-10 [&_.item>span]:text-center [&_.item]:flex [&_.item]:flex-col [&_.item]:justify-center [&_.item]:gap-0.5 [&>.item>span:first-child]:text-base [&>.item>span:first-child]:font-semibold">
                <div className="item">
                  <span>
                    {
                      find(
                        customerStatus,
                        (item) => item?.statisticKey === CustomerStatus[t('statistics.OnlineSeats')]
                      )?.num
                    }
                  </span>
                  <span>{t('statistics.Online')}</span>
                </div>
                <div className="item">
                  <span>
                    {
                      find(
                        customerStatus,
                        (item) => item?.statisticKey === CustomerStatus[t('statistics.BusySeats')]
                      )?.num
                    }
                  </span>
                  <span>{t('statistics.Busy')}</span>
                </div>
                <div className="item">
                  <span>
                    {
                      find(
                        customerStatus,
                        (item) =>
                          item?.statisticKey === CustomerStatus[t('statistics.Offlineseats')]
                      )?.num
                    }
                  </span>
                  <span>{t('statistics.Offline')}</span>
                </div>
              </div>
            </div>
            <img
              src={manage_data01}
              alt=""
              className="z-0 absolute right-0 bottom-0 w-[50%] h-[100%]"
            />
          </div>
          <div className="itemWrap bg-gradient-to-r from-[#F1EFFD] via-[#DAD9FB] to-[#F1EFFD] rounded-tr-lg rounded-br-lg">
            <div className="contentWrap">
              <div className="flex items-center gap-1">
                <span>{t('statistics.Visitors')}</span>
                {/* <TipsIcon content="xxxxxx" side="top" /> */}
              </div>
              <div className="flex items-center justify-between pr-20 [&_.item>span]:z-10 [&_.item>span]:text-center [&_.item]:flex [&_.item]:flex-col [&_.item]:justify-center [&_.item]:gap-0.5 [&>.item>span:first-child]:text-base [&>.item>span:first-child]:font-semibold">
                <div className="item">
                  <span>{formatNumberWithK(+(visitedes?.countNum || 0))}</span>
                  <span>{t('statistics.TotalVisitors')}</span>
                </div>
                <div className="item">
                  <span>{formatNumberWithK(+(visitedes?.countNewNum || 0))}</span>
                  <span>{t('statistics.NewVisitors')}</span>
                </div>
                <div className="item">
                  <span>{formatNumberWithK(+(visitedes?.countOldNum || 0))}</span>
                  <span>{t('statistics.NumberofFrequentVisitors')}</span>
                </div>
              </div>
            </div>
            <img
              src={manage_data02}
              alt=""
              className="z-0 absolute right-0 bottom-0 w-[50%] h-[100%]"
            />
          </div>
          <div className="itemWrap bg-gradient-to-r from-[#EAEFFB] via-[#D0DEF9] to-[#EAEFFB] rounded-tr-lg rounded-br-lg">
            <div className="contentWrap">
              <div className="flex items-center gap-1">
                <span>{t('statistics.Sessions')}</span>
                {/* <TipsIcon content="xxxxxx" side="top" /> */}
              </div>
              <div className="flex items-center justify-between pr-20 [&_.item>span]:z-10 [&_.item>span]:text-center [&_.item]:flex [&_.item]:flex-col [&_.item]:justify-center [&_.item]:gap-0.5 [&>.item>span:first-child]:text-base [&>.item>span:first-child]:font-semibold">
                <div className="item">
                  <span>
                    {formatNumberWithK(
                      +(
                        find(
                          chatData,
                          (item) =>
                            item?.statisticKey === CustomerStatus[t('statistics.Inprogress')]
                        )?.num || 0
                      )
                    )}
                  </span>
                  <span>{t('statistics.Inprogress')}</span>
                </div>
                <div className="item">
                  <span>
                    {formatNumberWithK(
                      +(
                        find(
                          chatData,
                          (item) => item?.statisticKey === CustomerStatus[t('statistics.Inqueue')]
                        )?.num || 0
                      )
                    )}
                  </span>
                  <span>{t('statistics.Inqueue')}</span>
                </div>
                <div className="item">
                  <span>
                    {formatNumberWithK(
                      +(
                        find(
                          chatData,
                          (item) => item?.statisticKey === CustomerStatus[t('statistics.Closed')]
                        )?.num || 0
                      )
                    )}
                  </span>
                  <span>{t('statistics.Closed')}</span>
                </div>
              </div>
            </div>
            <img
              src={manage_data03}
              alt=""
              className="z-0 absolute right-0 bottom-0 w-[50%] h-[100%]"
            />
          </div>
        </div>

        <div className="h-80 shrink-0 w-full">
          <ReactECharts option={option} className="w-[117%] h-full -translate-x-[7%]" lazyUpdate />
        </div>

        <div className="flex shrink-0 items-center p-3 h-[54px] border-b">
          <span className="text-base font-semibold">{t('statistics.Allseats')}</span>
        </div>

        <div className="h-16 shrink-0 pl-3 pr-3 flex items-center">
          <CustomerSelectComp
            onValueChange={(val) => {
              const userParams = val === '-1' ? {} : { userId: val }
              handleList({ pageNum: 1, pageSize: 10, ...userParams })
            }}
          />
        </div>

        <div className="shrink-0 h-[528px] overflow-hidden">
          <ManagerTable<IDataSyncPageRecords>
            pageationRef={pageationRef}
            data={listData?.records || []}
            rowCount={listData?.total || 0}
            columns={columns}
            onPageChange={(pageNum, pageSize) => {
              handleList({
                pageNum,
                pageSize
              })
            }}
            emptyElement={() => (
              <div className="flex items-center justify-center pt-20">
                <div className="flex flex-col items-center justify-center gap-2">
                  <ReactSVG src={noSearchResult} />
                  <span className="mb-2 text-sm text-lingheTextGray">
                    {t('common.Nodataavailable')}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </NavBodyLayout>
  )
}

export default memo(index)
