import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  cn
} from '@brisk/ui'
import { FC, memo, useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { fetchGetShortLink, IScriptDetail, RESPONSE_CODE } from '@brisk/api'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { message } from '@renderer/components/message'

interface ICreateScriptToWebProps {
  data: IScriptDetail | null
  open: boolean
  onClose: () => void
  onConfirm: (script: string) => void
}
const CreateScriptToWeb: FC<ICreateScriptToWebProps> = ({ data, open, onClose, onConfirm }) => {
  const [script, setScript] = useState(``)
  const [active, setActive] = useState(1)
  const [shortLink, setShortLink] = useState('')

  const { t } = useTranslation()

  const { mutateAsync: getShortLink } = useMutation({
    mutationFn: fetchGetShortLink
  })

  useEffect(() => {
    if (data) {
      const targetScript = `<script id="AIOChat-script" type="module" crossorigin
      src="${import.meta.env.VITE_PREVIEW_URL}assets/sdk.js?token=${data?.token}"></script>`
      setScript(targetScript)
    }
  }, [data])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogContent className="p-0 text-sm focus:outline-none">
        <div className="relative">
          <ReactSVG
            src={close}
            className="cursor-pointer absolute right-4 top-4 "
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          />

          <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
            {t('socialMediaAccount.Install')}
          </AlertDialogTitle>
          <AlertDialogDescription />
        </div>

        <div className="flex flex-col gap-3 p-6 pt-0">
          <div
            className="w-full p-1 mb-2 h-[48px] rounded-md border relative flex [&>div]:cursor-pointer [&>div]:relative [&>div]:flex [&>div]:items-center
            [&>div]:rounded-md [&>div]:justify-center [&>div]:h-full [&>div]:flex-1 [&>div]:shrink-0
          [&>.active]:bg-lingheBtnHover [&>.active:after]:content-[''] [&>.active:after]:absolute [&>.active:after]:w-[50%] [&>.active:after]:h-[2px] [&>.active:after]:rounded-md 
          [&>.active:after]:bg-primary [&>.active:after]:left-[50%] [&>.active:after]:bottom-0 [&>.active:after]:translate-x-[-50%]"
          >
            <div className={cn(active === 1 && 'active')} onClick={() => setActive(1)}>
              {t('chatFrame.网站脚本')}
            </div>
            <div
              className={cn(active === 2 && 'active')}
              onClick={async () => {
                try {
                  setActive(2)
                  const url = `${import.meta.env.VITE_ADMIN_URL}/previewchat?token=${data?.token}&hide=1`
                  const res = await getShortLink(url)
                  if (res?.code === RESPONSE_CODE.success) setShortLink(res?.data)
                  else message(res?.msg, 'error')
                } catch (error) {
                  console.log('error>---->>', error)
                }
              }}
            >
              {t('chatFrame.聊天短链')}
            </div>
          </div>
          {active === 1 && (
            <>
              <div className="whitespace-pre-line">
                {t(
                  'socialMediaAccount.Pleasecontactyourteamsdevelopmentengineerperformfollowingsteps'
                )}
              </div>
              <div className="bg-lingheTalkingBg pl-2 pr-2 pt-3 pb-3 rounded-md text-lingheTextGray">
                {script}
              </div>
            </>
          )}
          {active === 2 && (
            <div className="w-full flex flex-col gap-4 min-h-[196px]">
              <div>{t('chatFrame.短链接已生成，打开即可与客服交流。')}</div>

              <div className="w-full p-3 flex items-center text-primary rounded-md bg-[#F8FAFC]">
                <span
                  className="underline cursor-pointer"
                  onClick={() => window.open(shortLink, '__blank')}
                >
                  {shortLink}
                </span>
              </div>
            </div>
          )}
        </div>
        <AlertDialogFooter
          style={{ flexDirection: 'row' }}
          className="flex items-center gap-2 border-t h-[54px] pr-3"
        >
          <Button
            variant="outline"
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={() => {
              onConfirm && onConfirm(active === 1 ? script : shortLink)
            }}
          >
            {t('socialMediaAccount.Copy')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(CreateScriptToWeb)
