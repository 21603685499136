import {
  fetchTranslate,
  IChatList,
  IContent,
  RESPONSE_CODE,
  SessionListResponseBody
} from '@brisk/api'
import { filter, find, map, orderBy } from 'lodash'
import { handleFormatTalkListByApi } from '../handleFormatTalkListByApi'
import {
  convInfo,
  CustomElem,
  Json_value_msg,
  MsgSendMessageParamsV2,
  userProfile
} from 'im_electron_sdk/dist/interfaces'
import { TIMConvType, TIMPlatform, TIMMsgStatus, TIMElemType } from 'im_electron_sdk/dist/enumbers'
import { isJsonString, MESSAGE_TYPE, SEND_ROLE, TRANSLATE_ERROR } from '@brisk/utils'
import { decryptMessage, encryptMessage } from './secretMessage'
import { v4 } from 'uuid'
import { changeMsg, findMsg, getConversationInfo } from './ipcTimSdk'
import { useSessionTranslateConfigStore } from '@renderer/store/useSessionTranslateConfigStore'
import { useSingleChatStore } from '@renderer/store/useSingleChatStore'

/**
 * 处理会话列表数据结构
 */
export const formatConversationList = (data: SessionListResponseBody[]): IChatList[] => {
  if (!data || data.length === 0) return []
  const formatRecords = handleFormatTalkListByApi(orderBy(data, ['lastTalkTime'], ['desc']))

  return formatRecords
}

/**
 * 发送消息格式
 * @param data
 * @param id
 * @param custom_elem_data
 * @returns
 */
const formatSendResMsg = (data: IContent, id: string, custom_elem_data: string) => {
  const params: Json_value_msg = {
    message_elem_array: [
      {
        elem_type: TIMElemType.kTIMElem_Custom, // 自定义消息
        custom_elem_data,
        custom_elem_desc: '',
        custom_elem_ext: '',
        custom_elem_sound: ''
      }
    ],
    message_conv_id: id,
    message_conv_type: TIMConvType.kTIMConv_Group,
    message_client_time: Date.now(),
    message_is_from_self: false,
    message_platform: TIMPlatform.kTIMPlatform_Windows,
    message_is_read: false,
    message_is_online_msg: false,
    message_status: TIMMsgStatus.kTIMMsg_Sending, // 发送中
    message_is_forward_message: false
  }

  return {
    params,
    conv_id: id,
    conv_type: TIMConvType.kTIMConv_Group,
    callback: null,
    messageId: data.tid ? data.tid : null
  }
}

/**
 * 处理发送消息数据格式--翻译
 */
export const formatSendMessageTranslate = (data: IContent): MsgSendMessageParamsV2 => {
  const id = data?.conversationId || ''
  // 目前翻译 文字
  const custom_elem_data = formatSendMessageText(data)

  return formatSendResMsg(data, id, custom_elem_data)
}

/**
 * 处理发送消息数据格式
 */
export const formatSendMessage = (data: IContent, type?: string): MsgSendMessageParamsV2 => {
  const id = data?.conversationId || ''
  // 暂时处理图片和文字
  const custom_elem_data =
    type === 'image' ? formatSendMessageImage(data) : formatSendMessageText(data)

  return formatSendResMsg(data, id, custom_elem_data)
}

/**
 * 处理发送文件消息的格式
 * @param data
 * @returns
 */
export const formatSendFileMessage = (data: IContent): MsgSendMessageParamsV2 => {
  const id = data?.conversationId || ''
  const custom_elem_data = formatSendMessageFile(data)

  return formatSendResMsg(data, id, custom_elem_data)
}

/**
 * 处理发送消息数据格式-----多条消息
 */
export const formatSendArrayMessage = (data: IContent[]): MsgSendMessageParamsV2 => {
  const id = data[0]?.conversationId
  const message_elem_array = map(data, (item) => {
    const custom_elem_data =
      item?.type === 'image' ? formatSendMessageImage(item) : formatSendMessageText(item)
    return {
      elem_type: TIMElemType.kTIMElem_Custom, // 自定义消息
      custom_elem_data,
      custom_elem_desc: '',
      custom_elem_ext: '',
      custom_elem_sound: ''
    }
  })
  const params: Json_value_msg = {
    message_elem_array,
    message_conv_id: id,
    message_conv_type: TIMConvType.kTIMConv_Group,
    message_client_time: Date.now(),
    message_is_from_self: false,
    message_platform: TIMPlatform.kTIMPlatform_Windows,
    message_is_read: false,
    message_is_online_msg: false,
    message_status: TIMMsgStatus.kTIMMsg_Sending, // 发送中
    message_is_forward_message: false
  }

  return {
    params,
    conv_id: id,
    conv_type: TIMConvType.kTIMConv_Group,
    callback: null
    // messageId: data.tid ? data.tid : null
  }
}

/**
 * 格式化消息的发送者的用户资料
 * @param data
 */
export const formatSender = (data: userProfile) => {
  return {
    id: data.user_profile_identifier, //消息发送者的 Id
    name: data.user_profile_nick_name, //消息发送者名称
    portrait: data.user_profile_face_url, //消息发送者头像
    exts: '' //用户扩展字段
  }
}

/**
 * 自定义-文本消息
 */
export const formatSendMessageText = (data: IContent): string => {
  const realData = {
    messageType: MESSAGE_TYPE.GENERIC_TEXT,
    ...data?.content,
    MsgID: data.tid,
    // 回复消息 先放自定义消息里
    ...(() =>
      data.referMsg
        ? {
            referMsg: data.referMsg
          }
        : {})()
  }
  const jsonData = JSON.stringify(realData)
  const key = useSingleChatStore.getState()?.secretKey
  let encryptData
  if (key) encryptData = encryptMessage(jsonData, key)
  else encryptData = jsonData
  return encryptData
}

/**
 * 自定义-图片消息
 */
export const formatSendMessageImage = (data: IContent): string => {
  const jsonData = JSON.stringify({
    messageType: MESSAGE_TYPE.GENERIC_IMAGE,
    size: data?.content?.size,
    width: data?.content?.width,
    height: data?.content?.height,
    url: data?.content?.url,
    MsgID: data.tid,
    senderRole: SEND_ROLE.USER
  })
  const key = useSingleChatStore.getState()?.secretKey
  let encryptData
  if (key) encryptData = encryptMessage(jsonData, key)
  else encryptData = jsonData
  return encryptData
}

/**
 * 文件消息
 * @param data
 * @param convId
 * @returns
 */
export const formatSendMessageFile = (data: IContent): string => {
  const jsonData = JSON.stringify({
    ...data.content,
    senderRole: SEND_ROLE.USER,
    MsgID: data.tid
  })
  const key = useSingleChatStore.getState()?.secretKey
  let encryptData
  if (key) encryptData = encryptMessage(jsonData, key)
  else encryptData = jsonData
  return encryptData
}

/**
 * 格式化历史消息列表
 * @param list
 * @returns
 */
export const formatHistoryMsgList = (list: Json_value_msg[]): IContent[] => {
  const key = useSingleChatStore.getState()?.secretKey

  const shouldTranslateArrCustomer = [] // 用户需要翻译的
  const shouldTranslateArrUser = [] // 客服需要翻译的

  const formatData = map(list, (item) => {
    const msgElemData = (item.message_elem_array[0] as CustomElem).custom_elem_data
    let decryptMsg
    if (isJsonString(decryptMsg)) {
      decryptMsg = msgElemData
    } else {
      if (key) decryptMsg = decryptMessage(msgElemData, key)
      else decryptMsg = msgElemData
    }

    if (msgElemData) {
      try {
        const elemData = JSON.parse(decryptMsg)
        // 是否有翻译好的内容
        const hasTranslateRes = elemData?.sendContent || ''

        const {
          message_conv_type,
          message_conv_id,
          message_is_read,
          // message_is_peer_read,
          message_is_from_self,
          message_msg_id,
          message_client_time,
          message_status,
          message_seq,
          message_sender_profile: {
            user_profile_identifier,
            user_profile_nick_name,
            user_profile_face_url
          },
          message_sender
        } = item

        const obj = {
          conversationType: message_conv_type,
          conversationId: message_conv_id,
          isRead: message_is_read,
          isSender: message_is_from_self,
          tid: message_msg_id,
          content: {
            ...elemData,
            content: elemData?.content,
            url: elemData?.url,
            messageType: elemData?.messageType,
            noticeType: elemData?.noticeType,
            ...(() =>
              message_sender === '-1'
                ? {
                    notice: { ...elemData }
                  }
                : {})(),
            ...(() => {
              return {}
            })(),
            translateRes: hasTranslateRes
          },
          ...(() =>
            message_status === 6
              ? { name: MESSAGE_TYPE.RECALL_INFO }
              : { name: elemData.messageType })(),
          sentTime: message_client_time * 1000,
          sentState: message_status,
          messageIndex: message_seq,
          senderId: message_sender,
          sender: {
            id: user_profile_identifier,
            name: user_profile_nick_name,
            portrait: user_profile_face_url,
            exts: ''
          },
          // 回复消息
          ...(() =>
            elemData.referMsg
              ? {
                  referMsg: elemData.referMsg
                }
              : {})(),
          translateState: hasTranslateRes ? 2 : 1
        }
        // if (!hasTranslateRes && elemData?.content) {
        //   message_is_from_self
        //     ? shouldTranslateArrUser.push({ ...obj })
        //     : shouldTranslateArrCustomer.push({ ...obj })
        // }
        /** 只翻译左侧的访客发的消息 右侧客服的不翻译-显示历史翻译的 */
        if (!(message_is_from_self || elemData?.senderRole !== SEND_ROLE.VISITOR))
          shouldTranslateArrCustomer.push({ ...obj })
        return obj
      } catch (error) {
        const id = v4()
        return {
          content: {
            content: '[未知消息]',
            translateRes: '未知消息'
          },
          name: MESSAGE_TYPE.GENERIC_TEXT,
          messageIndex: id,
          tid: id,
          sentState: 2,
          translateState: 2
        }
      }
    }
  }) as unknown as IContent[]

  translateMessage(shouldTranslateArrCustomer, shouldTranslateArrUser) // 历史消息不翻译了 有就展示
  return formatData
}

/**
 * 映射群组信息
 * @param list
 * @param infoList
 * @returns
 */
export const formatConvListInfo = (list: IChatList[], infoList: convInfo[]): IChatList[] => {
  if (!infoList || !infoList.length) return list
  return map(list, (item) => {
    // 在 infoList 中找到与 list 对应的项
    const matchedInfo = infoList.find((info) => info.conv_id === item.conversationId)
    // 如果找到了对应项，将 conv_unread_num 赋值给 unreadCount
    if (matchedInfo) {
      return {
        ...item,
        unreadCount: matchedInfo.conv_unread_num
      }
    }

    // 如果没有匹配项，返回原来的 item
    return item
  })
}

/**
 *
 * @param list
 * @returns
 */
export const formatListInfomation = async (list: IChatList[]): Promise<IChatList[]> => {
  const convParam = map(list, (item) => ({
    get_conversation_list_param_conv_id: item.conversationId,
    get_conversation_list_param_conv_type: TIMConvType.kTIMConv_Group
  }))
  const convInfo = await getConversationInfo({ json_get_conv_list_param: convParam })
  const { json_param } = convInfo
  const formatConvList = formatConvListInfo(list, json_param || [])
  return formatConvList
}

/**
 * 翻译消息
 * @param messageListCustomer
 * @param messageListUser
 */
export const translateMessage = async (
  messageListCustomer: IContent[],
  messageListUser: IContent[]
) => {
  try {
    const {
      customerEnableStatus,
      userEnableStatus,
      customerLanguageCode,
      translateEngine,
      userLanguageCode
    } = useSessionTranslateConfigStore.getState().sessionTranslateConfig

    const translateCustomerContent = messageListCustomer
      .map((item) => item?.content?.content || '')
      .filter((content) => content)

    const translateUserContent = messageListUser
      .map((item) => item?.content?.content || '')
      .filter((content) => content)
    const { setTransLateResMsgData, setIsUpdate } = useSingleChatStore.getState()

    if (!translateCustomerContent.length && !translateUserContent.length) return

    const handleTranslateResponse = (res, messageList, type?: 'customer' | 'user') => {
      if (res?.code === RESPONSE_CODE.success && res?.data?.length) {
        return map(res.data, (translatedText, i) => {
          const item = messageList[i]
          const err = translatedText === TRANSLATE_ERROR.ERROR
          const content = item?.content
          const translate = err ? '' : translatedText
          return {
            ...item,
            content: {
              ...content,
              translateRes: translate,
              sendContent: translate,
              translateContent: {
                ...content?.translateContent,
                [translateEngine]: {
                  ...(() => {
                    if (err) return {}
                    return type === 'customer'
                      ? {
                          [customerLanguageCode]: translatedText
                        }
                      : {
                          [userLanguageCode]: translatedText
                        }
                  })()
                }
              }
            },
            translateState: err ? 3 : 2
          }
        })
      }
      return map(messageList, (item) => ({
        ...item,
        content: {
          ...item?.content,
          translateRes: ''
        },
        translateState: 3
      }))
    }

    const translateMessages = async (
      contentList,
      languageCode,
      messageList,
      type: 'customer' | 'user'
    ) => {
      try {
        const res = await fetchTranslate({
          contentList,
          translateEngine,
          toLanguageCode: languageCode
        })
        const translateResult = handleTranslateResponse(res, messageList, type)
        setTransLateResMsgData(translateResult, translateResult[0]?.conversationId)

        /** 调用sdk方法 修改消息 */
        const filterSuccessMsg = filter(translateResult, (it) => it?.translateState === 2)
        if (filterSuccessMsg.length) handleChangeSdkMsg(filterSuccessMsg)
      } catch {
        console.log('Translation error')
        const translateResult = handleTranslateResponse({ data: [] }, messageList)
        setTransLateResMsgData(translateResult, translateResult[0]?.conversationId)
      }
    }

    await Promise.all([
      customerEnableStatus && translateCustomerContent.length
        ? translateMessages(
            translateCustomerContent,
            customerLanguageCode,
            messageListCustomer,
            'customer'
          )
        : null,
      userEnableStatus && translateUserContent.length
        ? translateMessages(translateUserContent, userLanguageCode, messageListUser, 'user')
        : null
    ])

    setIsUpdate(false)
  } catch (error) {
    console.error('Error in translate:', error)
    useSingleChatStore.getState().setIsUpdate(false)
  }
}

/**
 * 会话内翻译配置改变
 * @param convId
 */
export const handleToTranslateWhenConfigChange = (convId: string) => {
  const { messageData } = useSingleChatStore.getState()
  const message = find(messageData, (item) => item.conversationId === convId)?.messages
  const {
    // senderList,
    receiverList
  } = splitBySender(message)
  const {
    customerEnableStatus
    //  userEnableStatus
  } = useSessionTranslateConfigStore.getState().sessionTranslateConfig

  /** 客服侧需要翻译的 */
  // const senderListShouldTranslate = userEnableStatus ? senderList : []
  const senderListShouldTranslate = [] // 客服侧不翻译历史了

  /** 用户侧需要翻译的 */
  const receiverListShouldTranslate = customerEnableStatus ? receiverList : []
  useSingleChatStore.getState().setIsUpdate(true)
  translateMessage(receiverListShouldTranslate, senderListShouldTranslate)
}

/**
 * 将列表按 isSender 字段区分为两个数组，排除没有内容的项
 * @param list 原始消息列表
 * @returns { senderList: IContent[], receiverList: IContent[] }
 */
export const splitBySender = (list: IContent[]) => {
  return list.reduce(
    (acc, item) => {
      if (item.content?.content) {
        // 排除没有内容的项
        if (item.isSender) {
          acc.senderList.push(item)
        } else {
          acc.receiverList.push(item)
        }
      }
      return acc
    },
    { senderList: [], receiverList: [] } as { senderList: IContent[]; receiverList: IContent[] }
  )
}

/**
 * 调用Sdk接口修改翻译后的消息
 * @param data
 */
export const handleChangeSdkMsg = async (data: IContent[]) => {
  try {
    // 使用 map 创建所有的 Promise，并通过 Promise.all 并行执行
    const promises = map(data, async (item) => {
      const res = await findMsg(item.tid)
      const custom_elem_data = formatSendMessageText(item)
      const resData = res?.message_elem_array[0]
      const changeData = {
        ...res,
        message_elem_array: [{ ...resData, custom_elem_data }]
      }
      await changeMsg({ params: changeData })
    })

    // 等待所有 Promise 完成（如果需要）
    await Promise.all(promises)
    console.log('消息修改完成>---->>')
  } catch (error) {
    console.log('error>---->>', error)
  }
}
