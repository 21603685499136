import { Button, cn, Textarea } from '@brisk/ui'
import { FC, memo, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaceBookHomeBusinessVO } from '@renderer/api'
import PromptComp from './PromptComp'
import { CreateChatFbContext } from '../ChannelManage/Messenger'

export interface ICreateChatTelegramForm {
  remark?: string
}

const defaultFormData: ICreateChatTelegramForm = {
  remark: ''
}

const CreateChatFbForm: FC<{
  type: 'create' | 'edit'
  onSubmit: (data) => void
  onCancel?: () => void
  data?: FaceBookHomeBusinessVO
}> = ({ type, onSubmit, onCancel, data }) => {
  const [noSave, setNoSave] = useState(false)
  const { t } = useTranslation()
  const { updateData, setUpdateData } = useContext(CreateChatFbContext)

  const { control, handleSubmit, reset, watch } = useForm<ICreateChatTelegramForm>({
    defaultValues: defaultFormData
  })

  const remarkChange = watch('remark')

  const submit = (data) => {
    onSubmit?.(data)
  }

  useEffect(() => {
    if (data) reset(data)
  }, [data])

  useEffect(() => {
    if (remarkChange) setNoSave(true)
  }, [remarkChange])

  return (
    <div className="w-full h-full">
      <PromptComp when={noSave} />
      <form className="w-full h-full flex flex-col" onSubmit={handleSubmit(submit)}>
        <div
          className="shrink-0 flex-1 p-4 w-full h-full flex flex-col gap-6 text-sm [&>.item>.itemBox]:w-full [&>.item>.itemBox]:flex
         [&>.item>.itemBox]:flex-col [&>.item>.itemBox]:gap-2"
        >
          <div className="item    flex flex-col gap-2">
            <span>{t('socialMediaAccount.PageName')}</span>
            <div className=" h-9 border rounded-md bg-[#F8FAFC] flex items-center px-3 text-lingheTextGray">
              {data?.businessName}
            </div>
          </div>
          <div className="item    flex flex-col gap-2">
            <span>{t('socialMediaAccount.AccountName')}</span>
            <div className=" h-9  border rounded-md bg-[#F8FAFC] flex items-center px-3 text-lingheTextGray">
              {data?.accountName}
            </div>
          </div>

          <div className="item">
            <Controller
              name="remark"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="itemBox relative   ">
                  <div>{t('chatFrame.备注内容')}</div>
                  <Textarea
                    placeholder={t('clientManagement.Pleaseenteracomment')}
                    className={cn('min-h-[180px]')}
                    maxLength={800}
                    value={value}
                    onChange={(e) => {
                      const val = e?.target?.value
                      setUpdateData({
                        ...updateData,
                        data: {
                          ...updateData.data,
                          remark: val
                        }
                      })
                      onChange(val)
                    }}
                    style={{
                      resize: 'none'
                    }}
                  />
                  <div className="relative h-0">
                    <span className="text-lingheTextGray text-xs absolute right-4 bottom-3">
                      {value?.length || 0}/800
                    </span>
                  </div>
                </div>
              )}
            />
          </div>
        </div>

        <div className="w-full h-[48px] shrink-0 border-t flex items-center gap-6 px-4">
          <Button variant="outline" onClick={onCancel}>
            {t('editInformation.cancel')}
          </Button>
          <Button type="submit">
            {type === 'create' ? t('managerBeginnerGuide.Next') : t('editInformation.save')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default memo(CreateChatFbForm)
