import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * ChannelEnableSaveDTO
 */
export interface IEnableReq {
  /**
   * 渠道编号
   */
  channelId: string
  /**
   * 是否启用 0-否 1-是
   */
  enable: number
}
interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 启用禁用渠道
 * @param param
 * @returns
 */
export const fetchChannelEnable = async (param: IEnableReq): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/channel/update-enable`, param)

  return serialize(data)
}
