import { FC } from 'react'
import HeaderNav from './components/HeaderNav'
import { Button, CardContent } from '@brisk/ui'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'lucide-react'
import { generateUrlWithQueryString } from '../../utils'
import { APP_ROUTER_PATH } from '../../router'
import { TCaptchaValidType } from './CaptchaValid'

const CreateCompany: FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <div>
        <HeaderNav title="创建企业" />
        <CardContent className="flex-1 justify-start flex flex-col">
          <p className="text-sm text-lingheGray text-center mb-4">您暂时未加入任何企业</p>
          <Button
            type="submit"
            className="w-full mt-4 h-12 bg-lingheBlue hover:bg-lingheBlue"
            onClick={() =>
              navigate(
                generateUrlWithQueryString(APP_ROUTER_PATH.Captcha_Valid, {
                  redirect: APP_ROUTER_PATH.COMPANY_INFO,
                  captchaValidType: TCaptchaValidType.TEMP_SIGN_IN
                })
              )
            }
          >
            <Plus strokeWidth={1.5} />
            创建企业
          </Button>
        </CardContent>

        {/*TODO：这里直接用url的redirect跳转到这个页面*/}
        {/*<CompanyInfo fn={() => fnBack()} />*/}
      </div>
    </>
  )
}

export default CreateCompany
