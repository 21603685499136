import { fetchTeamTree, ITeamResponse, RESPONSE_CODE, TeamUser } from '@brisk/api'
import { Avatar, AvatarFallback, AvatarImage, Button, cn, Input } from '@brisk/ui'
import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { findMatchingUsersOptimized, TeamUserListCreatChatScript } from './ChooseTeamUserList'
import { ReactSVG } from 'react-svg'
import { concat, debounce, every, filter, forEach, includes, map, pull, some, uniq } from 'lodash'
import { highlightText } from '@renderer/pages/home/MessageMenu/MessageItem'
import { EmployeeStatus } from '@renderer/api/model'
import { Mcheckbox } from './Mcheckbox'
import chooseCustomerIcon from '@renderer/assets/svg/chooseCustomerIcon.svg'
import delChooseCustomer from '@renderer/assets/svg/delChooseCustomer.svg'
import emptyCustomer from '@renderer/assets/svg/emptyCustomer.svg'
import search from '@renderer/assets/svg/search.svg'
import { useQuery } from '@tanstack/react-query'
import { User } from 'lucide-react'
import { statusColor } from '@renderer/pages/home/AccountMenu/AvatarPopover'
import PromptComp from './PromptComp'
import DelCustolerModal from './DelCustolerModal'
import { ChannelManageContext } from '../ChannelManage'

const SearchCustomer: FC<{ onInput: (val) => void }> = ({ onInput }) => {
  const { t } = useTranslation()
  return (
    <div className="w-full h-full relative shrink-0">
      <Input
        className="h-8 rounded-md pl-[36px]"
        placeholder={t('chatFrame.搜索坐席')}
        onInput={(e) => onInput(e?.target?.value)}
      />
      <ReactSVG src={search} className="absolute left-3 top-[50%] -translate-y-1/2 " />
    </div>
  )
}

let allIds = []

const ChooseCustomerForScript: FC<{
  type: 'add' | 'edit'
  onSubmit?: (data: TeamUser[]) => void
  onCancel?: () => void
  addGroupUserList: TeamUser[]
  setAddGroupUserList: (arr: TeamUser[]) => void
}> = ({ type, onSubmit, onCancel, addGroupUserList, setAddGroupUserList }) => {
  const [searchVal, setSearchVal] = useState('')
  const [searchValChooseed, setSearchValChooseed] = useState('')
  const [openedList, setOpenedList] = useState<string[]>([])
  const [searchList, setSearchList] = useState<ITeamResponse[]>([])
  const [searchListChooseed, setSearchListChooseed] = useState<ITeamResponse[]>([])

  const [customerHasChange, setCustomerHasChange] = useState(false) // 有人员变动
  const { setLoadding } = useContext(ChannelManageContext)

  const { t } = useTranslation()

  const { data: teamTree, refetch } = useQuery({
    queryKey: [fetchTeamTree.name],
    queryFn: async () => {
      const res = await fetchTeamTree()
      return res?.data
    }
  })

  /** 左侧筛选 */
  const handleSearch = useCallback(
    debounce(<T extends ITeamResponse>(val: string, targetArr: T[]): void => {
      const res = map(targetArr, (item) => {
        const listRes = filter(
          item?.userList,
          (user) =>
            includes(user?.nickname, val) ||
            includes(user?.phone, val) ||
            includes(user?.email, val)
        )
        if (listRes?.length) {
          setOpenedList(uniq([...openedList, item?.teamId + '']))
          return {
            ...item,
            userList: listRes
          }
        } else {
          return null
        }
      })
      const filteredRes = res.filter(Boolean) as T[]
      setSearchList(filteredRes)
    }, 500),
    [teamTree, searchVal]
  )

  /** 右侧筛选 */
  const handleSearchChooseed = useCallback(
    debounce((val: string, targetArr): void => {
      const res = filter(
        targetArr,
        (user) =>
          includes(user?.nickname, val) || includes(user?.phone, val) || includes(user?.email, val)
      )
      setSearchListChooseed(res)
    }, 500),
    [teamTree, searchVal]
  )

  const alreadySelect = useMemo(() => {
    if (!teamTree || !addGroupUserList) return []
    return findMatchingUsersOptimized(teamTree, addGroupUserList)
  }, [addGroupUserList, teamTree])

  const leftChooseList = searchVal ? searchList : teamTree
  const rightChooseList = searchValChooseed ? searchListChooseed : alreadySelect

  useEffect(() => {
    searchVal && handleSearch(searchVal, teamTree)
  }, [searchVal, teamTree])

  useEffect(() => {
    searchValChooseed && handleSearchChooseed(searchValChooseed, alreadySelect as ITeamResponse[])
  }, [searchValChooseed, alreadySelect])

  useEffect(() => {
    if (!teamTree || !teamTree?.length) return
    forEach(teamTree, (item) => {
      const idsArr = map(item?.userList, (it) => it?.userId) as TeamUser[]
      allIds = uniq(concat(allIds, idsArr))
    })
  }, [teamTree])

  return (
    <div className="w-full h-full">
      <PromptComp when={true} />
      <div className="w-full h-full flex flex-col">
        <div className="shrink-0 flex-1 w-full h-full flex text-sm overflow-hidden">
          <div className="flex-1 shrink-0 flex flex-col border-r">
            <div className="h-[51px] border-b shrink-0 flex items-center gap-2 px-4">
              <Mcheckbox
                id="allIds"
                allChecked
                checked={addGroupUserList?.length === allIds?.length && allIds?.length > 0}
                onCheckedChange={(e) => {
                  if (e) {
                    setAddGroupUserList(
                      addGroupUserList?.length === allIds?.length ? [] : [...allIds]
                    )
                  } else {
                    setAddGroupUserList([])
                  }
                }}
              />

              <label htmlFor={'allIds'} className="cursor-pointer">
                <span className="font-semibold">{t('chatFrame.全部坐席')}</span>
              </label>
              <span className="text-lingheGray">
                {t('common.Total')}{' '}
                <span className="text-primary font-semibold">{allIds.length}</span> {t('common.个')}
              </span>
            </div>
            <div className="flex-1 shrink-0 flex flex-col overflow-hidden">
              <div className="shrink-0 p-4 pb-2">
                <SearchCustomer onInput={(val) => setSearchVal(val)} />
              </div>

              <div className="w-full flex-1 shrink-0 p-4 pt-0  overflow-y-auto">
                {leftChooseList?.length ? (
                  map(leftChooseList, (item) => {
                    const children = item?.userList
                    const teamId = '' + item?.teamId || ''
                    // const onlineCount =
                    //   filter(children, (child) => child.status === EmployeeStatus.ONLINE).length ||
                    //   0
                    const idsArr = map(children, (it) => it?.userId) as TeamUser[]
                    // allIds = uniq(concat(allIds, idsArr))
                    return (
                      <div
                        key={'' + teamId}
                        className="flex flex-col gap-1 mb-2 text-xs text-lingheTextGray"
                      >
                        <div
                          className={cn(
                            'w-full pl-3 pr-3 flex items-center gap-2 cursor-pointer h-7 rounded-md hover:bg-[#A5A7B11A]'
                          )}
                          onClick={() => {
                            if (children?.length) {
                              if (!includes(openedList, teamId))
                                setOpenedList(uniq([...openedList, teamId]))
                              else setOpenedList(filter(openedList, (it) => it !== teamId))
                            }
                          }}
                        >
                          <ReactSVG
                            src={chooseCustomerIcon}
                            className={cn(
                              'transition',
                              includes(openedList, teamId) && 'rotate-[90deg]',
                              !children?.length && 'opacity-0'
                            )}
                          />
                          <Mcheckbox
                            id={item?.teamId + ''}
                            checked={some(idsArr, (it) => includes(addGroupUserList, it))}
                            allChecked={every(idsArr, (it) => includes(addGroupUserList, it))}
                            onClick={(e) => e.stopPropagation()}
                            onCheckedChange={(e) => {
                              e
                                ? setAddGroupUserList(uniq(concat(addGroupUserList, idsArr)))
                                : setAddGroupUserList(
                                    filter(addGroupUserList, (it) => !includes(idsArr, it))
                                  )
                            }}
                          />
                          <label
                            htmlFor={item?.teamId + ''}
                            className="flex items-center gap-2 cursor-pointer"
                          >
                            <span>
                              {highlightText(item?.teamName, searchVal)}
                              {/* {`(${onlineCount}/${children?.length || 0})`} */}
                            </span>
                          </label>
                        </div>
                        {children?.length ? (
                          <div
                            className={cn(
                              'transition-all duration-300 ease-in-out mb-0',
                              includes(openedList, teamId) ? 'block' : 'hidden'
                            )}
                          >
                            <TeamUserListCreatChatScript
                              searchVal={searchVal}
                              data={children}
                              userList={addGroupUserList as string[]}
                              setUserList={(val) => setAddGroupUserList(val as TeamUser[])}
                            />
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    )
                  })
                ) : (
                  <div className="w-full flex flex-col items-center justify-center min-h-[200px] text-xs text-lingheTextGray">
                    <ReactSVG src={emptyCustomer} />
                    当前暂无坐席
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 shrink-0 flex flex-col">
            <div className="h-[51px] border-b shrink-0 flex items-center gap-2 px-4">
              <span className="font-semibold">{t('chatFrame.已选坐席')}</span>
              <span className="text-lingheGray">
                {t('common.Total')}{' '}
                <span className="text-primary font-semibold">{alreadySelect?.length || 0}</span>{' '}
                {t('common.个')}
              </span>
            </div>
            <div className="flex-1 shrink-0 flex flex-col overflow-hidden">
              <div className="shrink-0 p-4 pb-2">
                <SearchCustomer onInput={(val) => setSearchValChooseed(val)} />
              </div>

              <div className="w-full flex-1 shrink-0 p-4 pt-0 flex flex-col gap-1 overflow-y-auto">
                {rightChooseList?.length ? (
                  map(rightChooseList as TeamUser[], (item, i) => (
                    <div
                      key={item?.userId + item?.nickname + i}
                      className={cn(
                        'w-full flex px-3 items-center justify-between h-7 rounded-md shrink-0 hover:bg-[#A5A7B11A]'
                      )}
                    >
                      <div className="h-full flex items-center gap-2">
                        <div className="relative">
                          <Avatar className="hidden h-5 w-5 sm:flex flex items-center">
                            <AvatarImage
                              alt="Avatar"
                              src={item?.avatarUrl}
                              className="rounded-full"
                            />
                            <AvatarFallback>
                              <User className="" />
                            </AvatarFallback>
                          </Avatar>
                          <div
                            className={cn(
                              'size-[6px] border-white border rounded-full absolute bottom-0 right-0',
                              statusColor[EmployeeStatus[item?.status || '']] || ''
                            )}
                          />
                        </div>
                        <span>{item?.nickname}</span>
                      </div>
                      <ReactSVG
                        src={delChooseCustomer}
                        className="cursor-pointer"
                        onClick={() =>
                          setAddGroupUserList([...pull(addGroupUserList, item?.userId as TeamUser)])
                        }
                      />
                    </div>
                  ))
                ) : (
                  <div className="w-full flex flex-col items-center justify-center min-h-[200px] text-xs text-lingheTextGray">
                    <ReactSVG src={emptyCustomer} />
                    {searchValChooseed ? '当前暂无坐席' : t('chatFrame.请从左侧勾选坐席')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[48px] shrink-0 border-t flex items-center gap-6 px-4">
          <Button variant="outline" onClick={onCancel}>
            {type === 'add' ? t('managerBeginnerGuide.Previous') : t('editInformation.cancel')}
          </Button>
          <Button
            onClick={async () => {
              try {
                setLoadding(true)
                const res = await fetchTeamTree()

                if (res?.code === RESPONSE_CODE.success) {
                  const ids = []
                  forEach(res?.data, (item) => {
                    forEach(item?.userList, (it) => {
                      ids.push(it)
                    })
                  })
                  if (ids.length !== allIds.length) {
                    setCustomerHasChange(true)
                    setLoadding(false)
                  } else onSubmit?.(addGroupUserList)
                } else setLoadding(false)
              } catch (error) {
                console.log('error>---->>', error)
                setLoadding(false)
              }
            }}
          >
            {type === 'add' ? t('chatFrame.完成创建') : t('editInformation.save')}
          </Button>
        </div>
      </div>

      <DelCustolerModal
        title={t('chatFrame.部分坐席已被删除')}
        open={customerHasChange}
        onClose={() => {
          refetch()
          setCustomerHasChange(false)
          setLoadding(false)
        }}
        onConfirm={() => {
          onSubmit?.(addGroupUserList)
          setCustomerHasChange(false)
        }}
        buttonCancelText={t('chatFrame.取消保存，刷新列表')}
        buttonText={t('chatFrame.继续保存')}
        content={
          <div className="text-lingheTextGray text-sm text-center px-8">
            <span>{t('chatFrame.已选坐席中包含[已删除]人员，是否继续保存其他坐席？')}</span>
          </div>
        }
      />
    </div>
  )
}

export default memo(ChooseCustomerForScript)
