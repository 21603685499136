import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * ChannelTelegramRemarkSaveDTO
 */
export interface IUpdateTelegramRemarkReq {
  /**
   * 渠道编号
   */
  channelId: string;
  /**
   * 备注
   */
  remark?: string;
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * telegram-更新渠道备注
 * @param param
 * @returns
 */
export const fetchUpdateTelegramRemark = async (
  param: IUpdateTelegramRemarkReq,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/channel/telegram/update-remark`,
    param,
  );

  return serialize(data);
};
