import { Button, Checkbox, Switch, cn } from '@brisk/ui'
import {
  ChannelTelegramVO,
  IAddTelegramConfigRequest,
  ITelegramResp,
  TeamUser,
  fetchAddTelegramConfig,
  fetchChannelEnable,
  fetchDelChannelConfigs,
  fetchTelegramConfig
  // fetchUpdateTelegramRemark
} from '@renderer/api'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { useMutation } from '@tanstack/react-query'
import { Plus } from 'lucide-react'
import {
  createContext,
  Dispatch,
  FC,
  memo,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { IPageationRef } from '../AddCustomer'
import { ReactSVG } from 'react-svg'
import { ColumnDef } from '@tanstack/react-table'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import creatChatScriptIcon from '@renderer/assets/svg/creatChatScriptIcon.svg'
import delChatScriptIcon from '@renderer/assets/svg/delChatScriptIcon.svg'
import dayjs from 'dayjs'
// import AddTelegramBot from './AddTelegramBot'
import { message } from '@renderer/components/message'
import DelCustolerModal from '../Components/DelCustolerModal'
import { map } from 'lodash'
// import UpdateRemark from './UpdateRemark'
import { IParamForPage } from '@renderer/utils/consts'
import { ManagerTable, ManagerTableRef } from '@renderer/components/ManagerTable'
import { useTranslation } from 'react-i18next'
import CreateTelegramCom from '../Components/CreateTelegramCom'
import { ICreateChatTelegramForm } from '../Components/CreateChatTelegramForm'
import createChatScriptLeftIcon from '@renderer/assets/svg/createChatScriptLeftIcon.svg'
import { ChannelManageContext } from '.'

export const CreateChatTelegramContext = createContext<{
  telegramDataForm: ICreateChatTelegramForm
  setTelegramDataForm: Dispatch<SetStateAction<ICreateChatTelegramForm>>
  addGroupUserList: TeamUser[]
  setAddGroupUserList: (arr: TeamUser[]) => void
  handleGetList: (data: IParamForPage) => Promise<void>
  pageationRef: RefObject<IPageationRef>
  step: number
  setStep: (step: number) => void
}>({
  telegramDataForm: { token: '', remark: '' },
  setTelegramDataForm: () => {},
  addGroupUserList: [],
  setAddGroupUserList: () => {},
  handleGetList: async () => {},
  pageationRef: null,
  step: 1,
  setStep: () => {}
})

const TelegramBot: FC = () => {
  const [telegramData, setTelegramData] = useState<ITelegramResp>()
  const [rowSelect, setRowSelect] = useState<ChannelTelegramVO[]>([])
  /** 创建插件选择的接待客服 */
  const [addGroupUserList, setAddGroupUserList] = useState<TeamUser[]>([])

  const [step, setStep] = useState(1)

  const [telegramDataForm, setTelegramDataForm] = useState({
    token: '',
    remark: ''
  })

  const [showAddTelegramBot, setShowAddTelegramBot] = useState<{
    open: boolean
    data: ChannelTelegramVO | null
  }>({
    data: null,
    open: false
  })
  const { t } = useTranslation()
  const [delData, setDelData] = useState<{
    open: boolean
    data: ChannelTelegramVO[] | null
  }>({
    open: false,
    data: null
  })
  // const [updateData, setUpdateData] = useState<{
  //   open: boolean
  //   data: ChannelTelegramVO | null
  // }>({
  //   open: false,
  //   data: null
  // })
  const { setLoadding } = useContext(ChannelManageContext)

  const tableRef = useRef<ManagerTableRef>(null)
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  const { mutateAsync: getTelegramList } = useMutation({
    mutationFn: fetchTelegramConfig
  })

  const { mutateAsync: addTelegramConfig } = useMutation({
    mutationFn: fetchAddTelegramConfig
  })

  const { mutateAsync: changeEnable } = useMutation({
    mutationFn: fetchChannelEnable
  })

  const { mutateAsync: delArrScript } = useMutation({
    mutationFn: fetchDelChannelConfigs
  })

  // const { mutateAsync: updateTelegramRemark } = useMutation({
  //   mutationFn: fetchUpdateTelegramRemark
  // })

  const handleGetList = async (param: IParamForPage): Promise<void> => {
    try {
      const res = await getTelegramList(param)
      if (res.code === RESPONSE_CODE.success) {
        console.log('res', res?.data)
        setTelegramData(res.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCloseCreate = () => {
    setShowAddTelegramBot({
      open: false,
      data: null
    })
    setStep(1)
    setTelegramDataForm(null)
    setAddGroupUserList([])
  }

  const columns: ColumnDef<ChannelTelegramVO>[] = useMemo(
    () => [
      {
        id: 'name',
        className: 'border',
        header: ({ table }) => (
          <div className="flex items-center gap-3">
            <Checkbox
              id="Select all"
              checked={table.getIsAllPageRowsSelected()}
              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              aria-label="Select all"
            />
            <label htmlFor="Select all">{t('socialMediaAccount.Name')}</label>
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center gap-3 h-full max-w-[150px] text-ellipsis overflow-hidden whitespace-nowrap">
            <Checkbox
              id={row?.original?.channelId + ''}
              checked={row.getIsSelected()}
              onCheckedChange={(value) => row.toggleSelected(!!value)}
              aria-label="Select row"
            />
            <label htmlFor={row?.original?.channelId + ''} className="flex flex-col gap-0.5">
              <span>{row?.original?.name}</span>
              <span className="text-lingheGray">{row?.original?.userName}</span>
            </label>
          </div>
        )
      },
      {
        id: 'tokenEffective',
        className: 'border',
        header: () => <div>{t('socialMediaAccount.TokenStatus')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          const effective = +original?.tokenEffective === 1
          return (
            <div className="flex flex-col gap-0.5">
              <span className={cn(effective ? 'text-[#23D881]' : 'text-lingheError')}>
                {effective ? t('socialMediaAccount.Valid') : t('socialMediaAccount.Invalid')}
              </span>
              <span className="text-lingheGray">
                {dayjs(original?.tokenCheckDate).format('YY-MM-DD HH:mm:ss')}
              </span>
            </div>
          )
        }
      },
      {
        id: 'remark',
        className: 'border',
        header: () => <div>{t('socialMediaAccount.Notes')}</div>,
        cell: ({ row: { original } }) => (
          <div className="max-w-[300px] line-clamp-2 break-words overflow-hidden text-ellipsis">
            {original?.remark}
          </div>
        )
      },
      {
        id: 'createTime',
        className: 'border',
        header: () => <div>{t('socialMediaAccount.AddTime')}</div>,
        cell: ({ row: { original } }) => (
          <div>{dayjs(original?.createTime).format('YY-MM-DD HH:mm:ss')}</div>
        )
      },
      {
        id: 'enable',
        className: 'border',
        header: () => <div>{t('socialMediaAccount.Enable')}</div>,
        cell: ({ row: { original } }) => (
          <Switch
            checked={Boolean(original?.enable)}
            onCheckedChange={async (e) => {
              try {
                const res = await changeEnable({
                  channelId: original?.channelId,
                  enable: Number(e)
                })
                if (res?.code === RESPONSE_CODE.success) {
                  message(
                    `${e ? t('socialMediaAccount.Done') : t('socialMediaAccount.disableDone')}`,
                    'success'
                  )
                  handleGetList({
                    pageNum: pageationRef?.current?.currentPage || 1,
                    pageSize: pageationRef?.current?.itemsPerPage || 10
                  })
                }
              } catch (error) {
                console.log('error', error)
              }
            }}
          />
        )
      },
      {
        id: 'edit',
        className: 'border',
        header: () => <div>{t('socialMediaAccount.Actions')}</div>,
        cell: ({ row: { original } }) => (
          <Button
            variant="link"
            className="hover:no-underline p-0"
            // onClick={() => setUpdateData({ open: true, data: original })}
            onClick={() => setShowAddTelegramBot({ open: true, data: original })}
          >
            {t('autoReception.edit')}
          </Button>
        )
      }
    ],
    []
  )

  useEffect(() => {
    handleGetList({
      pageNum: 1,
      pageSize: 10
    })
  }, [])

  return (
    <CreateChatTelegramContext.Provider
      value={{
        telegramDataForm,
        setTelegramDataForm,
        addGroupUserList,
        setAddGroupUserList,
        handleGetList,
        pageationRef,
        step,
        setStep
      }}
    >
      <div className="w-full h-full overflow-hidden flex flex-col">
        <div className="p-3">
          <div className="h-[56px] shrink-0 flex items-center gap-4 pl-3 pr-3 [&_button]:h-8 bg-[#F6F7F9] rounded-md [&_button]:flex [&_button]:items-center [&_button]:justify-center [&_button]:gap-[10px]">
            {!showAddTelegramBot.open ? (
              <>
                <Button
                  onClick={() =>
                    setShowAddTelegramBot({
                      open: true,
                      data: null
                    })
                  }
                >
                  <ReactSVG src={creatChatScriptIcon} />
                  {t('socialMediaAccount.Create')}
                </Button>
                {/* <Button variant="link" className="hover:no-underline p-0" onClick={() => {}}>
            如何添加 Telegram Bot?
          </Button> */}
                {telegramData?.records?.length ? (
                  <Button
                    disabled={!rowSelect?.length}
                    className="text-lingheError"
                    variant="outline"
                    onClick={() => setDelData({ open: true, data: rowSelect })}
                  >
                    <ReactSVG src={delChatScriptIcon} />
                    {t('socialMediaAccount.Delete')}
                  </Button>
                ) : (
                  false
                )}
              </>
            ) : showAddTelegramBot.data ? (
              <span className="flex items-center gap-2 font-semibold text-base">
                <ReactSVG
                  src={createChatScriptLeftIcon}
                  className="cursor-pointer"
                  onClick={() => {
                    step > 1 ? setStep(step - 1) : handleCloseCreate()
                  }}
                />
                {t('chatFrame.编辑机器人')}
              </span>
            ) : (
              <span className="flex items-center gap-2 font-semibold text-base">
                <ReactSVG
                  src={createChatScriptLeftIcon}
                  className="cursor-pointer"
                  onClick={() => {
                    step > 1 ? setStep(step - 1) : handleCloseCreate()
                  }}
                />
                {t('chatFrame.添加机器人')}
              </span>
            )}
          </div>
        </div>
        <div className="flex-1 w-full overflow-hidden shrink-0">
          {showAddTelegramBot.open ? (
            <div className="w-full h-full p-4">
              <CreateTelegramCom
                data={showAddTelegramBot.data}
                onClose={handleCloseCreate}
                onConfirm={async () => {
                  try {
                    const param = {
                      ...telegramDataForm,
                      userIds: addGroupUserList
                    }
                    const res = await addTelegramConfig(param as IAddTelegramConfigRequest)
                    if (res?.code === RESPONSE_CODE.success) {
                      message(t('common.successAdd'), 'success')
                      handleGetList({
                        pageNum: pageationRef?.current?.currentPage || 1,
                        pageSize: pageationRef?.current?.itemsPerPage || 10
                      })
                      handleCloseCreate()
                      setLoadding(false)
                    } else {
                      message(res?.msg, 'error')
                      setLoadding(false)
                    }
                  } catch (error) {
                    console.log('error', error)
                    setLoadding(false)
                  }
                }}
              />
            </div>
          ) : (
            <ManagerTable<ChannelTelegramVO>
              ref={tableRef}
              pageationRef={pageationRef}
              data={telegramData?.records || []}
              columns={columns}
              rowCount={telegramData?.total || 0}
              onPageChange={(pageNum, pageSize) => handleGetList({ pageNum, pageSize })}
              onRowSelectionChange={(rows) => setRowSelect(rows)}
              emptyElement={() => (
                <div className="flex items-center justify-center pt-20">
                  <div className="flex flex-col items-center justify-center gap-2">
                    <ReactSVG src={noSearchResult} />
                    <span className="mb-2 text-sm text-lingheTextGray">
                      {t('socialMediaAccount.NoTelegramBotyetpleaseaddonefirst')}
                    </span>
                    <Button
                      onClick={() =>
                        setShowAddTelegramBot({
                          open: true,
                          data: null
                        })
                      }
                    >
                      <Plus size={16} />
                      {t('socialMediaAccount.AddBot')}
                    </Button>
                    {/* <Button variant="link" className="hover:no-underline p-0" onClick={() => {}}>
                  如何添加 Telegram Bot?
                </Button> */}
                  </div>
                </div>
              )}
              headerStyle="bg-white border"
            />
          )}
        </div>

        {/* <AddTelegramBot
        open={showAddTelegramBot.open}
        onClose={() =>
          setShowAddTelegramBot({
            open: false,
            data: null
          })
        }
        onConfirm={async (token) => {
          try {
            const res = await addTelegramConfig(token)
            if (res?.code === RESPONSE_CODE.success) {
              message(t('common.successAdd'), 'success')
              handleGetList({
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
              setShowAddTelegramBot({
                open: true,
                data: null
              })
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
      /> */}

        <DelCustolerModal
          title={t('common.Prompt')}
          open={delData.open}
          onClose={() => {
            setDelData({
              open: false,
              data: null
            })
          }}
          onConfirm={async () => {
            try {
              const res = await delArrScript(map(delData?.data, (it) => it?.channelId))
              if (res?.code === RESPONSE_CODE.success) {
                message(t('businessManagement.Deletedsuccessfully'), 'success')
                setDelData({
                  open: false,
                  data: null
                })
                handleGetList({
                  pageNum: pageationRef?.current?.currentPage || 1,
                  pageSize: pageationRef?.current?.itemsPerPage || 10
                })
                tableRef?.current?.resetRowSelection()
              }
            } catch (error) {
              console.log('error', error)
            }
          }}
          content={
            <div className="text-lingheTextGray text-sm text-center">
              {t(
                'socialMediaAccount.Afterdeletionyourwebsitewilllongerbeableinitiateonlineconsultations'
              )}
              <br />
              {t('socialMediaAccount.Werecommendthatyou')}
              <Button
                variant="link"
                className="hover:no-underline p-0"
                onClick={async () => {
                  try {
                    const res = await changeEnable({
                      channelId: delData?.data[0]?.channelId,
                      enable: 0
                    })
                    if (res?.code === RESPONSE_CODE.success) {
                      message(t('socialMediaAccount.disableDone'), 'success')
                      handleGetList({
                        pageNum: pageationRef?.current?.currentPage || 1,
                        pageSize: pageationRef?.current?.itemsPerPage || 10
                      })
                    }
                  } catch (error) {
                    console.log('error', error)
                  }
                }}
              >
                ”{t('socialMediaAccount.Deactivate')}“
              </Button>
              ”{t('socialMediaAccount.Deactivate')}“
              <br />
            </div>
          }
        />

        {/* <UpdateRemark
          open={updateData?.open}
          data={updateData?.data}
          onClose={() => setUpdateData({ open: false, data: null })}
          onConfirm={async (remark) => {
            try {
              const res = await updateTelegramRemark({
                channelId: updateData?.data?.channelId,
                remark
              })
              if (res?.code === RESPONSE_CODE.success) {
                message(t('accountInformation.modifiedsuccessfully'), 'success')
                handleGetList({
                  pageNum: pageationRef?.current?.currentPage || 1,
                  pageSize: pageationRef?.current?.itemsPerPage || 10
                })
                setUpdateData({ open: false, data: null })
              }
            } catch (error) {
              console.log('error', error)
            }
          }}
        /> */}
      </div>
    </CreateChatTelegramContext.Provider>
  )
}

export default memo(TelegramBot)
