import { FC, memo, useRef } from 'react'
import { ReactSVG } from 'react-svg'
import sendFileIcon from '@renderer/assets/svg/sendFileIcon.svg'
import fileHoverIcon from '@renderer/assets/svg/fileHoverIcon.svg'
import { message } from '@renderer/components/message'
import {
  allowedFileTypes,
  allowedImageTypes,
  maxFileSize,
  maxImageSize
} from '@renderer/utils/consts'
import { useUploadFileManageOptions } from '@renderer/hooks/manager/useUploadFileManageOptions'
import { useTranslation } from 'react-i18next'
import { fileToBuffer, handleFormatFileContents } from '@renderer/utils'
import { IFileValue } from '@renderer/pages/manager/Components/KeyWordsUploadFile'
import { v4 } from 'uuid'
import { IContent } from '@brisk/api'
import { saveTalkingMsg, sendMessageThen } from '@renderer/utils/juggleHandles'
import { sendFileMsg } from '@renderer/utils/im-sdk/ipcTimSdk'
import { saveFileToDownloadPath } from '@renderer/utils/ipcHandler'
import { useUploadingFileMsgStore } from '@renderer/store/useUploadingFileMsgStore'

/**
 * 选择文件 发送文件
 * @param param0
 * @returns
 */
const ChooseFileChat: FC<{
  params: IContent
  handleSendImage: (file) => void
}> = ({ params, handleSendImage }) => {
  const { uploadFileOption } = useUploadFileManageOptions()
  const imgInputUploadRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0]

    if (!file) return
    imgInputUploadRef.current.value = ''

    const { name, size, type } = file
    const isImage = type.startsWith('image/')

    // 检查文件类型
    if (!allowedImageTypes.includes(type) && !allowedFileTypes.includes(type)) {
      message('不支持的文件类型！', 'info')
      return
    }

    if (isImage && size > maxImageSize) {
      message(t('knowledgeBase.uploadImgLimit'), 'info')
      return
    }

    if (!isImage && size > maxFileSize) {
      message(t('knowledgeBase.uploadFileLimit'), 'info')
      return
    }

    if (isImage) {
      handleSendImage && handleSendImage(file)
      return
    }

    const id = v4()
    const fileData = {
      url: URL.createObjectURL(file as Blob | MediaSource),
      type,
      name,
      id,
      size
    }
    const formateData = handleFormatFileContents([fileData] as IFileValue[])
    const fileParam = { ...params, content: formateData[0], tid: id }
    // 更新view
    sendMessageThen(fileParam, 1)
    saveTalkingMsg(fileParam)

    // 临时存储发送中的文件
    useUploadingFileMsgStore
      .getState()
      .setUploadingFileMsgs(fileParam.conversationId, fileParam, 'add')

    const res = await uploadFileOption(file)
    fileToBuffer(file).then((res) => {
      saveFileToDownloadPath(res, name)
    })
    const updatedData = { ...params, tid: id, content: { ...formateData[0], ...res } }
    sendFileMsg(updatedData)
  }

  return (
    <div className="flex w-8 h-8 items-center justify-center rounded-md hover:bg-lingheBtnHover group">
      <input
        type="file"
        ref={imgInputUploadRef}
        accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .doc, .docx, .xls, .xlsx, .xlsm, .ppt, .pptx, .pptm, .pdf, .txt"
        className="hidden"
        onChange={handleFileChange}
      />

      <ReactSVG
        src={fileHoverIcon}
        className="hidden group-hover:block"
        onClick={() => imgInputUploadRef?.current?.click()}
      />
      <ReactSVG src={sendFileIcon} className="group-hover:hidden" />
    </div>
  )
}

export default memo(ChooseFileChat)
