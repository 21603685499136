import {
  fetchChangeChannelConfig,
  fetchChannelConfigUserList,
  RESPONSE_CODE,
  TeamUser
} from '@brisk/api'
import { FC, memo, useContext, useState } from 'react'
import ChooseCustomerForScript from './ChooseCustomerForScript'
import { message } from '@renderer/components/message'
import { cn } from '@brisk/ui'
// import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { map } from 'lodash'
import CreateChatFbForm from './CreateChatFbForm'
import { FaceBookHomeBusinessVO } from '@renderer/api'
import { CreateChatFbContext } from '../ChannelManage/Messenger'
import { ChannelManageContext } from '../ChannelManage'
import { useTranslation } from 'react-i18next'

export interface ICreateTelegramCom {
  data?: FaceBookHomeBusinessVO
  handleUpdateList: () => void
  onClose: () => void
  onConfirm: (remark: string) => void
}

const CreateFbCom: FC<ICreateTelegramCom> = ({ data, onClose, onConfirm, handleUpdateList }) => {
  const { t } = useTranslation()
  const [firstRequest, setFirstRequest] = useState(true)
  const { addGroupUserList, setAddGroupUserList, step, setStep } = useContext(CreateChatFbContext)
  const { setLoadding } = useContext(ChannelManageContext)

  const { mutateAsync } = useMutation({
    mutationFn: fetchChannelConfigUserList
  })

  const { mutateAsync: handleFetchChangeChannelConfig } = useMutation({
    mutationFn: fetchChangeChannelConfig
  })

  return (
    <div className="w-full h-full rounded-md border flex">
      <div className="flex-1 shrink-0 flex flex-col">
        <div
          className={cn(
            'w-full h-[52px] shrink-0 border-b flex items-center gap-6 px-4 [&>span]:cursor-pointer [&>span]:relative',
            `[&>.active:after]:content-[""] [&>.active:after]:absolute [&>.active:after]:w-[50%] [&>.active:after]:h-[2px] [&>.active:after]:rounded-md
                 [&>.active:after]:bg-primary [&>.active:after]:left-[50%] [&>.active:after]:bottom-[-80%] [&>.active:after]:translate-x-[-50%]`
          )}
        >
          <span className={cn(step === 1 && 'text-primary active')} onClick={() => setStep(1)}>
            {t('chatFrame.编辑FB信息')}
          </span>
          <span
            className={cn(step === 2 && 'text-primary active')}
            onClick={async () => {
              try {
                setStep(2)
                if (!firstRequest) return
                const res = await mutateAsync(data?.channelId)
                if (res.code === RESPONSE_CODE.success) {
                  setFirstRequest(false)
                  setAddGroupUserList(map(res?.data, (item) => item?.userId) as TeamUser[])
                }
              } catch (error) {
                console.log('error>---->>', error)
              }
            }}
          >
            {t('chatFrame.选择接待客服')}
          </span>
        </div>

        {step === 1 && (
          <div className="w-[75%] flex-1 shrink-0">
            <CreateChatFbForm
              type="edit"
              data={data}
              onSubmit={async (dataForm) => {
                onConfirm?.(dataForm?.remark)
              }}
              onCancel={() => onClose?.()}
            />
          </div>
        )}
        {step === 2 && (
          <div className="flex-1 w-[75%] shrink-0  overflow-hidden">
            <ChooseCustomerForScript
              type="edit"
              addGroupUserList={addGroupUserList}
              setAddGroupUserList={setAddGroupUserList}
              onSubmit={async (useridsData) => {
                try {
                  if (!useridsData?.length) {
                    message('请选择接待客服', 'info')
                    return
                  }
                  // 编辑
                  const res = await handleFetchChangeChannelConfig({
                    channelId: data?.channelId,
                    userIds: useridsData as string[]
                  })
                  if (res?.code === RESPONSE_CODE.success) {
                    await handleUpdateList?.()
                    onClose?.()
                  }
                  setLoadding(false)
                } catch (error) {
                  console.log('error', error)
                  setLoadding(false)
                }
              }}
              onCancel={() => {
                onClose?.()
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(CreateFbCom)
