import { FC, memo } from 'react'
import Prompt from 'react-router-prompt'
import DelCustolerModal from './DelCustolerModal'
import { useTranslation } from 'react-i18next'

const PromptComp: FC<{ when: boolean }> = ({ when }) => {
  const { t } = useTranslation()
  return (
    <Prompt
      when={when}
      children={({ isActive, onCancel, onConfirm }) => {
        return (
          <DelCustolerModal
            title={t('chatFrame.内容未保存')}
            open={isActive}
            onClose={onCancel}
            onConfirm={onConfirm}
            content={
              <div className="text-lingheTextGray text-sm text-center px-8">
                <span>{t('chatFrame.您更改的内容未保持')}</span>
              </div>
            }
          />
        )
      }}
    />
  )
}

export default memo(PromptComp)
