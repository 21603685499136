import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import { ReactSVG } from 'react-svg'
import { cn } from '@brisk/ui'
import dayjs from 'dayjs'
import { useMutation } from '@tanstack/react-query'
import {
  fetchDataCustomerChat,
  fetchDataCustomerPageList,
  fetchDataCustomerReception,
  fetchReceptionChatEcharts,
  fetchReceptionEcharts,
  IDataCustomerPageList,
  IDataCustomerPageListRequest,
  IDataCustomerPageListRes,
  IDataCustomerRequestBody,
  IReceptionChatEcharts,
  IReceptionEcharts,
  RESPONSE_CODE
} from '@brisk/api'
import { TipsIcon } from '../ReceptionWelcomeWords'
import { formatNumberWithK } from '@renderer/utils'
import ReactECharts from 'echarts-for-react'
import { map, orderBy } from 'lodash'
import { pubblicOption } from '../DataSync'
import CustomerSelectComp from '../Components/CustomerSelectComp'
import { ManagerTable } from '@renderer/components/ManagerTable'
import { IPageationRef } from '../AddCustomer'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import { ColumnDef } from '@tanstack/react-table'
import { statusColor } from '@renderer/pages/home/AccountMenu/AvatarPopover'
import { EmployeeStatus } from '@renderer/api/model'
import DataPageHeaderDateSearch from '../Components/DataPageHeaderDateSearch'
import manage_data01_1 from '@renderer/assets/imgs/manage_data01_1.png'
import manage_data03_3 from '@renderer/assets/imgs/manage_data03_3.png'
import { useTranslation } from 'react-i18next'

const IconDown: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.16795 9.75192L4.51823 5.77735C4.29672 5.44507 4.53491 5 4.93426 5L11.0657 5C11.4651 5 11.7033 5.44507 11.4818 5.77735L8.83205 9.75192C8.43623 10.3457 7.56377 10.3457 7.16795 9.75192Z"
      fill="#FF4821"
    />
  </svg>
)

const IconUp: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.83205 6.24807L11.4818 10.2227C11.7033 10.5549 11.4651 11 11.0657 11L4.93426 11C4.53491 11 4.29672 10.5549 4.51823 10.2227L7.16795 6.24808C7.56377 5.65434 8.43623 5.65434 8.83205 6.24807Z"
      fill="#23D881"
    />
  </svg>
)

const formateLineData = (data: IReceptionEcharts[]): IReceptionEcharts[] =>
  // 暂时用dateTimes
  orderBy(data, ['dateTimes'], ['asc'])

interface IOptionParam {
  countNumCollection?: IReceptionEcharts[] // 总数
  countNewNumCollection?: IReceptionEcharts[] // 新访客数
  countOldNumCollection?: IReceptionEcharts[] // 常客
}

const index: FC = () => {
  const { t } = useTranslation()
  /** 接待访客数option */
  const echartsOption = (param: IOptionParam): { [key: string]: unknown } => {
    const countAll = formateLineData(param?.countNumCollection || [])
    const countNew = formateLineData(param?.countNewNumCollection || [])
    const countOld = formateLineData(param?.countOldNumCollection || [])
    return {
      ...pubblicOption,
      legend: {
        data: [
          t('statistics.TotalVisitors'),
          {
            icon: 'path://M1 4C1 4 2 7 4.5 7C7 7 8 4 8 4C8 4 9 1 11.5 1C14 1 15 4 15 4',
            name: t('statistics.NewVisitors')
          },
          {
            icon: 'path://M1 4C1 4 2 7 4.5 7C7 7 8 4 8 4C8 4 9 1 11.5 1C14 1 15 4 15 4',
            name: t('statistics.NumberofFrequentVisitors')
          }
        ],
        bottom: '0px'
      },
      xAxis: {
        type: 'category',
        data: map(countAll, (it) => dayjs(it?.dateTimes).format('MM-DD'))
      },
      series: [
        {
          name: t('statistics.TotalVisitors'),
          type: 'bar',
          smooth: true,
          data: map(countAll, (it) => it?.num),
          areaStyle: {
            opacity: 0.2
          },
          lineStyle: {
            width: 3
          },
          itemStyle: {
            color: '#247CFF'
          }
        },
        {
          name: t('statistics.NewVisitors'),
          type: 'line',
          smooth: true,
          data: map(countNew, (it) => it?.num),
          areaStyle: { opacity: 0 },
          lineStyle: {
            width: 3
          },
          itemStyle: {
            color: '#65D856'
          }
        },
        {
          name: t('statistics.NumberofFrequentVisitors'),
          type: 'line',
          smooth: true,
          data: map(countOld, (it) => it?.num),
          areaStyle: { opacity: 0 },
          lineStyle: {
            width: 3
          },
          itemStyle: {
            color: '#FF7527'
          }
        }
      ]
    }
  }

  /** 接待会话量option */
  const echartsChatOption = (param: IReceptionChatEcharts[]): { [key: string]: unknown } => {
    const data = formateLineData(param || [])
    return {
      ...pubblicOption,
      legend: {
        data: [t('statistics.Numbersessionsreceived')],
        bottom: '0px'
      },
      xAxis: {
        type: 'category',
        data: map(data, (it) => dayjs(it?.dateTimes).format('MM-DD'))
      },
      series: [
        {
          name: t('statistics.Numbersessionsreceived'),
          type: 'bar',
          smooth: true,
          data: map(data, (it) => it?.num),
          areaStyle: {
            opacity: 0.2
          },
          lineStyle: {
            width: 3
          },
          itemStyle: {
            color: '#247CFF'
          }
        }
      ]
    }
  }
  const [option, setOption] = useState(echartsOption({}))
  const [chatOption, setChatOption] = useState(echartsChatOption([]))
  const [listData, setListData] = useState<IDataCustomerPageListRes>()
  const [customerUserId, setCustomerUserId] = useState('-1')

  const dateSearchRef = useRef(null)
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  /** 接待访客数 */
  const {
    data: receptionData,
    mutateAsync: getReception,
    isPending: receptionLoading
  } = useMutation({
    mutationFn: fetchDataCustomerReception
  })

  /** 会话统计 */
  const {
    data: chatData,
    mutateAsync: getChat,
    isPending: chatLoading
  } = useMutation({
    mutationFn: fetchDataCustomerChat
  })

  /** 接待访客数柱状图 */
  const {
    data: receptionEchartsData,
    mutateAsync: getReceptionEcharts,
    isPending: receptionEchartsLoading
  } = useMutation({
    mutationFn: fetchReceptionEcharts
  })

  /** 会话统计柱状图 */
  const {
    data: receptionChatEchartsData,
    mutateAsync: getReceptionChatEcharts,
    isPending: receptionEchartsChatLoading
  } = useMutation({
    mutationFn: fetchReceptionChatEcharts
  })

  /** 坐席接待分页 */
  const { mutateAsync: getCustomerLsit, isPending: customerPageListLoading } = useMutation({
    mutationFn: fetchDataCustomerPageList
  })

  const handleGetList = async (param: IDataCustomerPageListRequest): Promise<void> => {
    try {
      const res = await getCustomerLsit(param)
      if (res?.code === RESPONSE_CODE.success) {
        setListData(res?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleFetch = useCallback(
    (param: IDataCustomerRequestBody): void => {
      getReception(param)
      getChat(param)
      getReceptionEcharts(param)
      getReceptionChatEcharts(param)
      handleGetList({
        ...param,
        pageNum: pageationRef?.current?.currentPage || 1,
        pageSize: pageationRef?.current?.itemsPerPage || 10
      })
    },
    [dateSearchRef?.current?.date, pageationRef?.current]
  )

  const columns: ColumnDef<IDataCustomerPageList>[] = useMemo(
    () => [
      {
        id: 'nickname',
        header: () => <div>{t('statistics.ReceptionSessions')}</div>,
        cell: ({ row: { original } }) => (
          <div className="flex items-center gap-1">
            <span
              className={cn(
                'w-2 h-2 border-white border rounded-full',
                statusColor[EmployeeStatus[original?.status || '']] || ''
              )}
            />
            <img className="h-6 w-6 rounded-full" src={original?.avatarUrl} alt="" />
            <span>{original?.nickname}</span>
          </div>
        )
      },
      {
        id: 'receptionNum',
        header: () => <div>{t('statistics.Newvisitors')}</div>,
        cell: ({ row: { original } }) => <div>{original?.receptionNum || 0}</div>
      },
      {
        id: 'receptionNewNum',
        header: () => <div>{t('statistics.Numberofinquiries')}</div>,
        cell: ({ row: { original } }) => <div>{original?.receptionNewNum || 0}</div>
      },
      {
        id: 'receptionOldNum',
        header: () => <div>{t('statistics.Numberregularvisitors')}</div>,
        cell: ({ row: { original } }) => <div>{original?.receptionOldNum || 0}</div>
      },
      {
        id: 'sessionNum',
        header: () => <div>{t('statistics.ReceptionSessionVolume')}</div>,
        cell: ({ row: { original } }) => <div>{original?.sessionNum || 0}</div>
      },
      {
        id: 'allocationSessionNum',
        header: () => <div>{t('statistics.Numberofactivesessions')}</div>,
        cell: ({ row: { original } }) => <div>{original?.allocationSessionNum || 0}</div>
      },
      {
        id: 'receptionSessionNum',
        header: () => <div>{t('statistics.ActiveReceptionSessions')}</div>,
        cell: ({ row: { original } }) => <div>{original?.receptionSessionNum || 0}</div>
      },
      {
        id: 'transferSessionNum',
        header: () => <div>{t('statistics.TransferredSessions')}</div>,
        cell: ({ row: { original } }) => <div>{original?.transferSessionNum || 0}</div>
      },
      {
        id: 'intoSessionNum',
        header: () => <div>{t('statistics.IncomingSessions')}</div>,
        cell: ({ row: { original } }) => <div>{original?.intoSessionNum || 0}</div>
      }
    ],
    []
  )

  useEffect(() => {
    handleFetch({
      startDate: dayjs(dateSearchRef?.current?.date?.from).startOf('day').toDate(),
      endDate: dayjs(dateSearchRef?.current?.date?.to).endOf('day').toDate()
    })
  }, [])

  useEffect(() => {
    if (receptionEchartsData) setOption(echartsOption(receptionEchartsData?.data))
  }, [receptionEchartsData])

  useEffect(() => {
    if (receptionChatEchartsData) setChatOption(echartsChatOption(receptionChatEchartsData?.data))
  }, [receptionChatEchartsData])

  return (
    <NavBodyLayout
      title={[t('statistics.statisticsTitle'), t('businessManagement.SeatData')]}
      loading={[
        receptionLoading,
        chatLoading,
        receptionEchartsLoading,
        receptionEchartsChatLoading,
        customerPageListLoading
      ]}
    >
      <div className="w-full h-full min-w-[896px] flex flex-col relative overflow-x-hidden overflow-y-auto">
        <DataPageHeaderDateSearch
          ref={dateSearchRef}
          title={t('businessManagement.SeatData')}
          content={t('statistics.Supportfilteringcycle')}
          handleSearch={(date) => {
            const userParams = customerUserId === '-1' ? {} : { userId: customerUserId }
            handleFetch({
              startDate: dayjs(date?.from).startOf('day').toDate(),
              endDate: dayjs(date?.to).endOf('day').toDate(),
              ...userParams
            })
          }}
        />

        <div
          className="flex shrink-0 items-center gap-2 p-3 [&_svg]:z-0 [&_.contentWrap]:z-1 [&_.contentWrap]:flex [&_.contentWrap]:flex-col [&_.contentWrap]:gap-3
          [&_.contentWrap]:pl-4 [&_.contentWrap]:pr-4 [&_.contentWrap]:justify-center [&_.contentWrap]:w-full [&_.contentWrap]:h-full
        [&_.itemWrap]:flex-1 [&_.itemWrap]:min-w-[235px] [&_.itemWrap]:h-[106px] [&_.itemWrap]:relative [&_.itemWrap]:overflow-hidden [&_.itemWrap]:rounded-md shrink-0"
        >
          <div className="itemWrap bg-gradient-to-r from-[#F0F9FF] via-[#D1EDF9] to-[#F0F9FF] rounded-tr-lg rounded-br-lg">
            <div className="contentWrap">
              <div className="flex items-center gap-1">
                <span>{t('statistics.Newvisitors')}</span>
                {/* <TipsIcon content="xxxxxx" side="top" /> */}
              </div>
              <div className="flex items-center justify-between pr-20 [&_.item>span]:z-10 [&_.item>span]:text-center [&_.item]:flex [&_.item]:flex-col [&_.item]:justify-center [&_.item]:gap-0.5 [&>.item>span:first-child]:text-base [&>.item>span:first-child]:font-semibold">
                <div className="item">
                  <span className="text-primary">
                    {formatNumberWithK(receptionData?.data?.countNum || 0)}
                  </span>
                  <span>{t('statistics.TotalVisitors')}</span>
                </div>
                <div className="item">
                  <span
                    className={cn(
                      'relative',
                      receptionData?.data?.countNumRatio >= 0
                        ? 'text-[#23D881]'
                        : 'text-lingheError'
                    )}
                  >
                    {Math.abs(receptionData?.data?.countNumRatio || 0)}%
                    <span className="absolute top-1/2 -right-6 -translate-y-1/2">
                      {receptionData?.data?.countNumRatio >= 0 ? <IconUp /> : <IconDown />}
                    </span>
                  </span>
                  <span>{t('statistics.Ringcomparison')}</span>
                </div>
                <div className="item">
                  <span>{formatNumberWithK(receptionData?.data?.countNewNum || 0)}</span>
                  <span>{t('statistics.NewVisitors')}</span>
                </div>
                <div className="item">
                  <span>{formatNumberWithK(receptionData?.data?.countOldNum || 0)}</span>
                  <span>{t('statistics.NumberofFrequentVisitors')}</span>
                </div>
              </div>
            </div>
            <img
              src={manage_data01_1}
              alt=""
              className="z-0 absolute right-0 bottom-0 w-[50%] h-[100%]"
            />
          </div>
          <div className="itemWrap bg-gradient-to-r from-[#EAEFFB] via-[#D0DEF9] to-[#EAEFFB] rounded-tr-lg rounded-br-lg">
            <div className="contentWrap">
              <div className="flex items-center gap-1">
                <span>{t('statistics.Sessionstatistics')}</span>
                {/* <TipsIcon content="xxxxxx" side="top" /> */}
              </div>
              <div className="flex items-center gap-20 pr-20 [&_.item>span]:z-10 [&_.item>span]:text-center [&_.item]:flex [&_.item]:flex-col [&_.item]:justify-center [&_.item]:gap-0.5 [&>.item>span:first-child]:text-base [&>.item>span:first-child]:font-semibold">
                <div className="item">
                  <span className="text-primary">
                    {formatNumberWithK(chatData?.data?.countNum || 0)}
                  </span>
                  <span>{t('statistics.Numbersessionsreceived')}</span>
                </div>
                <div className="item">
                  <span
                    className={cn(
                      'relative',
                      chatData?.data?.countNumRatio >= 0 ? 'text-[#23D881]' : 'text-lingheError'
                    )}
                  >
                    {Math.abs(chatData?.data?.countNumRatio || 0)}%
                    <span className="absolute top-1/2 -right-6 -translate-y-1/2">
                      {chatData?.data?.countNumRatio >= 0 ? <IconUp /> : <IconDown />}
                    </span>
                  </span>
                  <span>{t('statistics.Ringcomparison')}</span>
                </div>
              </div>
            </div>
            <img
              src={manage_data03_3}
              alt=""
              className="z-0 absolute right-0 bottom-0 w-[50%] h-[100%]"
            />
          </div>
        </div>

        {/* <div className="w-full h-80 shrink-0 overflow-x-auto overflow-y-hidden pl-6 pr-6"> */}
        <div className="w-full h-80 shrink-0 pl-6 pr-6">
          <div className="h-full flex items-center gap-1">
            <div className="h-full  min-w-[50%]">
              <ReactECharts
                option={option}
                className="h-[99%] w-[117%] -translate-x-[7%]"
                lazyUpdate
              />
            </div>
            <div className="h-full min-w-[50%]">
              <ReactECharts
                option={chatOption}
                className="h-[99%] w-[117%] -translate-x-[7%]"
                lazyUpdate
              />
            </div>
          </div>
        </div>

        <div className="flex shrink-0 items-center p-3 h-[54px] border-b">
          <span className="text-base font-semibold">{t('statistics.Seatreceptiondetails')}</span>
        </div>

        <div className="h-16 shrink-0 pl-3 pr-3 flex items-center">
          <CustomerSelectComp
            onValueChange={(val) => {
              setCustomerUserId(val)
              const userParams = val === '-1' ? {} : { userId: val }
              handleGetList({
                startDate: dateSearchRef?.current?.date?.from,
                endDate: dateSearchRef?.current?.date?.to,
                pageNum: 1,
                pageSize: 10,
                ...userParams
              })
            }}
          />
        </div>

        <div className="shrink-0 h-[528px] overflow-hidden">
          <ManagerTable<IDataCustomerPageList>
            pageationRef={pageationRef}
            data={listData?.records || []}
            rowCount={listData?.total || 0}
            columns={columns}
            onPageChange={(pageNum, pageSize) => {
              const userParams = customerUserId === '-1' ? {} : { userId: customerUserId }
              handleGetList({
                startDate: dateSearchRef?.current?.date?.from,
                endDate: dateSearchRef?.current?.date?.to,
                pageNum,
                pageSize,
                ...userParams
              })
            }}
            emptyElement={() => (
              <div className="flex items-center justify-center pt-20">
                <div className="flex flex-col items-center justify-center gap-2">
                  <ReactSVG src={noSearchResult} />
                  <span className="mb-2 text-sm text-lingheTextGray">
                    {t('common.Nodataavailable')}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </NavBodyLayout>
  )
}

export default memo(index)
