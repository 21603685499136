import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react'
import AccountMenu from './AccountMenu'
import { Outlet, useNavigate } from 'react-router-dom'
import { useCurrentAndSearch } from '@renderer/store/useCurrentAndSearch'
import { ChatListStatus } from '@renderer/api/model'
import { useAssignedStore } from '@renderer/store/useAssignedStore'
import { debounce, filter, find } from 'lodash'
import { APP_ROUTER_PATH } from '@renderer/router'
import {
  fetchSessionTranslateConfig,
  fetchUserDetail,
  RESPONSE_CODE,
  UserDetailResponse
} from '@brisk/api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchTagList } from '@renderer/api/manage/fetchTagList'
import { useConnectStatus } from '@renderer/store/useConnectStatus'
import { CombinedMessage, defaultMessageInfoContext } from './type'
import { useTeamWordsHandle } from '@renderer/hooks/useTeamWordsHandle'
import Loading from '@renderer/components/Loading'
import { useUserStore } from '@renderer/store/useUserStore'

import {
  handleReportReadMsg,
  initSdk,
  timGetJoinedGroupList,
  timLogin
} from '@renderer/utils/im-sdk/ipcTimSdk'
import { useUserRoleStore } from '@renderer/store/useUserRoleStore'
import { useSessionTranslateConfigStore } from '@renderer/store/useSessionTranslateConfigStore'
import { useSignStore } from '@renderer/store/useSignStore'
import HomePageJoyride from './components/HomePageJoyride'

export const MessageInfoContext = createContext<CombinedMessage>(defaultMessageInfoContext)

export const Home: FC = () => {
  const [userDetailData, setUserDetailData] = useState<UserDetailResponse>({ channelEnableType: 1 })
  const [loading, setLoading] = useState(false)

  const { sessionTranslateConfig, setSessionTranslateConfig } = useSessionTranslateConfigStore()
  const { setInputAccountToSign } = useSignStore()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { user } = useUserStore()
  const { role } = useUserRoleStore.getState()
  const isManager = role.roleIdList.includes('1') || role.roleIdList.includes('3')
  const {
    active,
    setActive,
    currentTab,
    setCurrentTab,
    searchResult,
    setSearchResult,
    clearCurrent,
    chatItemActive,
    setChatItemActive,
    shoudScrollWhenSearch,
    setShouldScrollWhenSearch,
    itemData,
    setItemData
  } = useCurrentAndSearch()
  const {
    assignedData,
    unAssignedData,
    finishedData,
    assignedList,
    unAssignedList,
    finishedList,
    reCallMessage,
    allConversations,
    setAssignedData,
    setAssignedList,
    setUnAssignedList,
    setUnAssignedData,
    setfinishedData,
    setFinishedList,
    setAllConversations,
    setReCallMessage,
    delReCallMessage,
    clearChatListInfo,
    shouldUpdate,
    setShouldUpdate
  } = useAssignedStore()

  const { connectStatus, connectStatusCode } = useConnectStatus()
  const {
    wordsDataList,
    personWordsDataList,
    handleGetTeamWordsData,
    isFetch,
    personGroupList,
    handleAddPersonGroup,
    handleSavePersonWords,
    handleDelPersonWords,
    handleDelPersonWordsGroup,
    wordsHandleLoading
  } = useTeamWordsHandle()

  const TALKSTATUS = useMemo(() => {
    return {
      // 会话是否是自己的
      isSelf: user?.userId === itemData?.userId,
      // 是否已结束
      isEnd: itemData?.status === ChatListStatus.FINISHED,
      // 是否在排队中
      isWaiting: itemData?.status === ChatListStatus.UNASSIGNED
    }
  }, [itemData, currentTab])

  const { mutateAsync: getUserDetail } = useMutation({
    mutationFn: fetchUserDetail
  })

  const { mutateAsync: getTranslateConfig } = useMutation({
    mutationFn: fetchSessionTranslateConfig
  })

  const { data: tagList, refetch: refetchTagList } = useQuery({
    queryKey: [fetchTagList?.name],
    queryFn: async () => {
      try {
        const res = await fetchTagList()
        if (res?.code === RESPONSE_CODE.success) return res?.data
      } catch (error) {
        console.log('error', error)
      }
    },
    staleTime: Infinity
  })

  const handleTranslateIn = (id): void => {
    setCurrentTab(ChatListStatus.ASSIGNED)
    setUnAssignedList(filter(unAssignedList, (item) => item?.conversationId !== id))
    navigate(`${APP_ROUTER_PATH.HOME_CHATTING}/${id}`, {
      state: { isTransIn: true }
    })
  }

  const handleGetUserDetail = async (id): Promise<void> => {
    try {
      const res = await getUserDetail(id)
      if (res?.code === RESPONSE_CODE.success) {
        setUserDetailData(res?.data)
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  /** 会话翻译配置 */
  const handleGetSessinTranslateConfig = useCallback(
    debounce(async (sessionId: string) => {
      setLoading(true)
      try {
        const res = await getTranslateConfig(sessionId)
        if (res?.code === RESPONSE_CODE.success) setSessionTranslateConfig(res?.data)
        else setSessionTranslateConfig({})
        setLoading(false)
      } catch (error) {
        console.log('error>---->>', error)
        setSessionTranslateConfig({})
        setLoading(false)
      }
    }, 100),
    [setSessionTranslateConfig, setLoading]
  )

  useEffect(() => {
    const id = itemData?.sessionId
    if (id) handleGetUserDetail(id)
  }, [currentTab, itemData])

  useEffect(() => {
    currentTab === ChatListStatus.ASSIGNED && timGetJoinedGroupList()
  }, [currentTab])

  useEffect(() => {
    if (!chatItemActive) return
    handleReportReadMsg(chatItemActive)
  }, [chatItemActive])

  useEffect(() => {
    initSdk()
    setInputAccountToSign(false)
    if (!localStorage.getItem('newbieGuide')) {
      setOpen(true)
    }
  }, [])

  useEffect(() => {
    setLoading(wordsHandleLoading)
  }, [wordsHandleLoading])

  useEffect(() => {
    if (!user) navigate(APP_ROUTER_PATH.INPUT_ACCOUNT)
  }, [user])

  useEffect(() => {
    ;(async () => {
      await timLogin()
      useAssignedStore.getState().setShouldUpdate()
      timGetJoinedGroupList()
    })()
  }, [user?.status])

  useEffect(() => {
    const data =
      currentTab === ChatListStatus.ASSIGNED
        ? find(assignedList, (it) => it?.conversationId === chatItemActive)
        : currentTab === ChatListStatus.UNASSIGNED
          ? find(unAssignedList, (it) => it?.conversationId === chatItemActive)
          : find(finishedList, (it) => it?.conversationId === chatItemActive)
    setItemData(data)
  }, [chatItemActive])

  useEffect(() => {
    itemData?.sessionId && handleGetSessinTranslateConfig(itemData.sessionId)
  }, [itemData?.sessionId])

  return (
    <MessageInfoContext.Provider
      value={{
        user,
        chatItemActive,
        setChatItemActive,
        connectStatus,
        connectStatusCode,
        itemData,
        userDetailData,
        active,
        currentTab,
        tagList,
        setActive,
        setCurrentTab,
        searchResult,
        setSearchResult,
        handleTranslateIn,
        assignedData,
        unAssignedData,
        finishedData,
        assignedList,
        allConversations,
        unAssignedList,
        finishedList,
        reCallMessage,
        setAssignedData,
        setAssignedList,
        setUnAssignedList,
        setUnAssignedData,
        setfinishedData,
        setFinishedList,
        setAllConversations,
        handleGetUserDetail,
        setReCallMessage,
        delReCallMessage,
        clearChatListInfo,
        clearCurrent,
        wordsDataList,
        personWordsDataList,
        handleGetTeamWordsData,
        isFetch,
        personGroupList,
        handleAddPersonGroup,
        handleSavePersonWords,
        handleDelPersonWords,
        handleDelPersonWordsGroup,
        setLoading,
        refetchTagList,
        shouldUpdate,
        setShouldUpdate,
        TALKSTATUS,
        isManager,
        sessionTranslateConfig,
        handleGetSessinTranslateConfig,
        shoudScrollWhenSearch,
        setShouldScrollWhenSearch,
        setItemData
      }}
    >
      {open && <HomePageJoyride open={open} setOpen={setOpen} />}
      <div className="w-full h-screen flex max-h-[100vh] overflow-y-hidden">
        <div className="flex flex-col w-16 pt-10 border-r">
          <AccountMenu onMenuActive={() => ''} />
        </div>
        <div className="flex-1 min-w-[520px] shrink-0">
          <Outlet />
        </div>
      </div>
      {loading && <Loading />}
    </MessageInfoContext.Provider>
  )
}
