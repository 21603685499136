import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type State = {
  errorCount: number
}

type Action = {
  setErrorCount: (errorCount: number) => void
}

export const usePasswordErrorCount = create(
  persist<State & Action>(
    (set, get) => ({
      errorCount: 0,
      setErrorCount: (errorCount: number): void => {
        // 错误次数超过3次，则弹出提示框
        console.log('errorCount', errorCount)
        set({ errorCount })
      }
    }),
    {
      name: 'errorCount',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
