import { Avatar, AvatarFallback, AvatarImage, Button, Checkbox, cn } from '@brisk/ui'
import {
  FaceBookAccountBusinessVO,
  IFaceboockAccountResp,
  fetchChangeFacebookAccountRemark,
  fetchDeleteFacebookAccount,
  fetchFacebookAccountList
} from '@renderer/api'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { IParamForPage } from '@renderer/utils/consts'
import { useMutation } from '@tanstack/react-query'
import { Plus, User } from 'lucide-react'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import { IPageationRef } from '../AddCustomer'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import UpdateRemark from './UpdateRemark'
import { message } from '@renderer/components/message'
import DelCustolerModal from '../Components/DelCustolerModal'
import { map } from 'lodash'
import Loading from '@renderer/components/Loading'
import { ManagerTable, ManagerTableRef } from '@renderer/components/ManagerTable'
import { useTranslation } from 'react-i18next'

interface IAccountProps {
  rowSelect: FaceBookAccountBusinessVO[]
  setRowSelect: (rows: FaceBookAccountBusinessVO[]) => void
  show: boolean
  setShow: (v: boolean) => void
  oauthUrl: string
}

const FacebookAccount: FC<IAccountProps> = ({
  rowSelect,
  setRowSelect,
  show,
  setShow,
  oauthUrl
}) => {
  const [accountData, setAccountData] = useState<IFaceboockAccountResp>()
  const { t } = useTranslation()
  const [updateData, setUpdateData] = useState<{
    data: FaceBookAccountBusinessVO | null
    open: boolean
  }>({
    open: false,
    data: null
  })

  const tableRef = useRef<ManagerTableRef>(null)
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  const { mutateAsync: getFacebookAccoutList, isPending } = useMutation({
    mutationFn: fetchFacebookAccountList
  })

  const { mutateAsync: changeFacebookRemark } = useMutation({
    mutationFn: fetchChangeFacebookAccountRemark
  })

  const { mutateAsync: delFacebookAccout, isPending: delLoading } = useMutation({
    mutationFn: fetchDeleteFacebookAccount
  })

  const handleGetFacebooKAccout = async (param: IParamForPage): Promise<void> => {
    try {
      const res = await getFacebookAccoutList(param)
      if (res?.code === RESPONSE_CODE.success) {
        setAccountData(res?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const columns: ColumnDef<FaceBookAccountBusinessVO>[] = useMemo(
    () => [
      {
        id: 'accountName',
        className: 'border',
        style: {
          width: 300
        },
        header: ({ table }) => (
          <div className="flex items-center gap-3">
            <Checkbox
              id="Select all"
              checked={table.getIsAllPageRowsSelected()}
              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              aria-label="Select all"
            />
            <label htmlFor="Select all">{t('socialMediaAccount.AccountName')}</label>
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center gap-3 h-full text-ellipsis overflow-hidden whitespace-nowrap">
            <Checkbox
              id={row?.original?.configId + ''}
              checked={row.getIsSelected()}
              onCheckedChange={(value) => row.toggleSelected(!!value)}
              aria-label="Select row"
            />
            <label htmlFor={row?.original?.configId + ''} className="flex items-center gap-0.5">
              <Avatar className="hidden h-7 w-7 sm:flex flex items-center">
                <AvatarImage alt="Avatar" src={row?.original?.avatar} className="rounded-full" />
                <AvatarFallback>
                  <User className="" />
                </AvatarFallback>
              </Avatar>
              <div className="flex flex-col ">
                <span>{row?.original?.accountName}</span>
                <span className="text-lingheGray">{row?.original?.accountId}</span>
              </div>
            </label>
          </div>
        )
      },
      {
        id: 'businessPageNum',
        className: 'border',
        header: () => <div>{t('socialMediaAccount.FacebookAccount')}</div>,
        cell: ({ row: { original } }) => <div className="">{original?.businessPageNum}</div>
      },
      {
        id: 'authState',
        className: 'border',
        header: () => <div>{t('socialMediaAccount.AuthorizationStatus')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          const effective = +original?.authState === 1
          return (
            <div className="flex flex-col gap-0.5">
              <span className={cn(effective ? 'text-[#23D881]' : 'text-lingheError')}>
                {effective ? t('socialMediaAccount.Valid') : t('socialMediaAccount.Invalid')}
              </span>
              <span className="text-lingheGray">
                {dayjs(original?.authTime).format('YYYY-MM-DD HH:mm:ss')}
              </span>
            </div>
          )
        }
      },
      {
        id: 'remark',
        className: 'border',
        header: () => <div>{t('socialMediaAccount.Notes')}</div>,
        cell: ({ row: { original } }) => (
          <div className="max-w-[300px] line-clamp-2 break-words overflow-hidden text-ellipsis">
            {original?.remark}
          </div>
        )
      },
      {
        id: 'authTime',
        className: 'border',
        header: () => <div>{t('socialMediaAccount.AddTime')}</div>,
        cell: ({ row: { original } }) => (
          <div>{dayjs(original?.authTime).format('YYYY-MM-DD HH:mm:ss')}</div>
        )
      },
      {
        id: 'edit',
        className: 'border',
        header: () => <div>{t('socialMediaAccount.Actions')}</div>,
        cell: ({ row: { original } }) => (
          <Button
            variant="link"
            className="hover:no-underline p-0"
            onClick={() => setUpdateData({ open: true, data: original })}
          >
            {t('socialMediaAccount.Notes')}
          </Button>
        )
      }
    ],
    []
  )

  useEffect(() => {
    handleGetFacebooKAccout({
      pageNum: 1,
      pageSize: 10
    })
  }, [])

  return (
    <div className="w-full h-full overflow-hidden text-sm">
      {(isPending || delLoading) && <Loading />}
      <ManagerTable<FaceBookAccountBusinessVO>
        ref={tableRef}
        pageationRef={pageationRef}
        data={accountData?.records || []}
        columns={columns}
        rowCount={accountData?.total || 0}
        onPageChange={(pageNum, pageSize) => handleGetFacebooKAccout({ pageNum, pageSize })}
        onRowSelectionChange={(rows) => setRowSelect(rows)}
        emptyElement={() => (
          <div className="flex items-center justify-center pt-20">
            <div className="flex flex-col items-center justify-center gap-2">
              <ReactSVG src={noSearchResult} />
              <span className="mb-2 text-sm text-lingheTextGray">
                {t('socialMediaAccount.NoFacebookPageyetpleaseaddonefirst')}
              </span>
              <Button onClick={() => window.open(oauthUrl, '__blank')}>
                <Plus size={16} />
                {t('socialMediaAccount.AuthorizeFBAccount')}
              </Button>
              {/* <Button variant="link" className="hover:no-underline p-0" onClick={() => {}}>
                如何授权 FB messenger？
              </Button> */}
            </div>
          </div>
        )}
        headerStyle="bg-white border"
      />

      <UpdateRemark
        open={updateData?.open}
        data={updateData?.data}
        onClose={() => setUpdateData({ open: false, data: null })}
        onConfirm={async (remark) => {
          try {
            const res = await changeFacebookRemark({
              id: updateData?.data?.configId + '',
              remake: remark
            })
            if (res?.code === RESPONSE_CODE.success) {
              message(t('common.successAdd'), 'success')
              handleGetFacebooKAccout({
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
              setUpdateData({ open: false, data: null })
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
      />

      <DelCustolerModal
        title={t('common.Prompt')}
        open={show}
        onClose={() => setShow(false)}
        onConfirm={async () => {
          try {
            const res = await delFacebookAccout(map(rowSelect, (it) => it?.configId))
            if (res?.code === RESPONSE_CODE.success) {
              message(t('businessManagement.Deletedsuccessfully'), 'success')
              handleGetFacebooKAccout({
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
              setShow(false)
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
        content={
          <div className="text-lingheTextGray text-sm text-center">
            <p className="whitespace-pre-line">
              {t(
                'socialMediaAccount.Publicpagesassociatedwiththeaccountwillbedeletedsimultaneously'
              )}
            </p>
            <Button
              variant="link"
              className="hover:no-underline p-0"
              onClick={async () => {
                try {
                  console.log('222', rowSelect)
                } catch (error) {
                  console.log('error', error)
                }
              }}
            >
              ”{t('socialMediaAccount.Deactivate')}“
            </Button>
            ”{t('socialMediaAccount.Deactivate')}“
            <br />
          </div>
        }
      />
    </div>
  )
}

export default memo(FacebookAccount)
