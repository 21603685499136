import { FC, memo, useContext } from 'react'
import { MessageInfoContext } from '../../Home'
import WordsItemWrap from './WordsItemWrap'
import { Button } from '@brisk/ui'
import { RefreshCw } from 'lucide-react'
import { WordsContext } from './KnowledgeBase'
import { TeamWordsFromPcResponse } from '@brisk/api'
import { useTranslation } from 'react-i18next'

const TeamWords: FC<{ list: TeamWordsFromPcResponse[] }> = ({ list }) => {
  const { handleGetTeamWordsData, isFetch } = useContext(MessageInfoContext)
  const { searchList, searchInputRef } = useContext(WordsContext)
  const { t } = useTranslation()
  return (
    <div className="w-full h-full overflow-hidden relative pb-16">
      <div className="w-full h-full overflow-y-auto p-3">
        <WordsItemWrap data={searchInputRef?.current?.value ? searchList : list} />
      </div>
      <div className="w-full h-16 flex items-center justify-end pl-3 pr-3 absolute bottom-0 left-0 border-t bg-white">
        <Button
          disabled={isFetch}
          onClick={() => {
            handleGetTeamWordsData()
          }}
        >
          <RefreshCw size={14} className="mr-1" />
          {t('common.refresh')}
        </Button>
      </div>
    </div>
  )
}

export default memo(TeamWords)
