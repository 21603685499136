import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import NavBodyLayout from '../Components/NavBodyLayout'
import wordsTips from '../../../assets/svg/wordsTips.svg'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import uploadFilePageIcon from '../../../assets/svg/uploadFilePageIcon.svg'
import { Button, cn, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@brisk/ui'
import { Plus } from 'lucide-react'
import { ManagerTable } from '@renderer/components/ManagerTable'
import { IPageationRef } from '../AddCustomer'
import { ColumnDef } from '@tanstack/react-table'
import KeyWordsTips from '../Components/KeyWordsTips'
import { filter, map } from 'lodash'
import DelCustolerModal from '../Components/DelCustolerModal'
import AddKeyWords from '../Components/AddKeyWords'
import { useMutation } from '@tanstack/react-query'
import {
  fetchDeleteKeyWords,
  fetchKeyWordsPage,
  fetchKeyWordsSave,
  KeyWordsPage,
  KeyWordsPageResponse,
  KeyWordsSaveRequest,
  RESPONSE_CODE
} from '@brisk/api'
import { message } from '@renderer/components/message'
import { useTranslation, Trans } from 'react-i18next'
import { MESSAGE_TYPE } from '@brisk/utils'
import ImageViewManage from '@renderer/components/ImageViewManage'

const index: FC = () => {
  const { t } = useTranslation()
  const [listData, setListData] = useState<KeyWordsPageResponse>()
  const [showKeyWordsTips, setShowKeyWordsTips] = useState(true)
  const [isMoving, setIsMoving] = useState(false)
  const [tipPosition, setTipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const [showTipsAgain, setShowTipsAgain] = useState(false)
  const [delData, setDelData] = useState<{
    open: boolean
    data: KeyWordsSaveRequest | null
  }>({
    open: false,
    data: null
  })
  const [addData, setAddData] = useState<{
    open: boolean
    data: KeyWordsSaveRequest | null
  }>({
    open: false,
    data: null
  })

  const tipsRef = useRef<HTMLSpanElement>(null)
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  const { mutateAsync: getKeyWordsList, isPending: listLoading } = useMutation({
    mutationFn: fetchKeyWordsPage
  })

  /** 保存关键词 */
  const { mutateAsync: saveKeyWords, isPending: saveLoading } = useMutation({
    mutationFn: fetchKeyWordsSave
  })

  /** 删除关键词 */
  const { mutateAsync: deleteKeyWords, isPending: deleteLoading } = useMutation({
    mutationFn: fetchDeleteKeyWords
  })

  const handleGetKeyWordsList = async (pageNum?: number, pageSize?: number) => {
    try {
      const res = await getKeyWordsList({
        pageNum: pageNum || pageationRef?.current?.currentPage || 1,
        pageSize: pageSize || pageationRef?.current?.itemsPerPage || 10
      })
      if (res?.code === RESPONSE_CODE.success) setListData(res?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const columns: ColumnDef<KeyWordsPage>[] = useMemo(
    () => [
      {
        id: 'keywordList',
        header: () => <div>{t('knowledgeBase.Keywords')}</div>,
        cell: ({ row: { original } }) => (
          <div className="flex items-center gap-2 flex-wrap">
            {map(original?.keywordList, (it, i) => (
              <span className="bg-[#F2F3F4] rounded pl-1 pr-1 pt-0.5 pb-0.5" key={it + i}>
                {it}
              </span>
            ))}
          </div>
        )
      },
      {
        id: 'response',
        header: () => <div>{t('knowledgeBase.Replycontent')}</div>,
        cell: ({ row: { original } }) => {
          const domArr = original?.response
            ?.split('\n')
            ?.map((line) => `<p class="truncate">${line}</p>`)
          const renderDom = domArr?.length > 3 ? [...domArr.slice(0, 3), '<p>...</p>'] : domArr
          return (
            <div
              contentEditable
              aria-disabled
              className="max-w-[740px] max-h-[180px] overflow-y-auto"
              dangerouslySetInnerHTML={{
                __html: renderDom?.join('')
              }}
            />
          )
        }
      },
      {
        id: 'contents',
        className: 'w-[70px]',
        header: () => <div>{t('knowledgeBase.Picture')}</div>,
        cell: ({ row: { original } }) => {
          const contentsRes = filter(
            map(original?.contents, (item) => {
              try {
                return JSON.parse(item)
              } catch (error) {
                return item
              }
            }),
            (it) => it?.messageType !== MESSAGE_TYPE.GENERIC_TEXT
          )
          return (
            <div className="flex items-center gap-1 w-[150px]">
              {contentsRes.length ? (
                map(contentsRes, (it, i) => {
                  if (it?.messageType === MESSAGE_TYPE.GENERIC_IMAGE)
                    return (
                      <ImageViewManage
                        key={it?.type + it?.messageType + i}
                        src={it?.url}
                        className="shrink-0 w-7 h-7 rounded-md cursor-pointer"
                      />
                    )
                  else
                    return (
                      <TooltipProvider key={it?.type + it?.messageType + i}>
                        <Tooltip delayDuration={200}>
                          <TooltipTrigger>
                            <ReactSVG src={uploadFilePageIcon} />
                          </TooltipTrigger>
                          <TooltipContent className="bg-[rgba(0,0,0,0.6)] text-xs text-white p-0 pt-1 pb-1 pl-[6px] pr-[6px] rounded-md">
                            {it?.name}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )
                })
              ) : (
                <span>-</span>
              )}
            </div>
          )
        }
      },
      {
        id: 'edit',
        className: 'w-[120px]',
        header: () => <div>{t('common.Operation')}</div>,
        cell: ({ row: { original } }) => (
          <div className="flex items-center gap-2 [&_button]:p-0">
            <Button
              variant="link"
              className="hover:no-underline"
              onClick={() =>
                setAddData({ open: true, data: original } as {
                  open: boolean
                  data: KeyWordsSaveRequest
                })
              }
            >
              {t('login.Edit')}
            </Button>
            <span className="text-[#ECECEC]">|</span>
            <Button
              variant="link"
              className="text-lingheError hover:no-underline"
              onClick={() =>
                setDelData({
                  open: true,
                  data: original
                } as { open: boolean; data: KeyWordsSaveRequest })
              }
            >
              {t('chatFrame.removing')}
            </Button>
          </div>
        )
      }
    ],
    []
  )

  const handleOnClose = (): void => {
    if (tipsRef.current) {
      const rect = tipsRef.current.getBoundingClientRect()
      setTipPosition({ x: rect.left - 170, y: window.innerHeight - rect.bottom })
    }
    setTimeout(() => {
      setShowKeyWordsTips(false)
      setIsMoving(false)
    }, 600)
  }

  useEffect(() => {
    handleGetKeyWordsList()
  }, [])

  return (
    <NavBodyLayout
      title={[t('knowledgeBase.knowledgeBaseTitle'), t('knowledgeBase.KeywordResponse')]}
      loading={[listLoading, saveLoading, deleteLoading]}
    >
      <style>
        {`@keyframes fadeOut {
            0% {
              width: 100%;
              opacity: 1;
            }
            60% {
              height: 150;
            }
            90% {
              height: 100;
            }
            100% {
              width: 0;
              height: 0;
            }
          }
          @keyframes scaleTips {
            0% {
              scale: 1;
            }
            50% {
              scale: 1.3;
            }
            100% {
              scale: 1;
            }
          }
        `}
      </style>
      <div className="flex flex-col h-full w-full relative">
        <div className="flex items-center justify-between shrink-0 p-3 h-[54px] border-b">
          <div className="h-full flex items-center gap-1">
            <span ref={tipsRef} className="text-base font-medium">
              {t('knowledgeBase.KeywordResponse')}
            </span>
            <div className="flex h-full items-center gap-1.5">
              <ReactSVG
                src={wordsTips}
                className="cursor-pointer"
                style={{
                  animation: isMoving ? 'scaleTips 1s' : 'none'
                }}
                onMouseEnter={() => {
                  if (showKeyWordsTips) return
                  setShowTipsAgain(true)
                }}
              />
            </div>
          </div>

          <div className="h-full flex items-center gap-3">
            <p className="text-lingheTextGray">
              <Trans
                i18nKey="knowledgeBase.Youcanaddatotalofkeywordsadded"
                values={{ count: 100 }}
                components={{ span: <span /> }}
              ></Trans>
              <span
                className={cn(
                  listData?.total > 0 && listData?.total < 100 && 'text-primary',
                  listData?.total >= 100 && 'text-lingheError'
                )}
              >
                {listData?.total || 0}
              </span>
              /100
            </p>
            <Button onClick={() => setAddData({ open: true, data: null })}>
              <Plus size={14} /> {t('knowledgeBase.AddKeywords')}
            </Button>
          </div>
        </div>

        <div className="flex-1 pt-3 shrink-0 overflow-hidden flex">
          <ManagerTable<KeyWordsPage>
            pageationRef={pageationRef}
            data={listData?.records || []}
            rowCount={listData?.total || 0}
            columns={columns}
            onPageChange={(pageNum, pageSize) => handleGetKeyWordsList(pageNum, pageSize)}
            emptyElement={() => (
              <div className="flex items-center justify-center mt-40">
                <div className="flex flex-col items-center justify-center gap-2">
                  <ReactSVG src={noSearchResult} />
                  <span className="mb-2 text-sm text-lingheTextGray">
                    {t('common.Nodataavailable')}
                  </span>
                  <Button onClick={() => setAddData({ open: true, data: null })}>
                    <Plus size={14} /> {t('knowledgeBase.AddKeywords')}
                  </Button>
                </div>
              </div>
            )}
          />
        </div>

        {showKeyWordsTips && (
          <div
            className={cn(
              'w-full h-[240px] flex items-center justify-center rounded-lg absolute p-3 z-20 overflow-hidden'
            )}
            style={{
              transition: 'all .6s',
              left: tipPosition.x,
              bottom: 0,
              marginBottom: tipPosition.y,
              animation: isMoving ? 'fadeOut .6s forwards' : 'none'
            }}
          >
            {isMoving ? (
              <div className="w-full h-full bg-[#F8F9FA]" />
            ) : (
              <KeyWordsTips
                onClose={() => {
                  setIsMoving(true)
                  handleOnClose()
                }}
              />
            )}
          </div>
        )}

        {showTipsAgain && (
          <div className="w-full absolute left-0 top-[60px] p-3">
            <KeyWordsTips onClose={() => setShowTipsAgain(false)} />
          </div>
        )}
      </div>

      <DelCustolerModal
        title={t('knowledgeBase.DeleteKeyword')}
        open={delData.open}
        onClose={() => {
          setDelData({
            open: false,
            data: null
          })
        }}
        onConfirm={async () => {
          try {
            const res = await deleteKeyWords(delData?.data?.keywordId)
            if (res?.code === RESPONSE_CODE.success) {
              message(t('businessManagement.Deletedsuccessfully'), 'success')
              handleGetKeyWordsList()
              setDelData({
                open: false,
                data: null
              })
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
        content={
          <div className="text-lingheTextGray text-sm text-center">
            {t('knowledgeBase.Areyousureurrentkeywordphrase')}
            <br />
            {t('knowledgeBase.Afterdeletionbotwillrespondautomatically')}
          </div>
        }
      />

      <AddKeyWords
        open={addData?.open}
        data={addData?.data}
        onClose={() => setAddData({ open: false, data: null })}
        onConfirm={async (data) => {
          try {
            const res = await saveKeyWords(data)
            if (res?.code === RESPONSE_CODE.success) {
              message(t('knowledgeBase.SaveSuccessful'), 'success')
              setAddData({ open: false, data: null })
              handleGetKeyWordsList()
            }
          } catch (error) {
            console.log(t('knowledgeBase.SaveSuccessful'), error)
          }
        }}
      />
    </NavBodyLayout>
  )
}

export default memo(index)
