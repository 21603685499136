import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

interface ApiResp extends ClientResponse {
  data: string;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 获取链接短链接
 * @param targetUrl
 * @returns
 */
export const fetchGetShortLink = async (
  targetUrl: string,
): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(
    `/bg/url/link/short?targetUrl=${targetUrl}`,
  );

  return serialize(data);
};
