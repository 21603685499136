import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 删除快捷短语 分组
 * @returns
 */
export const fetchDeletePersonWordsGroup = async (
  groupId,
): Promise<ApiResp> => {
  const { data } = await apiClient.delete<ApiResp>(
    `/preset/message/group/delete/${groupId}`,
  );

  return serialize(data);
};
