import { Avatar, AvatarImage } from '@radix-ui/react-avatar'
import { AvatarFallback, cn, Popover, PopoverContent, PopoverTrigger } from '@brisk/ui'
import { User } from 'lucide-react'
import {
  createContext,
  CSSProperties,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'
import UserTags from './UserTags'
import ChatTextAreal, { ChatTextArealRef } from './ChatTextAreal'
import { ReactSVG } from 'react-svg'
import turnOut from '../../../assets/svg/turnOut.svg'
import detail from '../../../assets/svg/detail.svg'
import talkingLoad from '../../../assets/svg/talkingLoad.svg'
import { sendMessageThen } from '@renderer/utils/juggleHandles'
import UserDetail, { UserDetailRef } from './UserDetail'
import KnowledgeBase from './components/KnowledgeBase'
import ChatListItem from './ChatListItem'
import {
  concat,
  debounce,
  dropRight,
  filter,
  find,
  findIndex,
  includes,
  reverse,
  sortBy,
  toLower
} from 'lodash'
// import { juggle } from '@renderer/lib/sdk/juggle'
import { IContent } from '@renderer/api/chatList'

import { MessageData, useSingleChatStore } from '@renderer/store/useSingleChatStore'
import TurnTalk from './TurnTalk'
import { ChatListStatus } from '@renderer/api/model'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ITeamResponse } from '@renderer/api'
import { MessageInfoContext } from '../Home'
import ConnectError from '../components/ConnectError'
import { replaceEmojiMark } from '@renderer/utils/emojiData'
import FastWrodsModal from './components/FastWrodsModal'
import {
  fetchLanguageEngine,
  fetchLanguageList,
  fetchRestartChat,
  fetchShutdownChat,
  // fetchTeamTree,
  fetchTeamTreeNew,
  fetchTranslate,
  ILanguageList,
  PresetMessageItem,
  RESPONSE_CODE
} from '@brisk/api'
import { useTranslation } from 'react-i18next'
// import { fetchUserTeamTree } from '@renderer/api/team/fetchUserTeamTree'
import { message as messageTips } from '../../../components/message'
import { TIMConvType } from 'im_electron_sdk/dist/enumbers'
import dayjs from 'dayjs'
import { timGetMsgList } from '@renderer/utils/im-sdk/ipcTimSdk'
import { formatHistoryMsgList } from '@renderer/utils/im-sdk/formateData'
// import { useUserRoleStore } from '@renderer/store/useUserRoleStore'
import { ConversitionFrom } from '@renderer/utils/consts'
import { useUploadingFileMsgStore } from '@renderer/store/useUploadingFileMsgStore'
import { Virtuoso } from 'react-virtuoso'
import { MESSAGE_TYPE, SESSION_TYPE, TRANSLATE_ERROR } from '@brisk/utils'
import TranslateComp from './components/TranslateComp'
import translateRetry01 from '@renderer/assets/svg/translateRetry01.svg'
import TranslateIconComp from './components/TranslateIconComp'
import groupTagIcon from '@renderer/assets/svg/groupTagIcon.svg'
import channleIcon from '@renderer/assets/svg/channleIcon.svg'
import AvatarSpin from '@renderer/components/AvatarSpin'
import TooltipComp from './components/TooltipComp'

export const whiteSpaceStyle: CSSProperties = {
  overflowWrap: 'anywhere',
  whiteSpace: 'pre-wrap',
  fontFamily:
    'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
}

let shouldScrollBottom = true
let shouldToBottomTimer
let translateTimer

export interface IOnTimeTranslate {
  state: number // 1: 翻译中 2: 翻译成功 3: 翻译失败
  data: string
}

// const { ConversationType } = juggle

export const defaltFastModalData = {
  visible: false,
  position: {
    left: 50,
    top: 0
  }
}
interface IChattingWindowContext {
  fastWordsModalData: {
    visible: boolean
    position: {
      left: number
      top: number
    }
  }
  setFastWordsModalData: (data: {
    visible: boolean
    position: {
      left: number
      top: number
    }
  }) => void

  handleFastWords: (data: PresetMessageItem) => void
  handleRestartChat: (sessionId: string | number) => void
  handleShutdownChat: (sessionId: string | number) => void

  isManager: boolean
  languageList: ILanguageList[]
  engineList: string[]
  handleTranslateInp: (val: string) => Promise<void>
  shouldTransLate: boolean
  translateOnTimeData: IOnTimeTranslate
  setTranslateOnTimeData: (data: IOnTimeTranslate) => void
  refetchLanguageList: () => void
  textAreaRef: React.MutableRefObject<ChatTextArealRef | null>
}

export const ChattingWindowContext = createContext<IChattingWindowContext>({
  fastWordsModalData: defaltFastModalData,
  setFastWordsModalData: () => {},
  handleFastWords: () => {},
  handleRestartChat: () => {},
  handleShutdownChat: () => {},
  isManager: false,
  languageList: [],
  engineList: [],
  handleTranslateInp: async () => {},
  shouldTransLate: false,
  translateOnTimeData: {
    state: 2,
    data: ''
  },
  setTranslateOnTimeData: () => {},
  refetchLanguageList: () => {},
  textAreaRef: null
})
const ChattingWindow: FC = () => {
  // return null
  const [show, setShow] = useState(false)
  const [showKnowledgeBase, setShowKnowledgeBase] = useState(false)
  const [teamTree, setTeamTree] = useState<ITeamResponse[]>([])
  const [fastWordsModalData, setFastWordsModalData] = useState(defaltFastModalData)
  const [isFetchMore, setIsFetchMore] = useState(false)
  const [isFinished, setisFinished] = useState(false)
  const [inpEnd, setInpEnd] = useState(true) // 判断输入是否结束
  /** 实时翻译结果 */
  const [translateOnTimeData, setTranslateOnTimeData] = useState<IOnTimeTranslate>({
    state: 2,
    data: ''
  })
  const [loadFinished, setLoadFinished] = useState(false)

  const wrapRef = useRef<HTMLDivElement>(null)
  const virtuosoRef = useRef(null)

  const firstItemRef = useRef<HTMLDivElement>(null)
  const textAreaRef = useRef<ChatTextArealRef>(null)
  const userDetailRef = useRef<UserDetailRef>(null)

  const { state } = useLocation()

  const { messageData, setMessageData, setMessageDataList, isUpdate, setIsUpdate } =
    useSingleChatStore()
  const {
    user,
    itemData,
    tagList,
    currentTab,
    searchResult,
    userDetailData,
    // connectStatus,
    connectStatusCode,
    chatItemActive: active,
    handleGetUserDetail,
    setCurrentTab,
    setReCallMessage,
    setShouldUpdate,
    TALKSTATUS,
    setLoading,
    isManager,
    sessionTranslateConfig,
    // handleGetSessinTranslateConfig,
    shoudScrollWhenSearch,
    setItemData
  } = useContext(MessageInfoContext)

  const shouldShowMoreIcon = useMemo(
    () =>
      itemData.channelType === ConversitionFrom.WEBSITE &&
      (currentTab === ChatListStatus.ASSIGNED ||
        currentTab === ChatListStatus.FINISHED ||
        (TALKSTATUS.isSelf && !TALKSTATUS.isWaiting && !TALKSTATUS.isEnd)),
    [currentTab, TALKSTATUS, itemData]
  )

  const shouldShowDetailBtn = useMemo(
    () =>
      isManager ||
      currentTab === ChatListStatus.ASSIGNED ||
      // (currentTab === ChatListStatus.FINISHED &&
      (TALKSTATUS.isSelf && !TALKSTATUS.isWaiting && !TALKSTATUS.isEnd),
    [currentTab, TALKSTATUS]
  )

  // 是否需要翻译
  const shouldTransLate = Boolean(sessionTranslateConfig?.userEnableStatus)

  const messageChatlist = find(messageData, (it) => it?.conversationId === active)?.messages || []

  const { t } = useTranslation()

  /** 翻译语言列表 */
  const { data: languageList, refetch: refetchLanguageList } = useQuery({
    queryKey: [fetchLanguageList.name],
    queryFn: async () => {
      const res = await fetchLanguageList(sessionTranslateConfig.translateEngine)
      if (res?.code === RESPONSE_CODE.success) return res.data
      else return []
    },
    enabled: !!sessionTranslateConfig.translateEngine
  })

  useEffect(() => {
    if (sessionTranslateConfig?.translateEngine) refetchLanguageList()
  }, [sessionTranslateConfig?.translateEngine])

  /** 翻译引擎列表 */
  const { data: engineList } = useQuery({
    queryKey: [fetchLanguageEngine.name],
    queryFn: async () => {
      const res = await fetchLanguageEngine()
      if (res?.code === RESPONSE_CODE.success) return res.data
      else return []
    }
  })

  // const { mutateAsync: getTeamList } = useMutation({
  //   mutationFn: fetchTeamTree
  // })

  const { mutateAsync: getTeamList } = useMutation({
    mutationFn: fetchTeamTreeNew
  })

  const { mutateAsync: restartChat } = useMutation({
    mutationFn: fetchRestartChat
  })

  const { mutateAsync: shutdownChat } = useMutation({
    mutationFn: fetchShutdownChat
  })

  /** 实时翻译 */
  const handleTranslateInp = useCallback(
    debounce(async (val) => {
      // 定义状态更新函数
      const updateTranslateState = (state: number, data = '') => {
        setTranslateOnTimeData({ state, data })
      }

      // 处理空值的情况
      if (!val) {
        updateTranslateState(2)
        return
      }

      // 清理之前的定时器
      if (translateTimer) clearTimeout(translateTimer)

      // 开始翻译状态
      updateTranslateState(1)

      try {
        const { userLanguageCode: toLanguageCode, translateEngine } = sessionTranslateConfig

        const res = await fetchTranslate({
          contentList: [val],
          translateEngine,
          toLanguageCode
        })

        if (res?.code === RESPONSE_CODE.success && res?.data?.length) {
          const translate = res.data[0]

          if (translate === TRANSLATE_ERROR.ERROR) {
            // 翻译失败状态
            updateTranslateState(3)
          } else {
            // 翻译成功状态，使用定时器更新
            translateTimer = setTimeout(() => {
              updateTranslateState(2, translate)
            }, 0)
          }
        } else {
          // 响应无效或失败状态
          updateTranslateState(3)
        }
      } catch (error) {
        console.error('Translation error:', error)
        // 捕获异常状态
        updateTranslateState(3)
      }
    }, 200),
    [sessionTranslateConfig, setTranslateOnTimeData]
  )

  const handleShutdownChat = useCallback(
    async (sessionId) => {
      setLoading(true)
      localStorage.setItem('shutdownBySelf', '1') // 是否客服主动结束
      try {
        const res = await shutdownChat({
          sessionId
        })
        if (res.code !== RESPONSE_CODE.success) messageTips(res.msg, 'error')
        setShouldUpdate()
        setLoading(false)
        setItemData({ ...itemData, status: ChatListStatus.FINISHED })
        setCurrentTab(ChatListStatus.FINISHED)
      } catch (error) {
        localStorage.setItem('shutdownBySelf', '0')
        setLoading(false)
        console.log('error', error)
      }
    },
    [itemData]
  )

  const handleRestartChat = useCallback(
    async (sessionId: number) => {
      try {
        setLoading(true)
        const res = await restartChat({ sessionId })
        if (res?.code === RESPONSE_CODE.success) {
          // !isManager && setCurrentTab(ChatListStatus.ASSIGNED)
          setItemData({ ...itemData, status: ChatListStatus.ASSIGNED, userId: user?.userId })
          setCurrentTab(ChatListStatus.ASSIGNED)
        } else {
          setShouldUpdate()
          messageTips(res.msg)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log('error', error)
      }
    },
    [isManager, user, itemData]
  )

  // 检测滚动条是否在底部
  const handleAtBottomStateChange = useCallback(
    (isAtBottom) => {
      if (!isAtBottom && virtuosoRef.current && !searchResult.value)
        virtuosoRef.current.scrollToIndex(messageChatlist.length)
    },
    [messageChatlist.length, searchResult]
  )
  const handleFastWords = (data: PresetMessageItem) => {
    if (!textAreaRef?.current?.arealRef?.current) return
    textAreaRef.current.arealRef.current.focus()
    // const content = textAreaRef?.current?.getRichTextValue()?.content
    // const lastSlashIndex = content.lastIndexOf('/')
    // const str = lastSlashIndex !== -1 ? content.slice(0, lastSlashIndex) : content
    textAreaRef.current.arealRef.current.innerHTML = ''
    let imgContent = ''
    if (data?.imageContent && data?.imageContent?.length) {
      imgContent = `<img src="${data?.imageContent[0] || ''}" style="width: 135;height: auto;">`
    }
    /** 常用话术覆盖输入区内容 */
    // textAreaRef.current.rangeInsert(replaceEmojiMark(str + data?.textContent + imgContent))
    textAreaRef.current.rangeInsert(replaceEmojiMark(data?.textContent + imgContent))
    // textAreaRef.current.setMessage(str + data?.textContent)
    textAreaRef.current.setMessage(data?.textContent)
  }

  const handleGetMessageList = debounce((time?: number) => {
    const conversationId = active + '' || ''
    const param = {
      conv_id: conversationId,
      conv_type: TIMConvType.kTIMConv_Group,
      params: {
        msg_getmsglist_param_is_ramble: true,
        msg_getmsglist_param_count: 30,
        ...(() => (time ? { msg_getmsglist_param_time_begin: dayjs(time).unix() } : {}))()
      }
    }

    timGetMsgList(param)
      .then((res) => {
        const jsonParams = res?.json_params

        // 确保 jsonParams 是数组
        const list = filter(
          reverse(Array.isArray(jsonParams) ? jsonParams : []),
          (it) => it?.message_elem_array?.[0]?.elem_type === 3
        )
        useSingleChatStore.getState().setIsUpdate(true)
        let formatList = formatHistoryMsgList(list)
        if (!formatList.length || formatList.length < 30) setisFinished(true)
        else setisFinished(false)
        if (time) {
          if (shouldToBottomTimer) clearTimeout(shouldToBottomTimer)
          setIsFetchMore(false)
          shouldScrollBottom = false
          setMessageDataList(conversationId, dropRight(formatList, 1) || [])
          setTimeout(() => {
            virtuosoRef?.current?.scrollToIndex?.(formatList?.length)
          }, 0)
          shouldToBottomTimer = setTimeout(() => {
            shouldScrollBottom = true
          }, 200)
          return
        }
        const { uploadingFileMsgs } = useUploadingFileMsgStore.getState()
        const fileList =
          find(uploadingFileMsgs, (it) => it.conversationId === conversationId)?.messages || []
        if (fileList && fileList?.length) {
          formatList = sortBy(concat(formatList, fileList), 'sentTime')
        }
        if (currentTab === ChatListStatus.FINISHED) {
          // 历史会话
          const data: MessageData = {
            ...res,
            conversationId,
            messages: formatList
          }
          setMessageData(data)
        } else {
          setMessageData({ conversationId, messages: formatList })
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log('err', err)
        setLoading(false)
      })
  }, 200)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      setIsUpdate(false)
      setShow(false)
      // await handleGetSessinTranslateConfig(itemData.sessionId)
      handleGetMessageList()
    })()
  }, [active, state, user?.status])

  /** 是否需要滚动至底部 */
  useEffect(() => {
    if (inpEnd && !searchResult?.value && shouldScrollBottom && !isFetchMore && !isUpdate) {
      virtuosoRef.current.scrollToIndex(messageChatlist.length)
    }
  }, [messageChatlist, active, shouldScrollBottom, isFetchMore, searchResult?.value])

  useEffect(() => {
    if (!searchResult.value) return
    // 有搜索关键字时滚动条滚动到搜索结果位置
    const index = findIndex(messageChatlist, (item) =>
      includes(toLower(item?.content?.content), toLower(searchResult.value))
    )
    if (index !== -1)
      virtuosoRef.current.scrollToIndex({
        index
        // behavior: 'smooth'
      })

    /**
     * 保留草稿
     */
    return (): void => {
      const draft = textAreaRef?.current?.getRichTextValue()?.content
      if (draft) {
        sendMessageThen(
          {
            name: MESSAGE_TYPE.TEXT,
            draft,
            conversationId: active || ''
          } as IContent,
          3
        )
      }
    }
  }, [active, shoudScrollWhenSearch, messageChatlist])

  useEffect(() => {
    shouldScrollBottom = true
  }, [active, currentTab])

  const searchKeytWords = useMemo(
    () => textAreaRef?.current?.getRichTextValue()?.content,
    [textAreaRef?.current?.getRichTextValue()]
  )

  useEffect(() => {
    if (!textAreaRef?.current?.arealRef?.current) return
    const dom = textAreaRef?.current?.arealRef?.current
    // 处理中文输入法的事件
    const handleCompositionStart = () => {
      setInpEnd(false)
    }

    const handleCompositionEnd = () => {
      setInpEnd(true)
    }

    // 添加事件监听器
    dom.addEventListener('compositionstart', handleCompositionStart)
    dom.addEventListener('compositionend', handleCompositionEnd)

    // 清理函数，移除事件监听器
    return () => {
      dom.removeEventListener('compositionstart', handleCompositionStart)
      dom.removeEventListener('compositionend', handleCompositionEnd)
    }
  }, [textAreaRef?.current?.arealRef?.current])

  useEffect(() => {
    show && handleGetUserDetail(itemData?.sessionId || itemData?.latestMessage?.sessionId)
  }, [show])

  return (
    <ChattingWindowContext.Provider
      value={{
        fastWordsModalData,
        setFastWordsModalData,
        handleFastWords,
        handleRestartChat,
        handleShutdownChat,
        isManager,
        languageList,
        engineList,
        handleTranslateInp,
        shouldTransLate,
        translateOnTimeData,
        setTranslateOnTimeData,
        refetchLanguageList,
        textAreaRef
      }}
    >
      <div className="w-full h-full bg-lingheTalkingBg relative flex flex-col">
        {connectStatusCode !== 0 && (
          <div className="w-full h-[46px]  absolute top-0 left-0 ">
            <ConnectError code={connectStatusCode} />
          </div>
        )}
        <div className="w-full h-[68px] flex pl-3 pr-3 items-center justify-between border-b shrink-0 bg-white">
          <div className="flex w-[50%] lg:w-[70%] shrink-0 h-full items-center gap-4">
            <Avatar className="hidden h-10 w-10 sm:flex rounded-full shrink-0 relative">
              <AvatarImage
                alt="Avatar"
                // src={itemData?.conversationPortrait}
                src={userDetailData?.customerInfo?.avatar}
                className="w-full h-full rounded-full"
                onLoad={() => setLoadFinished(true)}
                onError={() => setLoadFinished(true)}
              />
              <AvatarFallback>
                <User />
              </AvatarFallback>
              {!loadFinished && (
                <AvatarSpin
                  loadFinished={loadFinished}
                  calssName="w-full h-full absolute left-0 top-0"
                />
              )}
            </Avatar>
            <div className="flex flex-col  flex-1 shrink-0">
              <div className="text-base font-[500] mb-1 flex items-center gap-[2px]">
                <span>{userDetailData?.customerInfo?.name}</span>

                {userDetailData?.sessionType === SESSION_TYPE.TG_GROUP && (
                  <TooltipComp content="群组">
                    <div>
                      <ReactSVG src={groupTagIcon} />
                    </div>
                  </TooltipComp>
                )}
                {userDetailData?.sessionType === SESSION_TYPE.TG_CHANNEL && (
                  <TooltipComp content="频道">
                    <div>
                      <ReactSVG src={channleIcon} />
                    </div>
                  </TooltipComp>
                )}
              </div>
              <div className="flex items-center gap-3">
                <div className="flex items center gap-1 shrink-0 text-xs text-lingheTextGray">
                  <img src={userDetailData?.channelIconUrl} className="w-3.5 h-3.5" alt="" />
                  <span>{userDetailData?.channelName}</span>
                </div>
                {userDetailData?.customerInfo?.tag?.length ? (
                  <div className="flex items-center gap-1 flex-1 shrink-0">
                    <UserTags
                      sessionId={itemData?.sessionId}
                      tags={userDetailData?.customerInfo?.tag || []}
                      list={tagList}
                      onChange={() => handleGetUserDetail(itemData?.sessionId)}
                      ellipsis
                    />
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
          {shouldShowDetailBtn ? (
            <div className="flex items-center gap-3 shrink-0">
              <Popover
                onOpenChange={async (e) => {
                  if (!e) return
                  const res = await getTeamList({
                    channelId: userDetailData?.channelId,
                    sessionId: itemData?.sessionId
                  })
                  setTeamTree(res?.data)
                }}
              >
                <PopoverTrigger>
                  <span className="cursor-pointer flex items-center gap-2 border rounded-md pt-[0.313rem] pb-[0.313rem] pl-3 pr-3 bg-white">
                    <ReactSVG src={turnOut} />
                    {t('chatFrame.roll_out')}
                  </span>
                </PopoverTrigger>
                <PopoverContent className="p-0" side="bottom" sideOffset={40}>
                  <TurnTalk
                    list={teamTree}
                    sessionId={itemData?.sessionId}
                    conversationId={active + ''}
                  />
                </PopoverContent>
              </Popover>
              <span
                className="cursor-pointer flex items-center gap-2 border rounded-md pt-[0.313rem] pb-[0.313rem] pl-3 pr-3 bg-white"
                onClick={() => {
                  setShowKnowledgeBase(false)
                  setShow(!show)
                }}
              >
                <ReactSVG src={detail} />
                {t('chatFrame.details')}
              </span>
            </div>
          ) : null}
        </div>
        <style>{`
            @keyframes loadingAnimationTalk {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
            .loadingFetch {
              animation: loadingAnimationTalk 1s linear infinite;
            }
          .loading-dots {
            display: inline-block;
          }

          .dot {
            animation: blink 1s step-start infinite;
          }

          .dot:nth-child(1) {
            animation-delay: 0s;
          }

          .dot:nth-child(2) {
            animation-delay: 0.2s; /* 延迟 0.2 秒 */
          }

          .dot:nth-child(3) {
            animation-delay: 0.4s; /* 延迟 0.4 秒 */
          }

          @keyframes blink {
            0%, 20% {
              opacity: 0; /* 开始时透明 */
            }
            50% {
              opacity: 1; /* 中间时完全可见 */
            }
            80%, 100% {
              opacity: 0; /* 结束时透明 */
            }
          }
        `}</style>
        <div className="flex shrink-0 flex-1 relative">
          <div className="flex-1 flex-col flex shrink-0">
            <div className="w-full p-1 pb-0 absolute left-0 top-0" style={{ zIndex: 1 }}>
              <TranslateComp
                onConvLineChange={() => {
                  handleTranslateInp(textAreaRef?.current?.getRichTextValue().content)
                }}
              />
            </div>
            {isFetchMore && (
              <div className="flex items-center justify-center gap-1 text-xs text-lingheTextGray pt-[60px]">
                <ReactSVG className="loading loadingFetch" src={talkingLoad} />
                <span>{t('knowledgeBase.loading')}</span>
              </div>
            )}
            <div className="flex-1 relative shrink-0 overflow-hidden pt-[60px]" ref={wrapRef}>
              <Virtuoso
                ref={virtuosoRef}
                data={messageChatlist}
                overscan={searchResult?.value ? messageChatlist?.length : 10}
                increaseViewportBy={520}
                atTopStateChange={(atTop) => {
                  if (atTop && !isFetchMore && !isFinished) {
                    try {
                      const { sentTime } = messageChatlist[0]
                      if (!sentTime) return
                      handleGetMessageList(sentTime)
                      setIsFetchMore(true)
                    } catch (error) {
                      return
                    }
                  }
                }}
                itemContent={(index, item) => {
                  const isCustemer = itemData?.customerId !== item?.sender?.id
                  return (
                    <div ref={index === 0 ? firstItemRef : null} key={item?.tid}>
                      <ChatListItem
                        shouldShowMoreIcon={shouldShowMoreIcon}
                        triggerPaste={textAreaRef.current?.triggerPaste}
                        onReEditMessage={(text: string) => {
                          if (textAreaRef.current) {
                            textAreaRef.current.arealRef.current.focus()
                            textAreaRef.current.rangeInsert(replaceEmojiMark(text))
                          }
                        }}
                        onReCallMessage={(data) => setReCallMessage(data)}
                        onReCallItemClick={(data) => {
                          const targetDom = document.getElementById(data?.tid)
                          if (!targetDom) return
                          const targetPosition = targetDom?.offsetTop - 200

                          wrapRef.current.scrollTo({
                            top: targetPosition,
                            behavior: 'smooth'
                          })
                        }}
                        userDetailData={userDetailData}
                        msgIndex={index}
                        sessionTranslateConfig={sessionTranslateConfig}
                        isUpdate={isUpdate}
                        isCustemer={isCustemer}
                        {...item}
                      />
                    </div>
                  )
                }}
                atBottomStateChange={handleAtBottomStateChange}
                style={{ height: '100%', width: '100%', paddingBottom: '20px' }}
              />
              {/* 实时翻译 */}
              {shouldTransLate && (
                <div className="max-w-full max-h-[120px] overflow-y-auto overflow-x-hidden px-4 absolute bottom-2 text-lingheTextGray text-sm">
                  <div
                    className="py-2 px-3 rounded-md border z-10 bg-white max-w-full"
                    style={{
                      borderColor: 'rgba(36, 124, 255, 0.2)'
                    }}
                  >
                    {/* 翻译失败 */}
                    {translateOnTimeData.state === 3 && (
                      <div className="w-full flex items-center gap-2">
                        <TranslateIconComp />
                        <div>{t('knowledgeBase.translateError')}</div>
                        <div
                          onClick={() => {
                            handleTranslateInp(textAreaRef?.current?.getRichTextValue().content)
                          }}
                          className={cn('flex items-center gap-1 text-primary cursor-pointer')}
                        >
                          {t('knowledgeBase.translateRetry')}
                          <ReactSVG src={translateRetry01} />
                        </div>
                      </div>
                    )}

                    {translateOnTimeData.state !== 3 && (
                      <div
                        className={cn('flex w-full', translateOnTimeData.data ? 'gap-2' : 'gap-0')}
                      >
                        <TranslateIconComp />
                        {translateOnTimeData.state === 2 && translateOnTimeData.data && (
                          <div
                            style={{
                              overflowWrap: 'anywhere',
                              whiteSpace: 'pre-wrap',
                              fontFamily:
                                'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                            }}
                          >
                            {translateOnTimeData.data}
                          </div>
                        )}
                        {translateOnTimeData.state === 1 && (
                          <span className="loading-dots">
                            <span className="dot">.</span>
                            <span className="dot">.</span>
                            <span className="dot">.</span>
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className="w-full"
              style={{
                height: 172,
                maxHeight: 211
              }}
            >
              <ChatTextAreal
                ref={textAreaRef}
                changeShowKnowledgeBase={() => {
                  setShow(false)
                  setShowKnowledgeBase(!showKnowledgeBase)
                }}
                inpEnd={inpEnd}
              />
            </div>
          </div>
          <div
            className={cn(
              'w-80 bg-white overflow-y-scroll shrink-0 max-h-[100vh] border-l',
              !show && 'hidden'
              // 'absolute right-0 h-full top-0 border-l'
            )}
            style={{ zIndex: 2 }}
          >
            <UserDetail
              ref={userDetailRef}
              userDetailData={{ ...userDetailData, ...itemData }}
              setShow={() => setShow(false)}
              conversationId={active + '' || ''}
              onChangeInfo={() =>
                handleGetUserDetail(itemData?.sessionId || itemData?.latestMessage?.sessionId)
              }
            />
          </div>
          <div
            className={cn(
              'w-80 bg-white overflow-hidden shrink-0 max-h-[100vh] border-l',
              !showKnowledgeBase && 'hidden'
              // 'absolute right-0 h-full top-0 border-l'
            )}
            style={{ zIndex: 2 }}
          >
            <KnowledgeBase onClose={() => setShowKnowledgeBase(false)} id={itemData?.sessionId} />
          </div>

          {fastWordsModalData?.visible && (
            <div
              className="fixed -top-1/2"
              style={{
                left: fastWordsModalData?.position?.left,
                top: fastWordsModalData?.position?.top
              }}
            >
              <div className="w-[334px] h-[296px] flex flex-col justify-end">
                <FastWrodsModal
                  keyWords={searchKeytWords}
                  onSelect={handleFastWords}
                  inpEnd={inpEnd}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </ChattingWindowContext.Provider>
  )
}

export default memo(ChattingWindow)
