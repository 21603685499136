const emojis_icon = [
  'e_01_smile',
  'e_02_joy',
  'e_03_heart_eyes',
  'e_04_sweat_smile',
  'e_05_laughing',
  'e_06_wink',
  'e_07_yum',
  'e_24_blush',
  'e_09_fearful',
  'e_10_ohyeah',
  'e_11_cold_sweat',
  'e_12_scream',
  'e_13_kissing_heart',
  'e_14_smirk',
  'e_15_angry',
  'e_16_sweat',
  'e_17_stuck',
  'e_18_rage',
  'e_19_etriumph',
  'e_20_mask',
  'e_27_flushed',
  'e_22_sunglasses',
  'e_23_sob',

  'e_08_relieved',
  'e_26_doubt',
  'e_21_confounded',
  'e_28_sleepy',
  'e_29_sleeping',
  'e_30_disappointed_relieved',
  'e_31_tire',
  'e_32_astonished',
  'e_33_buttonnose',
  'e_34_frowning',
  'e_35_shutup',
  'e_36_expressionless',
  'e_37_confused',
  'e_38_tired_face',
  'e_39_grin',
  'e_40_unamused',
  'e_41_persevere',
  'e_42_relaxed',
  'e_43_pensive',
  'e_44_no_mouth',
  'e_45_worried',
  'e_46_cry',
  'e_47_pill',

  'e_48_celebrate',
  'e_49_gift',
  'e_50_birthday',
  'e_51_pray',
  'e_52_ok_hand',
  'e_53_first',
  'e_54_v',
  'e_55_punch',
  'e_56_thumbsup',
  'e_57_thumbsdown',
  'e_58_muscle',
  'e_59_maleficeent',
  'e_60_broken_heart',
  'e_61_heart',
  'e_62_taxi',
  'e_63_eyes',
  'e_64_rose',
  'e_65_ghost',
  'e_66_lip',
  'e_67_fireworks',
  'e_68_balloon',
  'e_69_clasphands',
  'e_70_bye'
]

export const emojis = [
  '[微笑]',
  '[快乐]',
  '[色眯眯]',
  '[汗]',
  '[大笑]',
  '[眨眼]',
  '[百胜]',
  '[放松]',
  '[可怕]',
  '[欧耶]',
  '[冷汗]',
  '[尖叫]',
  '[亲亲]',
  '[得意]',
  '[害怕]',
  '[沮丧]',
  '[卡住]',
  '[愤怒]',
  '[生气]',
  '[面具]',
  '[羞愧]',
  '[太阳镜]',
  '[在]',

  '[脸红]',
  '[疑惑]',
  '[激动]',
  '[休息]',
  '[睡着]',
  '[失望]',
  '[累]',
  '[惊讶]',
  '[抠鼻]',
  '[皱眉头]',
  '[闭嘴]',
  '[面无表情]',
  '[困惑]',
  '[厌倦]',
  '[露齿而笑]',
  '[非娱乐]',
  '[坚持下去]',
  '[傻笑]',
  '[沉思]',
  '[无嘴]',
  '[担心]',
  '[哭]',
  '[药]',

  '[庆祝]',
  '[礼物]',
  '[生日]',
  '[祈祷]',
  '[好]',
  '[冠军]',
  '[耶]',
  '[拳头]',
  '[赞]',
  '[垃圾]',
  '[肌肉]',
  '[鼓励]',
  '[心碎]',
  '[心]',
  '[出租车]',
  '[眼睛]',
  '[玫瑰]',
  '[鬼]',
  '[嘴唇]',
  '[烟花]',
  '[气球]',
  '[握手]',
  '[抱拳]'
]

// 处理图片地址
const getAssetsFile = (url: string) => {
  return new URL(`../assets/emojis/${url}.png`, import.meta.url).href
}

// 转换 emoji 图片
export const emojiToIcon = (key: string) => {
  const iconIndex = emojis.indexOf(key)

  if (iconIndex !== -1) {
    return getAssetsFile(emojis_icon[iconIndex])
  }

  return key
}

/**
 * 把表情标记转换为emoji表情
 * @param content
 * @returns
 */
export const replaceEmojiMark = (content: string): string => {
  if (!content) return ''

  try {
    return content?.replace(/\[{1,2}(.*?)\]{1,2}/gi, (match, p1) => {
      return emojis.indexOf(match) !== -1
        ? `<img style="vertical-align: middle; width: 24px; height: 24px; display: inline;"  src="${emojiToIcon(match)}" alt="[${p1}]" />`
        : match
    })
  } catch (error) {
    return '未知消息'
  }
}

/**
 * code表情
 */
export const smileysEmojiUnicodes = [
  '\uD83D\uDE04', // [微笑] (smiling face with open mouth)
  '\uD83D\uDE00', // [快乐] (grinning face)
  '\uD83D\uDE1C', // [色眯眯] (winking face)
  '\uD83D\uDE33', // [汗] (sweat droplets)
  '\uD83D\uDE02', // [大笑] (face with tears of joy)
  '\uD83D\uDE09', // [眨眼] (winking face)
  '\uD83E\uDD28', // [百胜] (face with monocle)
  '\uD83D\uDE0C', // [放松] (relieved face)
  '\uD83D\uDE31', // [可怕] (fearful face)
  '\uD83C\uDF89', // [欧耶] (party popper)
  '\ud83d\ude05', // [冷汗] (cold sweat)
  '\uD83D\uDE20', // [尖叫] (angry face)
  '\uD83D\uDE18', // [亲亲] (kissing face)
  '\uD83D\uDE0F', // [得意] (smirking face)
  '\uD83D\uDE28', // [害怕] (fearful face)
  '\uD83D\uDE1E', // [沮丧] (disappointed face)
  '\uD83D\uDE11', // [卡住] (pensive face)
  '\uD83D\uDE24', // [愤怒] (face with symbols over mouth)
  '\uD83D\uDE21', // [生气] (angry face)
  '\uD83D\uDCA9', // [面具] (masked face)
  '\uD83D\uDE33', // [羞愧] (flushed face)
  '\ud83d\ude0e', // [太阳镜] (sunglasses face)
  '\uD83D\uDE4C', // [在] (person raising both hands)
  '\uD83D\uDE42', // [脸红] (slightly smiling face)
  '\uD83D\uDE15', // [疑惑] (confused face)
  '\uD83D\uDE0D', // [激动] (heart eyes)
  '\uD83D\uDE34', // [休息] (sleeping face)
  '\ud83d\udca4', // [睡着] (sleeping face)
  '\uD83D\uDE1F', // [失望] (disappointed face)
  '\uD83D\uDE2F', // [累] (weary face)
  '\uD83D\uDE32', // [惊讶] (astonished face)
  '\ud83d\ude2d', // [抠鼻] (face with symbols over mouth)
  '\uD83D\uDE12', // [皱眉头] (pensive face)
  '\ud83e\udd2b', // [闭嘴] (cold sweat)
  '\uD83D\uDE10', // [面无表情] (neutral face)
  '\uD83D\uDE15', // [困惑] (confused face)
  '\uD83D\uDE35', // [厌倦] (weary face)
  '\ud83e\udd23', // [露齿而笑] (grinning face with big eyes)
  '\uD83D\uDE2A', // [非娱乐] (sleeping face)
  '\uD83D\uDE1A', // [坚持下去] (face with steam from nose)
  '\uD83D\uDE02', // [傻笑] (face with tears of joy)
  '\uD83E\uDD14', // [沉思] (thinking face)
  '\uD83D\uDE44', // [无嘴] (expressionless face)
  '\uD83D\uDE29', // [担心] (worried face)
  '\uD83D\uDE22', // [哭] (crying face)
  '\uD83C\uDF79', // [药] (pill)
  '\uD83C\uDF89', // [庆祝] (party popper)
  '\uD83C\uDF81', // [礼物] (wrapped gift)
  '\uD83C\uDF82', // [生日] (birthday cake)
  '\uD83D\uDE4F', // [祈祷] (folded hands)
  '\uD83D\uDC4D', // [好] (thumbs up)
  '\uD83C\uDFC6', // [冠军] (trophy)
  '\uD83C\uDF89', // [耶] (party popper)
  '\uD83D\uDC4A', // [拳头] (fist bump)
  '\uD83D\uDC4D', // [赞] (thumbs up)
  '\uD83D\uDED1', // [垃圾] (wastebasket)
  '\ud83d\udcaa', // [肌肉] (flexed biceps)
  '\uD83D\uDC4C', // [鼓励] (thumbs up)
  '\uD83D\uDC94', // [心碎] (broken heart)
  '\uD83D\uDC96', // [心] (heart)
  '\uD83D\uDE8C', // [出租车] (taxi)
  '\uD83D\uDC40', // [眼睛] (eyes)
  '\uD83C\uDF39', // [玫瑰] (rose)
  '\uD83D\uDC7B', // [鬼] (ghost)
  '\uD83D\uDC44', // [嘴唇] (lips)
  '\uD83C\uDF8A', // [烟花] (fireworks)
  '\uD83C\uDF88', // [气球] (balloon)
  '\ud83e\udd1d' // [握手] (handshake)
]
