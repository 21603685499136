import { IContent } from '@renderer/api/chatList'
import { ChatListStatus } from '@renderer/api/model'
import { juggle } from '@renderer/lib/sdk/juggle'
import { useAssignedStore } from '@renderer/store/useAssignedStore'
import { useConnectStatus } from '@renderer/store/useConnectStatus'
import { useCurrentAndSearch } from '@renderer/store/useCurrentAndSearch'
import { useSingleChatStore } from '@renderer/store/useSingleChatStore'
import { useUserStore } from '@renderer/store/useUserStore'
import { find, findIndex, map, set } from 'lodash'
import { useCallback } from 'react'
import { logOutIm, timGetJoinedGroupList } from './im-sdk/ipcTimSdk'
import { fetchUserDetailInfo, RESPONSE_CODE } from '@brisk/api'
import { useUserRoleStore } from '@renderer/store/useUserRoleStore'
import { hiddenServiceChat } from '.'

interface MessageDataReq {
  conversationType?: string
  conversationId: string
  messageIds?: string[]
  time?: number
  count?: number
}

export interface ISendMessage {
  conversationType?: number //	会话类型	1.0.0
  conversationId?: string // 会话 Id，会话类型是 PRIVATE 时，会话 Id 是接收方的 userId，会话类型是 GROUP 时是群组 Id	1.0.0
  name?: string // 消息名称，根据实际需要发送不同消息类型，详细枚举请查看 MessageType	1.0.0
  content: IContent // 消息内容，构建 message.name 消息	1.0.0
  referMsg?: ISendMessage //引用回复消息，参数要求是完整的 Message	1.0.0
  mentionInfo?: {
    // conversationType 为 GROUP 时有效，设置 mentionInfo 表示本条消息是 @ 消息	1.0.0
    mentionType: number //	@ 类型，详细可查看 @ 消息枚举 说明	1.0.0
    members: string[] //	@ 指定人列表，SDK 会优先根据 @ 消息枚举 判断消息的 @ 类型
  }
  isSender: boolean
  messageId?: string
  tag?: string
  tid?: string
  sentTime?: number
  senderId: string
}

interface IJuggleMessage {
  getMessages: (params: MessageDataReq) => Promise<{
    index: number
    isFinished: boolean
    messages: IContent[]
  }>
  handleSendMsg: (msg, callBackBefore?: MessageCallbacksType) => Promise<void>
}

// 消息发送回调对象
export type MessageCallbacksType = {
  onbefore: (message: ISendMessage) => void
}

export const useJuggleMessage = (): IJuggleMessage => {
  const { connectStatus } = useConnectStatus()

  const getMessages = useCallback(
    async ({ conversationType, conversationId, messageIds, time, count }: MessageDataReq) => {
      if (!connectStatus) return

      const result = await juggle.getMessages({
        conversationType,
        conversationId,
        messageIds,
        time: time || 0,
        count: count || 100
      })
      return result
    },
    [connectStatus]
  )

  const handleSendMsg = async (
    msg: ISendMessage,
    callBackBefore?: MessageCallbacksType
  ): Promise<void> => {
    try {
      const message = await juggle.sendMessage(
        msg,
        callBackBefore || {
          onbefore: (beforeMsg) => {
            sendMessageThen(msg as unknown as IContent, 1)
            saveTalkingMsg({ ...msg, ...beforeMsg })
          }
        }
      )
      saveTalkingMsg({ ...msg, ...message })
      sendMessageThen(message as IContent, 2)
    } catch (error) {
      console.error('发送消息时出错:', error) // 增加错误日志
      sendMessageThen(msg as unknown as IContent, 3)
      saveTalkingMsg({ ...msg, sentState: 3 })
    }
  }

  return {
    getMessages,
    handleSendMsg
  }
}

export const messageHandler = (i, conversations): void => {
  const newList = set(useAssignedStore.getState().assignedList, i, {
    ...useAssignedStore.getState().assignedList[i],
    latestMessage: {
      ...useAssignedStore.getState().assignedList[i]?.latestMessage,
      ...(conversations[0]?.latestMessage || conversations[0])
    }
  })
  useAssignedStore.setState({ assignedList: newList })
}

/**
 *
 * @param i
 * @param conversations
 */
export const messageAllTabHandler = (i, conversations, sentState?: number): void => {
  const { finishedList } = useAssignedStore.getState() // 解构获取 finishedList

  // 获取要更新的项，并构建更新后的消息对象
  const updatedItem = {
    ...finishedList[i],
    latestMessage: {
      ...finishedList[i]?.latestMessage,
      ...(conversations[0]?.latestMessage || conversations[0]),
      sentState: sentState || 2
    }
  }

  // 创建新的 finishedList，将更新后的项放在第一位
  const newList = [updatedItem, ...finishedList.filter((_, index) => index !== i)]

  // 更新状态
  useAssignedStore.setState({ finishedList: newList })
}

/**
 * 会话新增
 * @param conversations
 */
export const handleConversations = (conversations): void => {
  const i = findIndex(
    useAssignedStore.getState().assignedList,
    (item) => item?.conversationId === conversations[0]?.conversationId
  )
  if (i < 0) {
    const list = [...conversations, ...useAssignedStore.getState().assignedList]
    useAssignedStore.setState({ assignedList: list })
  } else {
    messageHandler(i, conversations)
  }
}

/**
 * 本地设置消息已读回调
 * @param conversationId
 * @returns
 */
export const handleLocalReadMessage = (conversationId): void => {
  const i = findIndex(
    useAssignedStore.getState().assignedList,
    (item) => item?.conversationId === conversationId
  )
  if (i < 0) return
  const newList = set(useAssignedStore.getState().assignedList, i, {
    ...useAssignedStore.getState().assignedList[i],
    latestMessage: {
      ...useAssignedStore.getState().assignedList[i]?.latestMessage,
      isRead: true
    }
  })
  useAssignedStore.setState({ assignedList: newList })
  const chatMessageData = useSingleChatStore.getState().messageData
  const j = findIndex(chatMessageData, (item) => item?.conversationId === conversationId)
  if (j < 0) return
  const newChatListData = {
    ...chatMessageData[j],
    messages: map(chatMessageData[j].messages, (it) => ({ ...it, isRead: true }))
  }
  useSingleChatStore.getState().setMessageData(newChatListData)
}

/**
 * 消息发送成功回调
 * @param msg
 * @param sentState 发送状态
 */
export const sendMessageThen = (msg: IContent, sentState = 2): void => {
  const { assignedList, finishedList } = useAssignedStore.getState()
  const i = findIndex(assignedList, (item) => item?.conversationId === msg?.conversationId)

  if (i >= 0) {
    const data = {
      ...assignedList[i],
      lastTalkTime: msg?.sentTime,
      latestMessage: {
        ...msg,
        sentState
      }
    }
    // 排序到第一
    assignedList?.splice(i, 1)
    useAssignedStore.setState({ assignedList: [data, ...assignedList] })
  }

  if (useCurrentAndSearch.getState().currentTab === ChatListStatus.FINISHED) {
    const j = findIndex(finishedList, (item) => item?.conversationId === msg?.conversationId)
    if (j >= 0) messageAllTabHandler(j, [msg])
  }
}

/**
 * 保存聊天内容
 * @param message
 */
export const saveTalkingMsg = (message): void => {
  const store = useSingleChatStore.getState()
  const data = store.messageData
  const newData = find(data, (it) => it?.conversationId === message?.conversationId) || {
    messages: []
  }
  const messageList = newData?.messages || []
  const existingIds = new Set()
  const long = messageList.length > 5

  const messagesToProcess = long ? messageList.slice(-5) : messageList

  // 使用 Set 来跟踪现有消息的 tid 和 messageId
  messagesToProcess.forEach((it) => {
    existingIds.add(it?.tid)
  })

  // 检查 tid  是否存在
  const existingMessage = existingIds.has(message?.tid)
  if (existingMessage) {
    // 更新已有消息
    const index = messagesToProcess.findIndex((it) => it.tid === message.tid)
    if (index > -1) {
      const oldMsg = messagesToProcess[index]

      messagesToProcess[index] = { ...oldMsg, ...message } // 更新消息
    }
  } else {
    // 添加新消息
    messagesToProcess.push(message)
  }

  const res = long ? messageList.slice(0, messageList.length - 5) : []

  // 将处理后的消息与未处理的消息拼接起来
  const updatedMessageList = [
    ...res, // 保留之前的消息
    ...messagesToProcess // 添加处理后的消息
  ]

  store.setMessageData({ ...newData, messages: updatedMessageList })
}
/**
 * 消息发送参数
 * @param content
 * @returns
 */
export const sendMsgParams = (params): IContent => {
  return {
    ...params,
    sentState: 1,
    isRead: false,
    isSender: true,
    sentTime: Date.now()
  }
}

/**
 * 草稿内容回填
 * @param id
 */
export const setDraftContent = (id): string => {
  const tab = useCurrentAndSearch.getState().currentTab
  let content = ''
  if (tab === ChatListStatus.ASSIGNED) {
    content =
      find(useAssignedStore.getState().assignedList, (item) => item?.conversationId === id)
        ?.latestMessage?.draft || ''
  }
  if (tab === ChatListStatus.UNASSIGNED) {
    content =
      find(useAssignedStore.getState().unAssignedList, (item) => item?.conversationId === id)
        ?.latestMessage?.draft || ''
  }
  if (tab === ChatListStatus.FINISHED) {
    content =
      find(useAssignedStore.getState().finishedList, (item) => item?.conversationId === id)
        ?.latestMessage?.draft || ''
  }
  return content
}

interface IChangeDetail {
  conversationId: string | number
  conversationTitle?: string
  conversationTags?: any[]
}
/**
 * 修改用户信息后同步跟新会话列表
 * @param details
 */
export const asyncChangeUserDetail = (details: IChangeDetail): void => {
  const tab = useCurrentAndSearch.getState().currentTab
  if (tab === ChatListStatus.UNASSIGNED) {
    return
  }
  if (tab === ChatListStatus.ASSIGNED) {
    const i = findIndex(
      useAssignedStore.getState().assignedList,
      (item) => item?.conversationId === details?.conversationId
    )
    const newList = set(useAssignedStore.getState().assignedList, i, {
      ...useAssignedStore.getState().assignedList[i],
      ...details
    })
    useAssignedStore.setState({ assignedList: newList })
  }
  if (tab === ChatListStatus.FINISHED) {
    const i = findIndex(
      useAssignedStore.getState().finishedList,
      (item) => item?.conversationId === details?.conversationId
    )
    const newList = set(useAssignedStore.getState().finishedList, i, {
      ...useAssignedStore.getState().finishedList[i],
      ...details
    })
    useAssignedStore.setState({ finishedList: newList })
  }
}

export interface IReadMsg {
  conversationType: number
  conversationId: string
  messageId?: string
  unreadIndex?: number
  sentTime: number
}

/**
 * 设置消息已读
 * @param param
 */
export const setReadMsg = async (param: IReadMsg[]): Promise<void> => {
  try {
    const res = await juggle.readMessage(param)
    console.log('设置消息已读成功', res)
  } catch (error) {
    console.log('设置消息已读error', error)
  }
}

export const publicSingOut = async (data?: string) => {
  const token = data ? data : localStorage.getItem('im_token')
  logOutIm({ userData: token })
  useUserStore.getState().clear()
  useAssignedStore.getState().clearChatListInfo()
  useCurrentAndSearch.getState().clearCurrent()
  hiddenServiceChat()
}

/**
 * 更新某个会话未读消息数
 * @param conversationId
 * @param type
 */
export const updateConvUnreadCount = async (conversationId: string, type: 'add' | 'rest') => {
  const { assignedList, setAssignedList, finishedList, setFinishedList } =
    useAssignedStore.getState()
  const list = [...assignedList]
  const listFinished = [...finishedList]
  const index = findIndex(list, (item) => item.conversationId === conversationId)
  const indexFinished = findIndex(listFinished, (item) => item.conversationId === conversationId)
  if (index > -1) {
    if (type === 'add') {
      list[index].unreadCount += 1
    } else {
      list[index].unreadCount = 0
    }
    setAssignedList(list)
  }
  if (indexFinished > -1) {
    if (type === 'add') {
      listFinished[indexFinished].unreadCount += 1
    } else {
      listFinished[indexFinished].unreadCount = 0
    }
    setFinishedList(listFinished)
  }
}

/**
 * 用户角色变更
 */
export const userRoleChange = async (): Promise<void> => {
  const { user } = useUserStore.getState()
  fetchUserDetailInfo(user.userId)
    .then((res) => {
      if (res?.code === RESPONSE_CODE.success) {
        timGetJoinedGroupList()
        useUserRoleStore.getState().setRole(res.data)
        useAssignedStore.getState().setShouldUpdate()
      }
    })
    .catch((err) => {
      console.log('err>---->>', err)
    })
}

/**
 * 会话状态需要手动改变
 * @param convId
 * @param status
 */
export const handleConvStatusChange = (convId: string, status: number) => {
  const { itemData, setItemData } = useCurrentAndSearch.getState()
  if (itemData?.conversationId === convId) {
    setItemData({ ...itemData, status })
  }
}
