import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { ITeamResponse } from "./fetchTeamTree";

interface ApiResp extends ClientResponse {
  // TODO:
  data: ITeamResponse[];
}
interface Serialize {
  (resp: ApiResp): ApiResp;
}

const serialize: Serialize = (resp) => {
  // TODO: 处理data
  return resp;
};

// 获取团队结构(渠道客服配置)
export const fetchTeamTreeNew = async (parma: {
  channelId: string;
  sessionId: string;
}): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(
    `/user/team/tree/${parma.channelId}/${parma.sessionId}`,
  );

  return serialize(data);
};
