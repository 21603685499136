import { cn } from '@brisk/ui'
import Loading from '@renderer/components/Loading'
import { map, some } from 'lodash'
import { FC, memo } from 'react'

interface INavTitleProps {
  title: string[]
  loading?: boolean[]
  children?: React.ReactNode
  className?: string
}

const NavBodyLayout: FC<INavTitleProps> = ({ title, loading = [false], children, className }) => {
  const shouldShowLoading = some(loading, (value) => value)
  return (
    <div className="w-full h-full flex flex-col text-sm">
      {shouldShowLoading && <Loading />}
      <div className="w-full h-[52px] flex items-center shrink-0">
        {map(title, (item, index) => (
          <span
            key={item + index}
            className={index !== title?.length - 1 ? 'text-lingheTextGray' : ''}
          >
            {item}
            {index !== title?.length - 1 && <span className="pl-1 pr-2"> / </span>}
          </span>
        ))}
      </div>
      <div className={cn('flex-1 h-full bg-white rounded-md shrink-0 overflow-hidden', className)}>
        {children}
      </div>
    </div>
  )
}

export default memo(NavBodyLayout)
