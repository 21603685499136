import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  Textarea,
  cn
} from '@brisk/ui'
import React, { memo, useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'

interface UpdateTelegramRemarkProps<T> {
  data: T | null
  open: boolean
  onClose: () => void
  onConfirm: (remark: string) => void
}
const UpdateRemark = <T extends { remark?: string }>({
  data,
  open,
  onClose,
  onConfirm
}: UpdateTelegramRemarkProps<T>): React.ReactElement => {
  const [value, setValue] = useState<string>('')
  const { t } = useTranslation()

  useEffect(() => {
    setValue(data?.remark ?? '')
  }, [data])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogContent className="p-0 text-sm w-[520px] focus:outline-none">
        <div className="relative">
          <ReactSVG
            src={close}
            className="cursor-pointer absolute right-4 top-4 "
            onClick={(e) => {
              e.preventDefault()
              setValue('')
              onClose && onClose()
            }}
          />
          <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
            {t('chatFrame.添加备注')}
          </AlertDialogTitle>
          <AlertDialogDescription />
        </div>

        <div className="pl-6 pr-6 flex flex-col gap-2">
          <span> {t('chatFrame.备注信息')}</span>
          <Textarea
            placeholder={t('clientManagement.Pleaseenteracomment')}
            className={cn('min-h-[120px]')}
            value={value}
            required
            onChange={(e) => {
              const val = e?.target?.value
              if (val?.length >= 100) {
                message('超出最大字数限制', 'error')
                setValue(val.slice(0, 100))
                return
              }
              setValue(val)
            }}
            style={{
              resize: 'none'
            }}
          />
          <div className="relative h-0">
            <span className="text-lingheTextGray text-xs absolute right-4 bottom-3">
              {value?.length || 0}/100
            </span>
          </div>
        </div>
        <AlertDialogFooter
          style={{ flexDirection: 'row' }}
          className="flex items-center gap-2 border-t h-[54px] pr-3"
        >
          <Button
            variant="outline"
            onClick={(e) => {
              e.preventDefault()
              setValue('')
              onClose && onClose()
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={() => {
              if (!value) {
                message(t('clientManagement.Pleaseenteracomment'), 'error')
                return
              }
              onConfirm && onConfirm(value)
            }}
          >
            {t('chatFrame.oK')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(UpdateRemark)
