import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * ChannelUserVO
 */
export interface IChannelConfigUserListResponse {
  /**
   * 用户头像
   */
  avatarUrl?: string;
  /**
   * 渠道id
   */
  channelId?: string;
  /**
   * 坐席id
   */
  userId?: string;
  /**
   * 坐席Name
   */
  userName?: string;
}

interface ApiResp extends ClientResponse {
  data: IChannelConfigUserListResponse[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 获取已配置客服
 * @param channelId
 * @returns
 */
export const fetchChannelConfigUserList = async (
  channelId: string,
): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(
    `/bg/channel/${channelId}/user`,
  );

  return serialize(data);
};
