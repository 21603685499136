import { useCurrentAndSearch } from '@renderer/store/useCurrentAndSearch'
import { handleSetUserInfo, isElectron } from '.'
import { handleReportReadMsg } from './im-sdk/ipcTimSdk'
import { useUserStore } from '@renderer/store/useUserStore'
import { EmployeeStatus } from '@renderer/api/model'
import { mutationChangeStatus, RESPONSE_CODE } from '@brisk/api'
// import { clearUnreadCount } from './juggleHandles'

export const sendNotification = ({ title, body, icon, silent }) => {
  if (window.ipcRenderer) {
    window.ipcRenderer.send('notify', {
      title,
      body,
      icon,
      silent // 是否静音
    })
  }
}

export const showCustomNotification = (title, body, icon, conversationId) => {
  if (window.ipcRenderer)
    window.ipcRenderer.invoke('show-custom-notification', title, body, icon, conversationId)
}

/**
 * 未读消息应用角标红点
 */
export const setSysReadDot = (count: string) => {
  if (window.ipcRenderer && count) window.ipcRenderer.send('set-sys-read-dot', { count })
}

export const hideSysReadDot = () => {
  if (window.ipcRenderer) window.ipcRenderer.send('hide-sys-read-dot')
}

if (isElectron && window.ipcRenderer) {
  window.ipcRenderer.on('notification-clicked', (event, data) => {
    const { conversationId } = data
    if (conversationId) {
      useCurrentAndSearch.getState().setChatItemActive(conversationId)
    }
  })

  /** 清空未读会话数 */
  window.ipcRenderer.on('clear-unread-count', () => {
    // clearUnreadCount()
    handleSetUserInfo()
    const { chatItemActive } = useCurrentAndSearch.getState()
    if (chatItemActive && chatItemActive !== '-99') {
      handleReportReadMsg(chatItemActive)
    }
  })
}

/**
 * 检查并创建下载文件夹
 */
export const ensureDownloadFolder = () => {
  if (window.ipcRenderer) return window.ipcRenderer.invoke('ensure-download-folder')
}

/**
 * 检测文件是否存在
 * @param fileName
 * @returns
 */
export const checkFileExist = async (fileName: string, type: string) => {
  if (window.ipcRenderer) return await window.ipcRenderer.invoke('check-file-exist', fileName, type)
}

/**
 * 下载文件
 * @param url
 * @param fileName
 * @returns
 */
export const downloadFile = (url: string, fileName: string) => {
  if (window.ipcRenderer) return window.ipcRenderer.invoke('download-file', url, fileName)
}

/**
 * 打开文件
 * @param fileName
 * @returns
 */
export const openFileFolder = (fileName: string) => {
  if (window.ipcRenderer) return window.ipcRenderer.invoke('open-file-folder', fileName)
}

/**
 * 保存文件到指定的下载路径
 * @param fileBuffer
 * @param fileName
 * @returns
 */
export const saveFileToDownloadPath = (fileBuffer: Buffer, fileName: string) => {
  if (window.ipcRenderer)
    return window.ipcRenderer.invoke('save-file-to-download-path', fileBuffer, fileName)
}

/**
 * 退出应用前
 * @returns
 */
export const appWillQuit = () => {
  // if (window.ipcRenderer)
  //   return window.ipcRenderer.on('app-before-quit', async () => {
  //     const { user, setStatus } = useUserStore.getState()
  //     if (user?.status !== EmployeeStatus.OFFLINE) {
  //       const res = await mutationChangeStatus({ status: EmployeeStatus.OFFLINE })
  //       console.log('res>---->>', res)
  //       if (res?.code === RESPONSE_CODE.success) {
  //         setStatus(EmployeeStatus.OFFLINE)
  //       }
  //     }
  //   })
}
