import {
  ChannelTelegramVO,
  fetchChangeChannelConfig,
  fetchChannelConfigUserList,
  fetchUpdateTelegramRemark,
  RESPONSE_CODE,
  TeamUser
} from '@brisk/api'
import { FC, memo, useContext, useEffect, useState } from 'react'
import ChooseCustomerForScript from './ChooseCustomerForScript'
import { message } from '@renderer/components/message'
import CreateStepCom from './CreateStepCom'
import CreateChatTelegramForm from './CreateChatTelegramForm'
import { CreateChatTelegramContext } from '../ChannelManage/TelegramBot'
import { cn } from '@brisk/ui'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { map } from 'lodash'
import { ChannelManageContext } from '../ChannelManage'

export interface ICreateTelegramCom {
  data?: ChannelTelegramVO
  onClose: () => void
  onConfirm: () => void
}

const CreateTelegramCom: FC<ICreateTelegramCom> = ({ data, onClose, onConfirm }) => {
  const [firstRequest, setFirstRequest] = useState(true)
  const { t } = useTranslation()
  const {
    setTelegramDataForm,
    addGroupUserList,
    setAddGroupUserList,
    handleGetList,
    pageationRef,
    step,
    setStep
  } = useContext(CreateChatTelegramContext)
  const { setLoadding } = useContext(ChannelManageContext)

  const { mutateAsync: updateTelegramRemark } = useMutation({
    mutationFn: fetchUpdateTelegramRemark
  })

  const { mutateAsync } = useMutation({
    mutationFn: fetchChannelConfigUserList
  })

  const { mutateAsync: handleFetchChangeChannelConfig } = useMutation({
    mutationFn: fetchChangeChannelConfig
  })

  const handleSaveSuccess = () => {
    message(t('knowledgeBase.SaveSuccessful'), 'success')
    handleGetList({
      pageNum: pageationRef?.current?.currentPage || 1,
      pageSize: pageationRef?.current?.itemsPerPage || 10
    })
    onClose?.()
  }

  useEffect(() => {
    if (!data) return
    setTelegramDataForm({ token: data?.token, remark: data?.remark })
  }, [data])

  return (
    <div className="w-full h-full rounded-md border flex overflow-y-auto">
      {data ? (
        <>
          <div className="flex-1 shrink-0 flex flex-col">
            <div
              className={cn(
                'w-full h-[52px] shrink-0 border-b flex items-center gap-6 px-4 [&>span]:cursor-pointer [&>span]:relative',
                `[&>.active:after]:content-[""] [&>.active:after]:absolute [&>.active:after]:w-[50%] [&>.active:after]:h-[2px] [&>.active:after]:rounded-md
                 [&>.active:after]:bg-primary [&>.active:after]:left-[50%] [&>.active:after]:bottom-[-80%] [&>.active:after]:translate-x-[-50%]`
              )}
            >
              <span className={cn(step === 1 && 'text-primary active')} onClick={() => setStep(1)}>
                {t('chatFrame.编辑机器人')}
              </span>
              <span
                className={cn(step === 2 && 'text-primary active')}
                onClick={async () => {
                  try {
                    setStep(2)
                    if (!firstRequest) return
                    const res = await mutateAsync(data?.channelId)
                    if (res.code === RESPONSE_CODE.success) {
                      setFirstRequest(false)
                      setAddGroupUserList(map(res?.data, (item) => item?.userId) as TeamUser[])
                    }
                  } catch (error) {
                    console.log('error>---->>', error)
                  }
                }}
              >
                {t('chatFrame.选择接待客服')}
              </span>
            </div>

            {step === 1 && (
              <div className="w-[75%] flex-1 shrink-0 ">
                <CreateChatTelegramForm
                  type="edit"
                  onSubmit={async (dataForm) => {
                    try {
                      const res = await updateTelegramRemark({
                        channelId: data?.channelId,
                        remark: dataForm?.remark
                      })
                      if (res?.code === RESPONSE_CODE.success) {
                        handleSaveSuccess()
                      } else {
                        message(res?.msg, 'error')
                      }
                    } catch (error) {
                      console.log('error', error)
                    }
                  }}
                  onCancel={() => onClose?.()}
                />
              </div>
            )}
            {step === 2 && (
              <div className="flex-1 shrink-0  overflow-hidden">
                <ChooseCustomerForScript
                  type="edit"
                  addGroupUserList={addGroupUserList}
                  setAddGroupUserList={setAddGroupUserList}
                  onSubmit={async (useridsData) => {
                    try {
                      if (!useridsData?.length) {
                        message('请选择接待客服', 'info')
                        setLoadding(false)
                        return
                      }
                      // 编辑
                      const res = await handleFetchChangeChannelConfig({
                        channelId: data?.channelId,
                        userIds: useridsData as string[]
                      })
                      if (res?.code === RESPONSE_CODE.success) handleSaveSuccess()
                      setLoadding(false)
                    } catch (error) {
                      console.log('error', error)
                      setLoadding(false)
                    }
                  }}
                  onCancel={() => {
                    onClose?.()
                  }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {/* 新建 名称-备注 */}
          {step === 1 && (
            <div className="w-[75%] h-full shrink-0 flex flex-col">
              <div className="flex flex-col gap-1 p-4 border-b shrink-0">
                <div className="text-base">{t('chatFrame.添加机器人')}</div>
                <div className="text-xs text-lingheGray">
                  {t('chatFrame.请从 Telegram 内复制机器人令牌粘贴在下方')}
                </div>
              </div>

              <div className="w-[80%] flex-1 shrink-0">
                <CreateChatTelegramForm
                  type="create"
                  onSubmit={(data) => {
                    setTelegramDataForm(data)
                    setStep(2)
                  }}
                  onCancel={() => onClose?.()}
                />
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="w-[75%]  h-full shrink-0 flex flex-col">
              <div className="flex flex-col gap-1 p-4 border-b shrink-0">
                <div className="text-base">{t('chatFrame.选择接待客服')}</div>
                <div className="text-xs text-lingheGray">
                  {t('chatFrame.在这里您可以选择任意坐席来处理客户的消息')}
                  <span className="text-[#FF9533]">
                    {t('chatFrame.角色支持查看并回复所有渠道消息。')}
                  </span>
                </div>
              </div>

              <div className="flex-1 shrink-0 overflow-hidden">
                <ChooseCustomerForScript
                  type="add"
                  addGroupUserList={addGroupUserList}
                  setAddGroupUserList={setAddGroupUserList}
                  onSubmit={(data) => {
                    if (!data?.length) {
                      message('请选择接待客服', 'info')
                      setLoadding(false)
                      return
                    }
                    onConfirm?.()
                  }}
                  onCancel={() => {
                    setStep(1)
                  }}
                />
              </div>
            </div>
          )}
          <div className="flex-1 shrink-0 border-l flex flex-col pt-[33px] pl-[33px]">
            <CreateStepCom step={step} />
          </div>
        </>
      )}
    </div>
  )
}

export default memo(CreateTelegramCom)
