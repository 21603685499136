import { Button, cn, Input, Textarea } from '@brisk/ui'
import { message } from '@renderer/components/message'
import { FC, memo, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PromptComp from './PromptComp'
import { CreateChatScriptContext } from '../ChannelManage/ChatScript'

export interface IScriptForm {
  name: string
  remark?: string
}

const defaultValueForm: IScriptForm = {
  name: ''
}

const CreateChatScriptForm: FC<{
  type: 'create' | 'edit'
  defaultValue?: IScriptForm
  onSubmit: (data: IScriptForm) => void
  onCancel?: () => void
}> = ({ type, onSubmit, onCancel, defaultValue }) => {
  const [noSave, setNoSave] = useState(false)

  const { t } = useTranslation()
  const { createScriptData, setCreateScriptData } = useContext(CreateChatScriptContext)

  const { control, handleSubmit, reset, watch } = useForm<IScriptForm>({
    defaultValues: defaultValueForm
  })

  const nameChange = watch('name')
  const remarkChange = watch('remark')

  const submit = (data: IScriptForm) => {
    onSubmit?.(data)
  }

  useEffect(() => {
    reset(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (nameChange || remarkChange) setNoSave(true)
  }, [nameChange, remarkChange])

  return (
    <div className="w-full h-full">
      <PromptComp when={noSave} />
      <form className="w-full h-full flex flex-col" onSubmit={handleSubmit(submit)}>
        <div className="shrink-0 flex-1 p-4 w-full h-full flex flex-col gap-6 text-sm [&>.item>.itemBox]:w-full [&>.item>.itemBox]:flex [&>.item>.itemBox]:flex-col [&>.item>.itemBox]:gap-2">
          <div className="item">
            <Controller
              name="name"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <div className="itemBox">
                    <div className="flex items-center gap-1">
                      <span className="text-lingheError">*</span>
                      <span>{t('infoCollection.Name')}</span>
                    </div>
                    <div className="w-full relative">
                      <Input
                        className="h-9 rounded-md px-3"
                        placeholder={t('socialMediaAccount.Pleaseenter')}
                        maxLength={30}
                        value={value}
                        onChange={(e) => {
                          const val = e.target.value
                          setCreateScriptData({
                            ...createScriptData,
                            name: val
                          })
                          onChange(e.target.value)
                        }}
                      />
                      <span className="text-lingheGray absolute right-3 top-[50%] translate-y-[-50%]">
                        {value?.length || 0}/30
                      </span>
                    </div>
                  </div>
                )
              }}
            />
          </div>
          <div className="item">
            <Controller
              name="remark"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <div className="itemBox">
                    <span>{t('socialMediaAccount.Notes')}</span>
                    <Textarea
                      placeholder={t('socialMediaAccount.Pleaseenter')}
                      className={cn('min-h-[180px]')}
                      value={value}
                      onChange={(e) => {
                        const val = e?.target?.value
                        if (val?.length >= 800) {
                          message(t('clientManagement.Maximumwordlimitexceeded'), 'error')
                          onChange(val.slice(0, 800))
                          return
                        }
                        setCreateScriptData({
                          ...createScriptData,
                          remark: val
                        })
                        onChange(val)
                      }}
                      style={{
                        resize: 'none'
                      }}
                    />
                    <div className="relative h-0">
                      <span className="text-lingheGray absolute right-4 bottom-3">
                        {value?.length || 0}/800
                      </span>
                    </div>
                  </div>
                )
              }}
            />
          </div>
        </div>
        <div className="w-full h-[48px] shrink-0 border-t flex items-center gap-6 px-4">
          <Button variant="outline" onClick={onCancel}>
            {t('editInformation.cancel')}
          </Button>
          <Button type="submit">
            {type === 'create' ? t('managerBeginnerGuide.Next') : t('editInformation.save')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default memo(CreateChatScriptForm)
