import { FC, memo, useContext, useEffect, useState } from 'react'
import translate from '@renderer/assets/svg/translate.svg'
import { ReactSVG } from 'react-svg'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@brisk/ui'
import { Controller, useForm } from 'react-hook-form'
import translateDefaultAvatar from '@renderer/assets/svg/translateDefaultAvatar.svg'
import { ChevronUp } from 'lucide-react'
import LanguageComp from './LanguageComp'
import { useTranslation } from 'react-i18next'
import { MessageInfoContext } from '../../Home'
import { map } from 'lodash'
import { ChattingWindowContext } from '../ChattingWindow'
import { useMutation } from '@tanstack/react-query'
import {
  fetchCustomerTranslateSwitch,
  fetchSessionTranslateEngine,
  fetchSessionTranslateEngineFrom,
  fetchSessionTranslateEngineTo,
  fetchUserTranslateSwitch,
  RESPONSE_CODE
} from '@brisk/api'
import { message } from '@renderer/components/message'
import translateSelectedIcon from '@renderer/assets/svg/translateSelectedIcon.svg'
import { handleToTranslateWhenConfigChange } from '@renderer/utils/im-sdk/formateData'
import { useUserStore } from '@renderer/store/useUserStore'

interface ITranslateForm {
  translateEngine: string
  customerEnableStatus: boolean
  userEnableStatus: boolean
  userLanguageCode: string
  customerLanguageCode: string
}

const transLateItemClassName =
  ' bg-[#F6F7F9] text-lingheTextGray border rounded-md flex items-center h-full px-3 py-2 flex items-center'
const translateContentClassName = `w-[299px] max-h-[399px] overflow-y-auto flex flex-col gap-[1px] p-2
[&_.item]:flex [&_.item]:relative [&_.item]:items-center [&_.item]:cursor-pointer [&_.item]:w-full [&_.item]:h-8 [&_.item]:pl-3 [&_.item]:pr-3 
[&_.item]:rounded-md [&_.item]:shrink-0 [&_.item]:text-sm [&_.item:hover]:bg-lingheBtnHover`

const TranslateComp: FC<{
  onConvLineChange: () => void
}> = ({ onConvLineChange }) => {
  const [selectLineOpen, setSelectLineOpen] = useState(false)
  const [userTranslateOpenChange, setUserTranslateOpenChange] = useState(false)
  const [customerTranslateOpenChange, setCustomerTranslateOpenChange] = useState(false)

  const { sessionTranslateConfig, itemData, handleGetSessinTranslateConfig, setLoading } =
    useContext(MessageInfoContext)
  const { engineList, refetchLanguageList } = useContext(ChattingWindowContext)
  const { user } = useUserStore()
  const { t } = useTranslation()

  const { control, setValue } = useForm<ITranslateForm>({
    defaultValues: {
      translateEngine: '',
      customerEnableStatus: true,
      userEnableStatus: false,
      userLanguageCode: 'zh-CN',
      customerLanguageCode: 'en-US'
    }
  })

  const { mutateAsync: handleUserSwitch } = useMutation({
    mutationFn: fetchUserTranslateSwitch
  })

  const { mutateAsync: handleCustomerSwitch } = useMutation({
    mutationFn: fetchCustomerTranslateSwitch
  })

  /** 会话翻译路线 */
  const { mutateAsync: handleSessionTranslateEngine } = useMutation({
    mutationFn: fetchSessionTranslateEngine
  })

  /** 翻译路线 接收 */
  const { mutateAsync: handleSessionTranslateFrom } = useMutation({
    mutationFn: fetchSessionTranslateEngineFrom
  })

  /** 翻译路线 发送 */
  const { mutateAsync: handleSessionTranslateTo } = useMutation({
    mutationFn: fetchSessionTranslateEngineTo
  })

  useEffect(() => {
    if (!sessionTranslateConfig?.translateEngine) return
    const {
      translateEngine,
      customerEnableStatus,
      userEnableStatus,
      userLanguageCode,
      customerLanguageCode
    } = sessionTranslateConfig
    setValue('translateEngine', translateEngine)
    setValue('customerEnableStatus', Boolean(customerEnableStatus))
    setValue('userEnableStatus', Boolean(userEnableStatus))
    setValue('userLanguageCode', userLanguageCode)
    setValue('customerLanguageCode', customerLanguageCode)
  }, [sessionTranslateConfig])

  return (
    <div className="h-14 w-full border bg-white text-xs rounded-md flex items-center pt-[10px] pb-[10px]">
      <div className="h-full flex items-center pl-3 pr-3 flex-1">
        <form className="h-full w-full flex items-center">
          <div className={transLateItemClassName}>
            <Controller
              name="translateEngine"
              control={control}
              render={({ field }) => {
                return (
                  <div className="w-full flex items-center gap-[6px]">
                    <span className="whitespace-nowrap hidden xl:block ">
                      {t('knowledgeBase.translateLine')}
                    </span>
                    <Popover onOpenChange={(open) => setSelectLineOpen(open)}>
                      <PopoverTrigger asChild>
                        <span className=" cursor-pointer text-primary flex items-center gap-1 select-none truncate">
                          {field.value}
                          <ChevronUp
                            size={14}
                            className={cn(selectLineOpen ? 'rotate-0' : 'rotate-180')}
                          />
                        </span>
                      </PopoverTrigger>
                      <PopoverContent className={translateContentClassName}>
                        {map(engineList, (it) => {
                          return (
                            <div
                              key={it}
                              onClick={async () => {
                                if (it === field.value) return
                                setLoading(true)
                                try {
                                  const {
                                    customerLanguageCode,
                                    userLanguageCode,
                                    customerUniversalLanguage,
                                    userUniversalLanguage
                                  } = sessionTranslateConfig
                                  const res = await handleSessionTranslateEngine({
                                    sessionId: itemData.sessionId,
                                    data: {
                                      engine: it,
                                      customerLanguageCode,
                                      customerUniversalLanguage,
                                      userLanguageCode,
                                      userUniversalLanguage
                                    }
                                  })
                                  if (res?.code === RESPONSE_CODE.success) {
                                    await handleGetSessinTranslateConfig(itemData?.sessionId) // 更新配置
                                    // await refetchLanguageList() // 更新语言列表
                                    /** 会话内翻译配置改变 */
                                    handleToTranslateWhenConfigChange(itemData.conversationId)
                                    field.onChange(it)
                                    setLoading(false)
                                    onConvLineChange?.()
                                  } else {
                                    setLoading(false)
                                    message(res.msg, 'error')
                                  }
                                } catch (error) {
                                  setLoading(false)
                                }
                              }}
                              className="item"
                            >
                              {it}
                              {field.value === it && (
                                <ReactSVG
                                  src={translateSelectedIcon}
                                  className="absolute right-3 top-1/2 -translate-y-1/2"
                                />
                              )}
                            </div>
                          )
                        })}
                      </PopoverContent>
                    </Popover>
                  </div>
                )
              }}
            />
          </div>
          <div className="mx-[10px] h-full w-[1px] bg-[#ECECEC]   " />
          <div className="flex-1 flex items-center">
            <div className={cn(transLateItemClassName, 'flex-1 flex')}>
              <Controller
                name="customerEnableStatus"
                control={control}
                render={({ field }) => (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="flex items-center pr-3 h-full border-r   ">
                          <Switch
                            id="switch-user"
                            checked={field.value}
                            onCheckedChange={async (e) => {
                              setLoading(true)
                              try {
                                const res = await handleUserSwitch({
                                  sessionId: itemData?.sessionId,
                                  status: Number(e)
                                })
                                if (res?.code === RESPONSE_CODE.success) {
                                  await handleGetSessinTranslateConfig(itemData?.sessionId)
                                  /** 会话内翻译配置改变 */
                                  handleToTranslateWhenConfigChange(itemData.conversationId)
                                  field.onChange(e)
                                  setLoading(false)
                                } else {
                                  setLoading(false)
                                  message(res.msg, 'error')
                                }
                              } catch (error) {
                                setLoading(false)
                              }
                            }}
                          />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="bg-[rgba(0,0,0,0.6)] text-xs text-white p-0 pt-1 pb-1 pl-[6px] pr-[6px] rounded-md">
                        {t('systemSetting.接收消息自动翻译')}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
              />

              <div className=" flex items-center pl-3 gap-2">
                <Avatar className="h-5 w-5 flex   ">
                  <AvatarImage
                    alt="Avatar"
                    className="rounded-full w-full h-full"
                    src={itemData?.conversationPortrait}
                  />
                  <AvatarFallback>
                    <ReactSVG src={translateDefaultAvatar} />
                  </AvatarFallback>
                </Avatar>

                <span className="whitespace-nowrap hidden xl:block">
                  {t('knowledgeBase.translateTo')}
                </span>

                <Controller
                  name="customerLanguageCode"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Popover onOpenChange={(open) => setUserTranslateOpenChange(open)}>
                        <PopoverTrigger asChild>
                          <span className=" cursor-pointer text-primary flex items-center gap-1 select-none truncate">
                            {sessionTranslateConfig?.customerLanguage ||
                              t('infoCollection.PleaseSelect')}
                            <ChevronUp
                              size={14}
                              className={cn(userTranslateOpenChange ? 'rotate-0' : 'rotate-180')}
                            />
                          </span>
                        </PopoverTrigger>
                        <PopoverContent className={translateContentClassName}>
                          <LanguageComp
                            selected={field.value}
                            onSelect={async (it) => {
                              if (it.language === sessionTranslateConfig.customerLanguage) return
                              setLoading(true)
                              try {
                                const { universalLanguage, languageCode } = it
                                const { translateEngine, userLanguageCode, userUniversalLanguage } =
                                  sessionTranslateConfig
                                const res = await handleSessionTranslateFrom({
                                  sessionId: itemData.sessionId,
                                  data: {
                                    customerLanguageCode: languageCode,
                                    customerUniversalLanguage: universalLanguage,
                                    engine: translateEngine,
                                    userLanguageCode,
                                    userUniversalLanguage
                                  }
                                })
                                if (res?.code === RESPONSE_CODE.success) {
                                  await handleGetSessinTranslateConfig(itemData?.sessionId)
                                  /** 会话内翻译配置改变 */
                                  handleToTranslateWhenConfigChange(itemData.conversationId)
                                  setLoading(false)
                                } else {
                                  setLoading(false)
                                  message(res.msg, 'error')
                                }
                              } catch (error) {
                                setLoading(false)
                              }
                            }}
                          />
                        </PopoverContent>
                      </Popover>
                    )
                  }}
                />
              </div>
            </div>
            <div className="flex items-center justify-center pl-[22px] pr-[22px]">
              <ReactSVG src={translate} />
            </div>
            <div className={cn(transLateItemClassName, 'flex-1 flex')}>
              <Controller
                name="userEnableStatus"
                control={control}
                render={({ field }) => {
                  return (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="flex items-center pr-3 h-full border-r">
                            <Switch
                              id="switch-customer"
                              checked={field.value}
                              onCheckedChange={async (e) => {
                                setLoading(true)
                                try {
                                  const res = await handleCustomerSwitch({
                                    sessionId: itemData?.sessionId,
                                    status: Number(e)
                                  })
                                  if (res?.code === RESPONSE_CODE.success) {
                                    await handleGetSessinTranslateConfig(itemData.sessionId)
                                    /** 会话内翻译配置改变 */
                                    handleToTranslateWhenConfigChange(itemData.conversationId)
                                    field.onChange(e)
                                    setLoading(false)
                                  } else {
                                    setLoading(false)
                                    message(res.msg, 'error')
                                  }
                                } catch (error) {
                                  setLoading(false)
                                }
                              }}
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[rgba(0,0,0,0.6)] text-xs text-white p-0 pt-1 pb-1 pl-[6px] pr-[6px] rounded-md">
                          {t('systemSetting.发送消息自动翻译')}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )
                }}
              />

              <div className=" flex items-center pl-3 gap-2">
                <Avatar className="flex h-5 w-5   ">
                  <AvatarImage
                    alt="Avatar"
                    className="rounded-full w-full h-full"
                    src={user?.avatarUrl}
                  />
                  <AvatarFallback>
                    <ReactSVG src={translateDefaultAvatar} />
                  </AvatarFallback>
                </Avatar>

                <span className="whitespace-nowrap hidden xl:block  ">
                  {t('knowledgeBase.translateTo')}
                </span>

                <Controller
                  name="userLanguageCode"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Popover onOpenChange={(open) => setCustomerTranslateOpenChange(open)}>
                        <PopoverTrigger asChild>
                          <span className=" cursor-pointer text-primary flex items-center gap-1 select-none truncate">
                            {sessionTranslateConfig?.userLanguage ||
                              t('infoCollection.PleaseSelect')}
                            <ChevronUp
                              size={14}
                              className={cn(
                                customerTranslateOpenChange ? 'rotate-0' : 'rotate-180'
                              )}
                            />
                          </span>
                        </PopoverTrigger>
                        <PopoverContent className={translateContentClassName}>
                          <LanguageComp
                            selected={field.value}
                            onSelect={async (it) => {
                              if (it.language === sessionTranslateConfig.userLanguage) return
                              setLoading(true)
                              try {
                                const { universalLanguage, languageCode } = it
                                const {
                                  translateEngine,
                                  customerLanguageCode,
                                  customerUniversalLanguage
                                } = sessionTranslateConfig
                                const res = await handleSessionTranslateTo({
                                  sessionId: itemData.sessionId,
                                  data: {
                                    customerLanguageCode,
                                    customerUniversalLanguage,
                                    engine: translateEngine,
                                    userLanguageCode: languageCode,
                                    userUniversalLanguage: universalLanguage
                                  }
                                })
                                if (res?.code === RESPONSE_CODE.success) {
                                  await handleGetSessinTranslateConfig(itemData?.sessionId)
                                  /** 会话内翻译配置改变 */
                                  handleToTranslateWhenConfigChange(itemData.conversationId)
                                  setLoading(false)
                                } else {
                                  setLoading(false)
                                  message(res.msg, 'error')
                                }
                              } catch (error) {
                                setLoading(false)
                              }
                            }}
                          />
                        </PopoverContent>
                      </Popover>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default memo(TranslateComp)
