// 腾讯桌面端接收IM
import { listenerRevoke, timAddRecvNewMsg } from '../ipcTimSdk'
import { useConnectStatus } from '../../../store/useConnectStatus'
import { handleConversaionListener } from '../listenHandler'
import { useUnreadCountStore } from '@renderer/store/useUnreadCountStore'

const TencentElectron = () => {
  window.TIMAPI.TIMInit().then(() => {
    window.TIMCallbackPI.InitListeners((callback) => {
      const { data, type } = callback
      switch (type) {
        /**
         * 处理收到消息逻辑
         */
        case 'TIMAddRecvNewMsgCallback':
          try {
            timAddRecvNewMsg(data)
          } catch (error) {
            console.log('error>---->>', error)
          }
          break
        /**
         * 连接状态监听回调
         */
        case 'TIMSetNetworkStatusListenerCallback':
          useConnectStatus.getState().setConnectStatusCode(data)
          break
        /**
         * 会话改变
         */
        case 'TIMSetConvEventCallback':
          handleConversaionListener({ type: data?.type, data: data?.data })
          break
        /**
         * 未读数改变
         */
        case 'TIMSetConvTotalUnreadMessageCountChangedCallback':
          useUnreadCountStore.getState().setUnreadCount(data)
          break
        /**
         * 消息撤回
         */
        case 'TIMSetMsgRevokeCallback':
          console.log('监听：TIMSetMsgRevokeCallback====>', type, data)
          listenerRevoke(data[0]?.message_msg_id)
          break
        case 'TIMSetMsgReadedReceiptCallback':
          console.log('监听：TIMSetMsgReadedReceiptCallback====>', type, data)
          break
        /**
         * 群组系统消息回调
         */
        case 'TIMSetGroupTipsEventCallback':
          console.log('监听：TIMSetGroupTipsEventCallback====>', type, data)
          break
        /**
         * 元素上传进度回调
         */
        case 'TIMSetMsgElemUploadProgressCallback':
          console.log('监听：TIMSetMsgElemUploadProgressCallback====>', type, data)
          break
        /**
         * 被挤下线
         */
        case 'TIMSetKickedOfflineCallback':
          console.log('监听：TIMSetKickedOfflineCallback====>', type, data)
          break
        /**
         * 收到音视频邀请
         */
        case 'TIMOnInvited':
          console.log('监听：TIMOnInvited====>', type, data)
          break
        /**
         * 自己的邀请被拒绝
         */
        case 'TIMOnRejected':
          console.log('监听：TIMOnRejected====>', type, data)
          break
        /**
         * 自己的邀请被接收
         */
        case 'TIMOnAccepted':
          console.log('监听：TIMOnAccepted====>', type, data)
          break
        /**
         * 收到的音视频邀请被取消
         */
        case 'TIMOnCancelled':
          console.log('监听：TIMOnCancelled====>', type, data)
          break
        /**
         * 收到的音视频邀请已經超时
         */
        case 'TIMOnTimeout':
          console.log('监听：TIMOnTimeout====>', type, data)
          break
        case 'TIMSetFriendAddRequestCallback':
          console.log('监听：TIMSetFriendAddRequestCallback====>', type, data)
          break
      }
    })
  })
}
export { TencentElectron }
