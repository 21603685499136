import { Button, cn } from '@brisk/ui'
import creatChatScriptIcon from '@renderer/assets/svg/creatChatScriptIcon.svg'
import { createContext, FC, memo, useEffect, useState } from 'react'
import FacebookHomePage from './FacebookHomePage'
import {
  FaceBookAccountBusinessVO,
  FaceBookHomeBusinessVO,
  fetchFacebookOauth,
  TeamUser
} from '@renderer/api'
import FacebookAccount from './FacebookAccount'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { getQueryParam } from '@brisk/utils'
import { message } from '@renderer/components/message'
import { ReactSVG } from 'react-svg'
import createChatScriptLeftIcon from '@renderer/assets/svg/createChatScriptLeftIcon.svg'

interface IFbUpdateData {
  data: FaceBookHomeBusinessVO | null
  open: boolean
}

export const CreateChatFbContext = createContext<{
  addGroupUserList: TeamUser[]
  setAddGroupUserList: (arr: TeamUser[]) => void
  step: number
  setStep: (step: number) => void
  updateData: IFbUpdateData
  setUpdateData: (data: IFbUpdateData) => void
}>({
  addGroupUserList: [],
  setAddGroupUserList: () => {},
  step: 1,
  setStep: () => {},
  updateData: {
    open: false,
    data: null
  },
  setUpdateData: () => {}
})

const Messenger: FC = () => {
  const [active, setActive] = useState(0)
  const [rowSelectHome, setRowSelectHome] = useState<FaceBookHomeBusinessVO[]>([])
  const [rowSelectAccount, setRowSelectAccount] = useState<FaceBookAccountBusinessVO[]>([])
  const [showHomeDel, setShowHomeDel] = useState(false)
  const [showAccountDel, setShowAccountDel] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [step, setStep] = useState(1)
  // fb主页编辑
  const [updateData, setUpdateData] = useState<IFbUpdateData>({
    open: false,
    data: null
  })

  /** 创建插件选择的接待客服 */
  const [addGroupUserList, setAddGroupUserList] = useState<TeamUser[]>([])

  const { t } = useTranslation()

  const { data: oauthUrlData } = useQuery({
    queryKey: [fetchFacebookOauth.name],
    queryFn: async () => {
      const res = await fetchFacebookOauth()
      return res
    }
  })

  useEffect(() => {
    const errorMsg = getQueryParam('error')
    let timer

    if (errorMsg) {
      timer = setTimeout(() => {
        message(decodeURIComponent(errorMsg), 'error')
      }, 0)
    }

    return () => clearTimeout(timer)
  }, [])

  return (
    <CreateChatFbContext.Provider
      value={{
        addGroupUserList,
        setAddGroupUserList,
        step,
        setStep,
        updateData,
        setUpdateData
      }}
    >
      <div className="w-full h-full overflow-hidden flex flex-col text-sm">
        {!showEdit && (
          <div
            className="h-[54px] shrink-0 border-b pl-3 pr-3 flex items-center gap-4 text-base [&_span]:cursor-pointer [&_span]:relative  [&_.active]:text-primary [&_.active]:after:block [&_.active]:after:conten-[''] [&_.active]:after:
      [&_.active]:after:absolute [&_.active]:after:h-1 [&_.active]:after:w-full [&_.active]:after:bg-primary [&_.active]:after:rounded-md [&_.active]:after:left-[50%] [&_.active]:after:-translate-x-1/2 
      [&_.active]:after:-bottom-4"
          >
            <span
              className={cn(active === 0 ? 'active' : '')}
              onClick={() => {
                if (active === 0) return
                setActive(0)
                setRowSelectAccount([])
              }}
            >
              {t('socialMediaAccount.FacebookPage')}
            </span>
            <span
              className={cn(active === 1 ? 'active' : '')}
              onClick={() => {
                if (active === 1) return
                setActive(1)
                setRowSelectHome([])
              }}
            >
              {t('socialMediaAccount.FacebookAccount')}
            </span>
          </div>
        )}

        {!showEdit && (
          <div className="h-16 shrink-0 flex items-center justify-between [&_button]:h-8">
            <div className="w-full p-3">
              <div
                className="h-[56px] pl-3 pr-3 w-full shrink-0 flex items-center gap-4 [&_button]:h-8 bg-[#F6F7F9] rounded-md border
               [&_button]:flex [&_button]:items-center [&_button]:justify-center [&_button]:gap-[10px]"
              >
                <Button onClick={() => window.open(oauthUrlData?.data, '__blank')}>
                  <ReactSVG src={creatChatScriptIcon} />
                  {t('socialMediaAccount.AuthorizeFBAccount')}
                </Button>
                {/* <Button variant="link" className="hover:no-underline p-0">
            如何授权Facebook账号？
          </Button> */}

                <Button
                  className="text-lingheError"
                  variant="outline"
                  disabled={active === 0 ? !rowSelectHome?.length : !rowSelectAccount?.length}
                  onClick={() => (active === 0 ? setShowHomeDel(true) : setShowAccountDel(true))}
                >
                  {t('socialMediaAccount.Delete')}
                </Button>
              </div>
            </div>
          </div>
        )}

        {showEdit && (
          <div className="h-16 shrink-0 flex items-center justify-between ">
            <div className="w-full p-3 pt-6 ">
              <div className="h-[56px]  bg-[#F6F7F9] pl-3 pr-3 w-full shrink-0 flex items-center gap-4 rounded-md border">
                <span className="flex items-center gap-2 font-semibold text-base">
                  <ReactSVG
                    className="cursor-pointer"
                    src={createChatScriptLeftIcon}
                    onClick={() => {
                      if (step > 1) setStep(step - 1)
                      else {
                        setShowEdit(false)
                        setUpdateData({ open: false, data: null })
                      }
                    }}
                  />
                  {t('chatFrame.编辑FB信息')}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="flex-1 w-full overflow-hidden shrink-0">
          {active === 0 ? (
            <FacebookHomePage
              rowSelect={rowSelectHome}
              setRowSelect={(rows) => setRowSelectHome(rows)}
              show={showHomeDel}
              setShow={(e) => setShowHomeDel(e)}
              oauthUrl={oauthUrlData?.data}
              onEdit={(bool) => setShowEdit(bool)}
            />
          ) : (
            <FacebookAccount
              rowSelect={rowSelectAccount}
              setRowSelect={(rows) => setRowSelectAccount(rows)}
              show={showAccountDel}
              setShow={(e) => setShowAccountDel(e)}
              oauthUrl={oauthUrlData?.data}
            />
          )}
        </div>
      </div>
    </CreateChatFbContext.Provider>
  )
}

export default memo(Messenger)
