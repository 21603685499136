import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * ChannelUserChoiceSaveDTO
 */
export interface IChangeChannelConfigRequest {
  /**
   * 渠道id
   */
  channelId: string;
  /**
   * 选中坐席ids
   */
  userIds: string[];
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 客服配置
 * @param param
 * @returns
 */
export const fetchChangeChannelConfig = async (
  param: IChangeChannelConfigRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(`/bg/channel/user`, param);

  return serialize(data);
};
