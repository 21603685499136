import { fetchIMToken, fetchSessionListByGroupId, IContent, RESPONSE_CODE } from '@brisk/api'
import { useUserStore } from '@renderer/store/useUserStore'
import {
  convGetConvInfo,
  convGetTotalUnreadMessageCount,
  getConvList,
  logoutParam,
  MsgGetMsgListParams,
  MsgModifyMessageParams,
  MsgReportReadedParams,
  MsgSendReplyMessage
} from 'im_electron_sdk/dist/interfaces'
import {
  formatConversationList,
  formatHistoryMsgList,
  formatListInfomation,
  formatSendArrayMessage,
  formatSendFileMessage,
  // formatSender,
  formatSendMessage,
  formatSendMessageTranslate
} from './formateData'
import { useAssignedStore } from '@renderer/store/useAssignedStore'
import { debounce, findIndex, forEach, map } from 'lodash'
import {
  handleConvStatusChange,
  messageAllTabHandler,
  messageHandler,
  publicSingOut,
  saveTalkingMsg,
  sendMessageThen,
  updateConvUnreadCount,
  userRoleChange
} from '../juggleHandles'
import { v4 } from 'uuid'
import { TIMConvType } from 'im_electron_sdk/dist/enumbers'
import { TencentElectron } from './platforms/TencentElectron'
import { showCustomNotification } from '../ipcHandler'
import { isJsonString, MESSAGE_TYPE, SEND_ROLE } from '@brisk/utils'
import { message } from '../../components/message'
import { useCurrentAndSearch } from '@renderer/store/useCurrentAndSearch'
import { ChatListStatus, EmployeeStatus } from '@renderer/api/model'
import { message as tipsMessage } from '@renderer/components/message'
import { useUnreadCountStore } from '@renderer/store/useUnreadCountStore'
import { decryptMessage } from './secretMessage'
import { useSingleChatStore } from '@renderer/store/useSingleChatStore'
import { useUploadingFileMsgStore } from '@renderer/store/useUploadingFileMsgStore'
import { useSessionTranslateConfigStore } from '@renderer/store/useSessionTranslateConfigStore'

/** 状态码 */
export const TIMCODEENUM = {
  SUCCESS: 0
}

/**
 * 初始化接收连接，通过初始化initSdk(name, platformType)获取平台分类
 * 默认是腾讯SDK  electron
 * parameterName 第三方厂商    parameterType平台
 */
export const initSdk = (parameterName?: string, parameterType?: string) => {
  if (!window.TIMAPI || !window.TIMCallbackPI) return
  const name = !parameterName ? 'Tencent' : parameterName // 判断是否默认厂商
  const platformType = !parameterType ? 'electron' : parameterType // 判断是否默认平台
  const plat = {
    Tencent: {
      electron: () => TencentElectron()
    }
  }
  plat[name][platformType]()
  timLogin()
}

/**
 * 登录
 */
export const timLogin = debounce(async () => {
  if (!window.TIMAPI) return
  const data = await fetchIMToken()
  const token = data.data.IMToken
  if (!token) return
  const user = useUserStore.getState().user!
  localStorage.setItem('im_token', token)

  const res = await window.TIMAPI.TIMLogin({
    userID: String(user?.userId),
    userSig: token
  })
  console.log('TIM 登录==>', res)
  if (res.code === TIMCODEENUM.SUCCESS) {
    timGetJoinedGroupList(token)
    getUnreadCount({ user_data: token })
  }
}, 200)
/**
 * 收到消息
 */
export const timAddRecvNewMsg = (data) => {
  const { user, setStatus } = useUserStore.getState()
  // if (user.status === EmployeeStatus.OFFLINE) return
  const message = data[0]
  const customData = message.message_elem_array[0].custom_elem_data
  const id = message?.message_conv_id || ''

  // 会话转入
  if (message.message_elem_array[0].elem_type === 8) {
    timGetJoinedGroupList()
    return
  }

  if (!customData) return

  // 处理个人会话私聊--未加密
  if (message?.message_conv_type === TIMConvType.kTIMConv_C2C) {
    handleC2CMessage(customData, user)
    return
  }

  const decryptMsg = getDecryptedMessage(customData)
  if (!decryptMsg) {
    timGetJoinedGroupList()
    return
  }

  const elemData = parseElemData(decryptMsg)
  if (!elemData) {
    timGetJoinedGroupList()
    return
  }

  const { noticeType, messageType, content } = elemData
  // 处理会话开启/删除和在线状态同步
  handleSessionNotices(messageType, noticeType, content, id, user, setStatus)

  // 处理系统通知
  if (handleSystemNotifications(messageType, noticeType, content, user)) return

  // 通知逻辑
  if (
    user.status === EmployeeStatus.ONLINE &&
    messageType !== MESSAGE_TYPE.CUSTOMIZE_SESSION_NOTICE &&
    noticeType !== MESSAGE_TYPE.SQUEEZE_OUT_OF_LINE
  ) {
    showNotification(message, elemData, id)
  }

  updateConversation(id, data)
}

const handleC2CMessage = (customData, user) => {
  const elemData = JSON.parse(customData)
  const { noticeType, messageType, content } = elemData
  if (noticeType === MESSAGE_TYPE.USER_STATUS_SWITCH) {
    const { status } = content || {}
    useUserStore.getState().setStatus(status)
    if (status === EmployeeStatus.ONLINE) {
      timGetJoinedGroupList()
    }
  }
  // if (noticeType === MESSAGE_TYPE.SESSION_NOTICE_OUT) {
  //   useCurrentAndSearch.getState().setItemData(null)
  // }
  if (noticeType === MESSAGE_TYPE.USER_ROLE_CHANGE) {
    useAssignedStore.getState().setShouldUpdate()
    userRoleChange()
  }
  // 处理系统通知
  if (handleSystemNotifications(messageType, noticeType, content, user)) return
}

const getDecryptedMessage = (customData) => {
  const key = useSingleChatStore.getState()?.secretKey
  if (isJsonString(customData)) {
    return customData
  } else {
    return key ? decryptMessage(customData, key) : customData
  }
}

const parseElemData = (decryptMsg) => {
  try {
    return JSON.parse(decryptMsg)
  } catch (error) {
    return null
  }
}

const handleSessionNotices = (messageType, noticeType, content, id, user, setStatus) => {
  try {
    if (
      messageType === MESSAGE_TYPE.CUSTOMIZE_SESSION_NOTICE &&
      [
        MESSAGE_TYPE.SESSION_USER_RESTART,
        MESSAGE_TYPE.SESSION_USER_COMPLETE,
        MESSAGE_TYPE.SESSION_NOTICE_IN,
        MESSAGE_TYPE.USER_PROACTIVELY_RECEIVE,
        MESSAGE_TYPE.USER_ALLOCATION
      ].includes(noticeType)
    ) {
      useAssignedStore.getState().setShouldUpdate()
      timGetJoinedGroupList()
      handleConvStatusChange(id, ChatListStatus.ASSIGNED)
    }

    if (id === '-1' && noticeType === MESSAGE_TYPE.USER_STATUS_SWITCH) {
      const { status } = content || {}
      setStatus(status)
      if (status === EmployeeStatus.ONLINE) {
        timGetJoinedGroupList()
      }
    }
  } catch (error) {
    console.log('error>---->>', error)
  }
}

const handleSystemNotifications = (messageType, noticeType, content, user) => {
  if (
    messageType === MESSAGE_TYPE.CUSTOMIZE_SYSTEM_NOTICE &&
    noticeType === MESSAGE_TYPE.SQUEEZE_OUT_OF_LINE &&
    content === user?.token
  ) {
    tipsMessage('登录被挤下线，请重新登录')
    publicSingOut()
    return true
  }
  return false
}

const showNotification = (message, elemData, id) => {
  const lg = localStorage.getItem('i18nextLng') || 'en'
  const imageNotify = lg.toLowerCase().indexOf('en') !== -1 ? '[Image]' : '[图片]'
  const notifyContent = (() => {
    if (elemData.messageType === MESSAGE_TYPE.GENERIC_IMAGE) return imageNotify
    else if (elemData.messageType === MESSAGE_TYPE.GENERIC_FILE) return elemData.name
    else return elemData.content
  })()

  showCustomNotification(
    message.message_sender_profile.user_profile_nick_name,
    notifyContent,
    message.message_sender_profile.user_profile_face_url,
    id
  )
}

const updateConversation = (id, data) => {
  if (id !== '-1') updateConvUnreadCount(id, 'add')
  const messageData = formatHistoryMsgList(data)
  sendMessageThen(messageData[0])
  saveTalkingMsg(messageData[0])

  const { assignedList, finishedList } = useAssignedStore.getState()
  const i = findIndex(assignedList, (item) => item?.conversationId === id)
  if (i >= 0) messageHandler(i, [messageData[0]])

  // 停留在【所有的】同步更新会话列表栏
  if (useCurrentAndSearch.getState().currentTab === ChatListStatus.FINISHED) {
    const j = findIndex(finishedList, (item) => item?.conversationId === id)
    if (j >= 0) messageAllTabHandler(j, [messageData[0]])
  }
}

/**
 * 获取最近联系人的会话列表
 */
export const timGetConvList = async (param?: getConvList) => {
  if (!window.TIMAPI) return
  const res = await window.TIMAPI.TIMGetConvList(param)
  console.log('TIM 获取会话列表==>', res)
}

/**
 * 获取已加入群组列表
 * @param data
 * @param retries 重试次数
 * @param count 当前调用次数，初始值为 0
 * 会话不同步问题 可能有延迟 先默认调用3次 没有会话时调用5次
 * @returns
 */
export const timGetJoinedGroupList = async (data?: string, retries = 5, count = 0) => {
  if (!window.TIMAPI) return
  const token = data ? data : localStorage.getItem('im_token')
  if (!token) return

  const res = await window.TIMAPI.TIMGetJoinedGroupList(data)

  if (res.code === TIMCODEENUM.SUCCESS) {
    const ids = map(res.json_param, (item) => item?.group_detail_info_group_id)

    if (ids.length > 0 && count >= 3) {
      // 正常获取到群组ID列表时
      const listData = await fetchSessionListByGroupId({ groupIdList: ids })
      if (listData.code === RESPONSE_CODE.success) {
        const formatList = formatConversationList(listData.data)
        const formatConvList = await formatListInfomation(formatList)
        useAssignedStore.getState().setAssignedList(formatConvList)
      }
    } else if (retries > 0 || count < 3) {
      // 未获取到群组列表且满足重试条件或调用次数未达到3次
      setTimeout(() => {
        timGetJoinedGroupList(token, retries > 0 ? retries - 1 : 0, count + 1) // 递归调用，更新count
      }, 200)
    } else {
      // 重试次数和调用次数耗尽，将 `setAssignedList` 更新为空数组
      useAssignedStore.getState().setAssignedList([])
    }
  }
}

/**
 * 发送翻译消息-单条
 * @param param
 */
export const sendTranslateMsg = async (msg: IContent, translate: string) => {
  const { userLanguageCode: toLanguageCode, translateEngine } =
    useSessionTranslateConfigStore.getState().sessionTranslateConfig
  const tid = v4()
  const param = { ...msg, tid }
  // 更新view
  sendMessageThen(param, 1)
  saveTalkingMsg({ ...param, sentState: 1, translateState: 2 })
  try {
    const translateData = {
      sendContent: translate,
      senderRole: SEND_ROLE.USER,
      translateContent: {
        [translateEngine]: {
          [toLanguageCode]: translate
        }
      },
      translateRes: translate // 展示的翻译结果
    }
    const sendTranslateData = { ...param, content: { ...param.content, ...translateData } }
    const callback = (res) => {
      try {
        const data = res[0]
        if (data.code === TIMCODEENUM.SUCCESS) {
          updateConvUnreadCount(sendTranslateData.conversationId, 'rest')
          // 发送成功回调
          saveTalkingMsg({ ...sendTranslateData, sentState: 2, translateState: 2 })
          sendMessageThen(sendTranslateData, 2)
        } else {
          // 发送失败回调
          sendMessageThen(param, 3)
          saveTalkingMsg({ ...param, sentState: 3, translateState: 3 })
        }
      } catch (error) {
        console.log('error', error)
        sendMessageThen(param, 3)
        saveTalkingMsg({ ...param, sentState: 3, translateState: 3 })
      }
    }

    // 格式化发送数据
    const sendParams = { ...formatSendMessageTranslate(sendTranslateData), callback }
    const res = await window.TIMAPI.TIMSendMsg(sendParams)
    saveTalkingMsg({ ...sendTranslateData, sentState: 2, timMsgId: res })
    console.log(`TIM 发送${'翻译text'}消息==>`, res)
  } catch (error) {
    sendMessageThen(param, 3)
    saveTalkingMsg({ ...param, sentState: 3, translateState: 3 })
  }
}

/**
 * 发消息调用这个方法
 * type: image |
 */
export const sendMsg = async (param: IContent, type?: string) => {
  if (!window.TIMAPI) return
  const tid = v4()
  const msg = { ...param, tid }
  // 更新view
  sendMessageThen(msg, 1)
  saveTalkingMsg({ ...msg, sentState: 1 })
  const callback = (res) => {
    try {
      const data = res[0]
      if (data.code === TIMCODEENUM.SUCCESS) {
        updateConvUnreadCount(param.conversationId, 'rest')
        // 发送成功回调
        saveTalkingMsg({ ...msg, sentState: 2 })
        sendMessageThen(msg, 2)
      } else {
        // 发送失败回调
        sendMessageThen(msg, 3)
        saveTalkingMsg({ ...msg, sentState: 3 })
      }
    } catch (error) {
      console.log('error', error)
      sendMessageThen(msg, 3)
      saveTalkingMsg({ ...msg, sentState: 3 })
    }
  }

  // 格式化发送数据
  const sendParams = { ...formatSendMessage(msg, type), callback }
  const res = await window.TIMAPI.TIMSendMsg(sendParams)
  saveTalkingMsg({ ...msg, sentState: 2, timMsgId: res })
  console.log(`TIM 发送${type || 'text'}消息==>`, res)
}

/**
 * 发送文件消息
 * @param param
 * @returns
 */
export const sendFileMsg = async (param: IContent) => {
  if (!window.TIMAPI) return
  const callback = (res) => {
    const { setUploadingFileMsgs } = useUploadingFileMsgStore.getState()

    try {
      setUploadingFileMsgs(param.conversationId, param, 'del')
      const data = res[0]
      if (data.code === TIMCODEENUM.SUCCESS) {
        updateConvUnreadCount(param.conversationId, 'rest')
        // 发送成功回调
        saveTalkingMsg({ ...param, sentState: 2, downloadState: 2 })
        sendMessageThen(param, 2)
      } else {
        // 发送失败回调
        sendMessageThen(param, 3)
        saveTalkingMsg({ ...param, sentState: 3 })
      }
    } catch (error) {
      console.log('error', error)
      sendMessageThen(param, 3)
      saveTalkingMsg({ ...param, sentState: 3 })
      setUploadingFileMsgs(param.conversationId, param, 'del')
    }
  }
  // 格式化发送数据
  const sendParams = { ...formatSendFileMessage(param), callback }
  const res = await window.TIMAPI.TIMSendMsg(sendParams)
  saveTalkingMsg({ ...param, sentState: 2, timMsgId: res })
  console.log(`TIM 发送文件消息==>`, res)
}

/**
 * 发送多条消息
 * @param params
 * @returns
 */
export const sendArrayMsg = async (
  params: Array<
    IContent & {
      tid: string
      type?: string
    }
  >
) => {
  if (!window.TIMAPI) return
  // 更新view
  forEach(params, (item) => {
    sendMessageThen(item, 1)
    saveTalkingMsg({ ...item, sentState: 1 })
  })
  const callback = (res) => {
    try {
      const data = res[0]
      if (data.code === TIMCODEENUM.SUCCESS) {
        updateConvUnreadCount(params[0].conversationId, 'rest')

        forEach(params, (item) => {
          sendMessageThen(item, 2)
          saveTalkingMsg({ ...item, sentState: 2 })
        })
      } else {
        forEach(params, (item) => {
          sendMessageThen(item, 3)
          saveTalkingMsg({ ...item, sentState: 3 })
        })
      }
    } catch (error) {
      console.log('error', error)
      forEach(params, (item) => {
        sendMessageThen(item, 3)
        saveTalkingMsg({ ...item, sentState: 3 })
      })
    }
  }
  // 格式化发送数据
  const sendParams = { ...formatSendArrayMessage(params), callback }
  const res = await window.TIMAPI.TIMSendMsg(sendParams)
  forEach(params, (item) => {
    saveTalkingMsg({ ...item, sentState: 2, timMsgId: res })
  })
}

export const sendImageMsg = async (param: IContent, type?: string) => {
  if (!window.TIMAPI) return
  const callback = (res) => {
    try {
      const data = res[0]
      if (data.code === TIMCODEENUM.SUCCESS) {
        // 发送成功回调
        console.info(param)
        saveTalkingMsg({ ...param, sentState: 2 })
        sendMessageThen(param, 2)
      } else {
        // 发送失败回调
        sendMessageThen(param, 3)
        saveTalkingMsg({ ...param, sentState: 3 })
      }
    } catch (error) {
      console.log('error', error)
      sendMessageThen(param, 3)
      saveTalkingMsg({ ...param, sentState: 3 })
    }
  }

  // 格式化发送数据
  const sendParams = { ...formatSendMessage(param, type), callback }
  const res = await window.TIMAPI.TIMSendMsg(sendParams)
  saveTalkingMsg({ ...sendParams, sentState: 2, timMsgId: res })
  console.log(`TIM 发送${type || 'text'}消息==>`, res)
}

/**
 * 获取指定会话的消息列表的参数
 * message_status 消息状态 消息正在发送->1 消息发送成功->2 消息发送失败->3 消息已删除->4 消息导入状态->5 消息撤回状态->6 消息取消->7
 * @param params
 * @returns
 */
export const timGetMsgList = async (params: MsgGetMsgListParams) => {
  if (!window.TIMAPI) return
  const res = await window.TIMAPI.TIMGetMsgList(params)
  // console.log('TIM 获取消息列表==>', res)
  return res
}

/**
 * 消息撤回
 */
export const msgRevoke = async (params: IContent) => {
  if (!window.TIMAPI) return
  const id = params?.timMsgId ? params.timMsgId : params.tid
  const messages = await window.TIMAPI.TIMMsgFindMessages({
    json_message_id_array: [id]
  })
  const { code, json_params } = messages
  if (code === 0) {
    const res = await window.TIMAPI.TIMGetMsgRevoke({
      conv_id: params?.conversationId,
      conv_type: TIMConvType.kTIMConv_Group,
      json_msg_param: json_params[0]
    })
    if (res.code === 10030 || res.code === 10031) {
      message('消息撤回失败', 'error')
      return
    }
  }
}

/**
 * 监听到消息撤回
 * @param id
 */
export const listenerRevoke = async (id: string) => {
  const messages = await window.TIMAPI.TIMMsgFindMessages({
    json_message_id_array: [id]
  })
  if (messages.code === TIMCODEENUM.SUCCESS) {
    const msg = formatHistoryMsgList(messages?.json_params)
    const content = msg[0]?.content || {}
    const msgData = {
      ...msg[0],
      content: { ...content, exts: { history_message: content?.content } }
    }
    useSingleChatStore.getState().setMessageDataListInfo(msgData)
    sendMessageThen(msg[0], 2)
  }
}

/**
 * 查询某一条消息
 * @param id
 * @returns
 */
export const findMsg = async (id: string) => {
  if (!window.TIMAPI) return
  try {
    const messages = await window.TIMAPI.TIMMsgFindMessages({
      json_message_id_array: [id]
    })
    if (messages.code === TIMCODEENUM.SUCCESS) {
      return messages?.json_params[0]
    }
  } catch (error) {
    return {}
  }
}

/**
 * 修改消息
 * @param param
 * @returns
 */
export const changeMsg = async (param: MsgModifyMessageParams) => {
  if (!window.TIMAPI) return
  return await window.TIMAPI.TIMChangeMsg(param)
}

/**
 * 消息回复
 * @param params
 * @returns
 */
export const replyMsg = async (params: MsgSendReplyMessage) => {
  if (!window.TIMAPI) return
  console.log('params', params)
  const res = await window.TIMAPI.TIMSendReplyMsg(params)
  console.info('TIM 回复消息===>', res)
}

/**
 * 退出登录
 * @param param
 * @returns
 */
export const logOutIm = async (param: logoutParam) => {
  if (!window.TIMAPI) return
  return await window.TIMAPI.TIMLogout(param)
}

/**
 * 获取所有会话总的未读消息数
 * @param param
 * @returns
 */
export const getUnreadCount = async (param: convGetTotalUnreadMessageCount) => {
  if (!window.TIMAPI) return
  const res = await window.TIMAPI.TIMGetUnreadCount(param)
  const { json_param, code } = res || {}
  if (code === 0) {
    const { conv_get_total_unread_message_count_result_unread_count } = json_param || {}
    if (useUserStore?.getState()?.user?.status === EmployeeStatus.OFFLINE) return
    useUnreadCountStore
      .getState()
      .setUnreadCount(conv_get_total_unread_message_count_result_unread_count || 0)
  }
}

/**
 * 消息已读上报
 */
export const reportReadMsg = async (param: MsgReportReadedParams) => {
  if (!window.TIMAPI) return
  await window.TIMAPI.TIMReportReadMsg(param)
}

/**
 * 消息已读上报
 * @param chatItemActive
 */
export const handleReportReadMsg = (chatItemActive: string): void => {
  const param: MsgReportReadedParams = {
    conv_id: chatItemActive, //必填
    conv_type: TIMConvType.kTIMConv_Group, //必填
    json_msg_param: {}, //选填
    user_data: '' //选填
  }
  reportReadMsg(param)
  updateConvUnreadCount(chatItemActive, 'rest')
}

/**
 * 获取群组信息
 * @param param
 * @returns
 */
export const getConversationInfo = async (param: convGetConvInfo) => {
  if (!window.TIMAPI) return
  const res = await window.TIMAPI.TIMGetConvInfo(param)
  // console.info('TIM 获取群组信息===>', res)
  return res
}
