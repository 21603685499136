import { Base64 } from 'js-base64'
import ChaCha20 from 'js-chacha20'
import { v4 } from 'uuid'

/**
 * 将字符串转换为 Uint8Array
 * @param str {string} - 输入的字符串
 * @returns {Uint8Array} - 返回对应的 Uint8Array
 */
function stringToUint8Array(str) {
  const encoder = new TextEncoder()
  return encoder.encode(str)
}

/**
 * 消息加密
 * @param message
 * @param secretKey
 * @returns
 */
export const encryptMessage = (message, secretKey) => {
  try {
    const enkey = new TextEncoder().encode(secretKey)
    const str = v4().replace(/-/g, '').slice(0, 12)
    const nonce = stringToUint8Array(str) // 12 字节的初始向量
    // 将字符串转换为字节数组
    const plaintext = new TextEncoder().encode(message)
    // 创建 ChaCha20 加密器
    const cipher = new ChaCha20(enkey, nonce)
    const ciphertext = cipher.encrypt(plaintext)
    const ciphertextBase64 = Base64.fromUint8Array(ciphertext) + str

    // console.log('加密>---->>', ciphertextBase64)
    return ciphertextBase64
  } catch (error) {
    console.log('error', error)
    return message
  }
}

/**
 * 消息解密
 * @param ciphertextBase64
 * @param secretKey
 * @returns
 */
export const decryptMessage = (ciphertextBase64, secretKey) => {
  try {
    const enkey = new TextEncoder().encode(secretKey)
    const nonce = new TextEncoder().encode(ciphertextBase64.slice(-12))

    const msgSub = Base64.toUint8Array(ciphertextBase64.slice(0, -12))
    // 创建一个新的 ChaCha20 加密器进行解密
    const decryptCipher = new ChaCha20(enkey, nonce)
    const decrypted = decryptCipher.decrypt(msgSub)

    const decryptedText = new TextDecoder().decode(decrypted)
    // console.log('解密>---->>', decryptedText)
    return decryptedText
  } catch (error) {
    console.log('error', error)
    return ciphertextBase64
  }
}
