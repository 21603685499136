import { IParamForPage } from '@renderer/utils/consts'
import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * PageFaceBookBusinessVO
 */
export interface IFaceboockHomeResp {
  countId?: string
  current?: number
  pages?: number
  records?: FaceBookHomeBusinessVO[]
  searchCount?: Response
  size?: number
  total?: number
}

/**
 * FaceBookBusinessVO
 */
export interface FaceBookHomeBusinessVO {
  id: string
  /**
   * 账号ID
   */
  accountId?: string
  /**
   * 账号名称
   */
  accountName?: string
  /**
   * 授权时间
   */
  authTime?: Date
  /**
   * 主页头像
   */
  businessAvatar?: string
  /**
   * 主页id
   */
  businessId?: string
  /**
   * 主页名称
   */
  businessName?: string
  /**
   * businessPageId
   */
  businessPageId?: number
  /**
   * 主页token
   */
  businessToken?: string
  /**
   * facebook渠道id
   */
  configId?: number
  /**
   * 最后沟通时间
   */
  lastConsultingTime?: Date
  /**
   * 备注
   */
  remark?: string
  /**
   * 启用状态 0：未启用 1启用
   */
  state?: number
  /**
   * 租户编号
   */
  tenantId?: string
  channelId?: string
  channelUserIds?: string[]
}

interface ApiResp extends ClientResponse {
  data: IFaceboockHomeResp
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * facebook主页列表
 * @param param
 * @returns
 */
export const fetchFacebookHomePageList = async (param: IParamForPage): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/messenger/business/business/page`, param)

  return serialize(data)
}
