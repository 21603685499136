import { useRoutePathStore } from '@renderer/store/manageStore/useRoutePathStore'
import { includes } from 'lodash'
import { FC, memo } from 'react'
import { useLocation } from 'react-router-dom'
import noProtect from '@renderer/assets/svg/noProtect.svg'
import { ReactSVG } from 'react-svg'
import { APP_ROUTER_PATH } from '@renderer/router'

const ProtectedRoute: FC<{ element: React.ReactElement }> = ({ element }) => {
  const { pathname } = useLocation()
  const { routePath } = useRoutePathStore()
  if (!routePath?.length) return false
  if (!includes([...routePath, APP_ROUTER_PATH.MANAGER_SETTINGS], pathname)) {
    return (
      <div className="w-full h-full flex text-lingheTextGray text-sm flex-col items-center justify-center gap-[10px]">
        <ReactSVG src={noProtect} />
        <span>抱歉，您暂无该页面的权限</span>
      </div>
    )
  }

  return element
}

export default memo(ProtectedRoute)
