import { FC, useId, useState } from 'react'
import {
  Button,
  CardContent,
  CardFooter,
  Checkbox,
  cn,
  Label,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@brisk/ui'
import { SignInHeader } from './components/SignInHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import { AccountType, TerminalType } from '@renderer/api/model'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { object, string, ZodType } from 'zod'
import { useErrorHinter } from '@renderer/hooks/useErrorHinter'
import { APP_ROUTER_PATH } from '@renderer/router'
import MyInputComps from './components/MyInputComps'
import emai from '../../assets/svg/emai.svg'
import lock from '../../assets/svg/lock.svg'
import GetCaptchaInp from './components/GetCaptchaInp'
import { PhoneInput } from '@renderer/components/phone-input'
import { generateUrlWithQueryString } from '@renderer/utils'
import { TCaptchaValidType } from './CaptchaValid'
import { message } from '@renderer/components/message'
import ImageCaptcha, { IImgCaptcha } from './components/ImageCaptcha'
import { usePasswordErrorCount } from '@renderer/store/usePasswordErrorCount'
import { useMutation } from '@tanstack/react-query'
import {
  mutationSignInByCaptcha,
  mutationSignInByPassword,
  RequestBodyByPassword,
  RequestBodyCaptcha
} from '@renderer/api'
import { User } from '@renderer/model/data/user'
import { useUserStore } from '@renderer/store/useUserStore'
import { useSaveLoginTime } from '@renderer/store/useSaveLoginTime'
import { useSignStore } from '@renderer/store/useSignStore'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'

type IFormInput = {
  email: string
  password: string
  phone: string
  captcha: string
}

const defaultFormValues: IFormInput = {
  email: '',
  password: '',
  phone: '',
  captcha: ''
}

const emailSchemaByPassword = object({
  email: string().email().min(1, '请输入邮箱'),
  password: string().min(1, '请输入密码')
})
const emailSchemaByCaptcha = object({
  email: string().email().min(1, '请输入邮箱'),
  captcha: string().min(6, '请输入验证码')
})
const phoneSchemaByPassword = object({
  phone: string().min(1, '请输入手机号'),
  password: string().min(1, '请输入密码')
})
const phoneSchemaByCaptcha = object({
  phone: string().min(1, '请输入手机号'),
  captcha: string().min(6, '请输入验证码')
})
const getSchema = (tab: AccountType, type: boolean): ZodType => {
  if (type) {
    return tab === AccountType.EMAIL ? emailSchemaByPassword : phoneSchemaByPassword
  } else {
    return tab === AccountType.EMAIL ? emailSchemaByCaptcha : phoneSchemaByCaptcha
  }
}

let saveFormData: IFormInput = defaultFormValues

const CheckInfoToLogIn: FC = () => {
  const navigate = useNavigate()
  const readId = useId()
  const autoSiginId = useId()
  const [autoSigin, setAutoSigin] = useState(true)
  const [currentTabValue, setCurrentTabValue] = useState(AccountType.EMAIL)
  const [type, setType] = useState(true) // true 密码登录 false 验证码登录
  const [showImageCaptcha, setShowImageCaptcha] = useState(false) // 图形验证码弹框
  const setUser = useUserStore((state) => state.setUser)
  const { setLoginTime } = useSaveLoginTime()
  const { errorCount, setErrorCount } = usePasswordErrorCount()
  const {
    setFifteenAutoSingin,
    setAccount,
    setAccountType,
    serverRead: read,
    setServerRead: setRead
  } = useSignStore()
  const { state } = useLocation()
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    watch
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(getSchema(currentTabValue, type))
  })
  const emailCount = watch('email')
  const phoneAccount = watch('phone')
  useErrorHinter([errors.email, errors.phone, errors.password, errors.captcha])

  /**
   * 密码登录
   */
  const { mutateAsync: mutateAsyncSignInByPassword } = useMutation({
    mutationFn: mutationSignInByPassword
  })

  /**
   * 验证码登录
   */
  const { mutateAsync: mutateAsyncSignInByCaptcha } = useMutation({
    mutationFn: mutationSignInByCaptcha
  })

  const handleReqParams = (
    type: boolean,
    formData: IFormInput,
    otherParam?: IImgCaptcha
  ): RequestBodyByPassword | RequestBodyCaptcha => {
    let result: RequestBodyByPassword | RequestBodyCaptcha = {
      tenantId: state?.tenantId || '',
      account: '',
      terminalType: TerminalType.PC,
      ...otherParam
    }
    if (type) {
      currentTabValue === AccountType.EMAIL
        ? (result = { ...result, password: formData.password, account: formData.email })
        : (result = { ...result, password: formData.password, account: formData.phone })
    } else {
      currentTabValue === AccountType.EMAIL
        ? (result = { ...result, captcha: formData.captcha, account: formData.email })
        : (result = { ...result, captcha: formData.captcha, account: formData.phone })
    }
    return result
  }

  const handleRequest = async (
    type: boolean,
    formData: IFormInput,
    otherParam?: IImgCaptcha
  ): Promise<void> => {
    let user: User
    if (type) {
      const res = await mutateAsyncSignInByPassword(
        handleReqParams(type, formData, otherParam) as RequestBodyByPassword
      )
      if (res?.code !== RESPONSE_CODE.imgCaptcha_error) setShowImageCaptcha(false)
      if (res?.code !== RESPONSE_CODE.success) {
        setErrorCount(errorCount + 1)
        return
      }
      user = res?.data
    } else {
      const res = await mutateAsyncSignInByCaptcha(handleReqParams(type, formData, otherParam))
      if (res?.code !== RESPONSE_CODE.imgCaptcha_error) setShowImageCaptcha(false)
      if (res?.code !== RESPONSE_CODE.success) {
        setErrorCount(errorCount + 1)
        return
      }
      user = res?.data
    }
    setErrorCount(0)
    setShowImageCaptcha(false)
    setUser(user)
    /** 存储登录时间 */
    setLoginTime(new Date().getTime() + 15 * 24 * 60 * 60 * 1000)

    message('登录成功', 'success')
    navigate(APP_ROUTER_PATH.HOME_CHATTING)
  }
  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    if (!read) {
      message('请勾选我已阅读并同意隐私权政策', 'error')
      return
    }
    saveFormData = formData
    if (errorCount >= 3) {
      setShowImageCaptcha(true)
      return
    }
    if (currentTabValue === AccountType.EMAIL) {
      setAccount(getValues('email'))
    } else {
      setAccount(getValues('phone'))
    }
    setAccountType(currentTabValue)
    handleRequest(type, formData)
  }
  return (
    <>
      <ImageCaptcha
        open={showImageCaptcha}
        onOpenChange={() => setShowImageCaptcha(false)}
        onConfirm={(data) => {
          handleRequest(type, saveFormData, data)
        }}
      />
      <SignInHeader showBack={true} title="正在进入零和科技" />
      <CardContent className="flex-1 justify-start flex flex-col">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            defaultValue={currentTabValue}
            className=""
            onValueChange={(value) => {
              setCurrentTabValue(value as AccountType)
            }}
          >
            <TabsList className="mb-5">
              <TabsTrigger
                className=" data-[state=active]:bg-[none !important]"
                value={AccountType.EMAIL}
                onClick={() => setType(true)}
              >
                邮箱
              </TabsTrigger>
              <TabsTrigger
                className=" data-[state=active]:bg-[none !important]"
                value={AccountType.SMS}
                onClick={() => setType(false)}
              >
                手机号
              </TabsTrigger>
            </TabsList>
            <TabsContent value={AccountType.EMAIL}>
              <div className="grid gap-4">
                <div className="grid gap-2">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <MyInputComps
                        field={field}
                        placeholder="请输入邮箱"
                        required
                        errors={errors.email}
                        svg={emai}
                      />
                    )}
                  />
                </div>
                {type && (
                  <div className="grid gap-2">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <MyInputComps
                          type="password"
                          field={field}
                          placeholder="请输入密码"
                          required
                          errors={errors.password}
                          errorMsg="密码错误，请重新输入"
                          svg={lock}
                        />
                      )}
                    />
                  </div>
                )}
                {!type && (
                  <div className="grid gap-2">
                    <Controller
                      name="captcha"
                      control={control}
                      render={({ field }) => (
                        <GetCaptchaInp
                          field={field}
                          required
                          errors={errors.captcha}
                          account={emailCount}
                          accountType={AccountType.EMAIL}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            </TabsContent>
            <TabsContent value={AccountType.SMS}>
              <div className="grid gap-4">
                <div className="flex items-center gap-2 ">
                  <div className="flex-1 h-12">
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => <PhoneInput {...field} required className="h-full" />}
                    />
                  </div>
                </div>
                {type && (
                  <div className="grid gap-2">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <MyInputComps
                          type="password"
                          field={field}
                          placeholder="请输入密码"
                          required
                          errors={errors.password}
                          errorMsg="密码错误，请重新输入"
                          svg={lock}
                        />
                      )}
                    />
                  </div>
                )}
                {!type && (
                  <div className="grid gap-2">
                    <Controller
                      name="captcha"
                      control={control}
                      render={({ field }) => (
                        <GetCaptchaInp
                          field={field}
                          required
                          errors={errors.captcha}
                          account={phoneAccount}
                          accountType={AccountType.SMS}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            </TabsContent>
          </Tabs>
          <Button type="submit" className="w-full mt-8 h-12">
            登录
          </Button>
        </form>
        <div className="flex justify-between items-center mt-4 text-sm">
          <span className="text-primary cursor-pointer" onClick={() => setType(!type)}>
            {type ? '验证码登录' : '密码登录'}
          </span>
          <span
            className={cn('text-lingheGray cursor-pointer', !type && 'hidden')}
            onClick={() => {
              if (currentTabValue === AccountType.EMAIL) {
                const account = getValues('email')
                if (!account) {
                  message('请输入邮箱', 'error')
                  return
                } else {
                  setAccount(account)
                  setAccountType(currentTabValue)
                  const path = generateUrlWithQueryString(APP_ROUTER_PATH.Captcha_Valid, {
                    redirect: APP_ROUTER_PATH.RESET_PASSWORD,
                    captchaValidType: TCaptchaValidType.RESET_PASSWORD
                  })
                  navigate(path)
                }
              } else {
                const account = getValues('phone')
                if (!account) {
                  message('请输入手机号', 'error')
                  return
                } else {
                  setAccount(getValues('phone'))
                  setAccountType(currentTabValue)
                  const path = generateUrlWithQueryString(APP_ROUTER_PATH.Captcha_Valid, {
                    redirect: APP_ROUTER_PATH.RESET_PASSWORD,
                    captchaValidType: TCaptchaValidType.RESET_PASSWORD
                  })
                  navigate(path)
                }
              }
            }}
          >
            忘记密码
          </span>
        </div>
      </CardContent>
      <CardFooter>
        <div>
          <div className="flex items-center space-x-2">
            <Checkbox
              checked={autoSigin}
              onCheckedChange={(e: boolean) => {
                setFifteenAutoSingin(e)
                setAutoSigin(e)
              }}
              id={autoSiginId}
            />
            <Label
              htmlFor={autoSiginId}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              15天内自动登录
            </Label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox id={readId} checked={read} onCheckedChange={(e: boolean) => setRead(e)} />
            <Label
              htmlFor={readId}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              我已阅读并同意
              <Button
                variant="link"
                className="p-0 m-0"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  window.open('https://www.aiochat.im/privacy_policy', '_blank')
                }}
              >
                <span>服务协议、隐私权政策</span>
              </Button>
            </Label>
          </div>
        </div>
      </CardFooter>
    </>
  )
}

export default CheckInfoToLogIn
